import axios from "axios"
import { VERIFY_USER_ROLE, CHECK_USER_ROLE, ROLE_LOADING, ROLE_LOADING_DONE, GET_STATUS_CODE } from "../actions/types"
import process from "../env"



const service_url = process.env.SERVICE_URL



export const checkUserRole = ({ dispatch }) => next => action => {
    if(action.type === VERIFY_USER_ROLE) {
        dispatch({ type: ROLE_LOADING })
        axios.get(`${service_url}check-user-type`).then(res => {
            dispatch({ 
                type: CHECK_USER_ROLE,
                payload: res.data
            })
            dispatch({ type: ROLE_LOADING_DONE })
        }).catch(err => {
            dispatch({ type: ROLE_LOADING_DONE })
            dispatch({
                type: GET_STATUS_CODE,
                payload: err.response
            })
        })
    }

    return next(action)
}