import { DELETE_OFFICES, GET_OFFICES_LOADING_STARTS, GET_OFFICES_LOADING_ENDS, GET_ALL_OFFICES, GET_AN_OFFICE, OFFICE_CRUD_LOADING_STARTS, OFFICE_CRUD_LOADING_ENDS } from "../../actions/types"


const INITIAL_STATE = {
    data: undefined,
    loading: false,
    office:{},
    crud:false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_OFFICES_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case GET_OFFICES_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case GET_ALL_OFFICES:
            return {
                ...state,
                data: action.payload
            }
        // case GET_AN_OFFICE:
        //     return {
        //         ...state,
        //         data: action.payload
        //     }
        case DELETE_OFFICES:
            return {
                ...state,
                office: state.data.filter(item => item.id !== action.payload)
            }
        case OFFICE_CRUD_LOADING_STARTS:
            return {
                ...state,
                crud: true
            }
        case OFFICE_CRUD_LOADING_ENDS:
            return {
                ...state,
                crud:false
            }
        default:
            return state
    }
}