import { GET_TRAINING_REPORTS, FETCH_ERROR } from "../../actions/types";

const initialState = {
  getAllEmployeesTrainingReports: {},
  getAnEmployeeTrainingReport: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRAINING_REPORTS:
      return {
        ...state,
        getAllEmployeesTrainingReports: action.payload,
      };

    default:
      return state;
  }
};
