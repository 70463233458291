import React from "react"

function ChangePasswordSVG(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={122.879}
        height={118.662}
        viewBox="0 0 122.879 118.662"
        {...props}
        className='forgotPassword'
        style={{
            opacity:'0.6',
            transform:'scale(0.55)'
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.101 54.363h4.138v-8.738c0-4.714 1.93-8.999 5.034-12.105v-.004c3.105-3.105 7.392-5.034 12.108-5.034 4.714 0 8.999 1.929 12.104 5.034l.004.004c3.104 3.105 5.034 7.392 5.034 12.104v8.738l3.297.001a1.34 1.34 0 011.335 1.335v28.203a1.34 1.34 0 01-1.336 1.335H43.101a1.34 1.34 0 01-1.336-1.335V55.698a1.34 1.34 0 011.336-1.335zM16.682 22.204a52.746 52.746 0 00-5.061 7.457c-5.987 10.645-8.523 22.731-7.49 34.543 1.01 11.537 5.432 22.827 13.375 32.271 2.853 3.392 5.914 6.382 9.132 8.968 11.112 8.935 24.276 13.341 37.405 13.216 13.134-.125 26.209-4.784 37.145-13.981a65.713 65.713 0 008.915-9.13c6.396-7.957 10.512-17.29 12.071-27.138 1.532-9.672.595-19.829-3.069-29.655-3.487-9.355-8.814-17.685-15.775-24.206C96.695 8.333 88.593 3.755 79.196 1.483A52.567 52.567 0 0070.205.109a53.847 53.847 0 00-9.401.224 3.661 3.661 0 10.802 7.278 46.56 46.56 0 018.141-.195c2.654.171 5.23.568 7.731 1.174 8.106 1.959 15.104 5.914 20.838 11.288 6.138 5.751 10.847 13.125 13.941 21.427 3.212 8.613 4.035 17.505 2.696 25.959-1.36 8.589-4.957 16.739-10.553 23.699-2.469 3.071-5.121 5.78-7.912 8.127-9.591 8.067-21.031 12.153-32.502 12.263-11.473.109-23.001-3.762-32.764-11.61a58.584 58.584 0 01-8.129-7.966c-6.917-8.224-10.771-18.092-11.655-28.202-.908-10.375 1.317-20.988 6.572-30.331 1.586-2.82 3.211-5.071 5.013-7.241l.533 14.696a3.655 3.655 0 107.306-.258l-.85-23.419a3.655 3.655 0 00-4.154-3.493v-.003L3.157 16.715a3.66 3.66 0 001.004 7.249l12.521-1.76zm37.217 32.159h20.963v-8.834c0-2.883-1.18-5.504-3.077-7.403l-.002.001a10.453 10.453 0 00-7.402-3.08c-2.883 0-5.504 1.18-7.404 3.078a10.456 10.456 0 00-3.077 7.404v8.834h-.001zm10.566 15.432l2.116 9.764-5.799.024 1.701-9.895a3.931 3.931 0 111.982.107z"
        />
      </svg>
    )
  }



  export default ChangePasswordSVG