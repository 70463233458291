import { trainingTypes } from "../../actions/types";

let initialState = {
  data: [],
  loading: false,
  currentItemId: "",
  opsInProgress: false,
  errors: {},
  statusCode: null,
  redirectTo: null,
};

export const trainingRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case trainingTypes.GET_TRAINING_REQUEST_BEGINS:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case trainingTypes.GET_TRAINING_REQUEST_SUCCESS:
      
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case trainingTypes.GET_TRAINING_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        errors: true,
      };

    case trainingTypes.GET_SINGLE_TRAINING_REQUEST_BEGINS:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case trainingTypes.GET_SINGLE_TRAINING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case trainingTypes.GET_SINGLE_TRAINING_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        errors: true,
      };

    case trainingTypes.SAVE_TRAINING_REQUEST_BEGINS:
      return {
        ...state,
        opsInProgress: true,
      };
    case trainingTypes.SAVE_TRAINING_REQUEST_SUCCESS:
      return {
        ...state,
        data: [],
        opsInProgress: false,
      };
    case trainingTypes.SAVE_TRAINING_REQUEST_FAILED:
      return {
        ...state,
        errors: action.error,
        opsInProgress: false,
      };
    case trainingTypes.SAVE_TRAINING_REQUEST_ENDED:

    case trainingTypes.DELETE_TRAINING_REQUEST_BEGINS:
      return {
        ...state,
        opsInProgress: true,
      };
    case trainingTypes.SET_TRAINING_REQUEST_ITEM_ID:
      return {
        ...state,
        currentItemId: action.payload.id,
      };

    case trainingTypes.DELETE_TRAINING_REQUEST_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.id != action.payload.id),
        opsInProgress: false,
        currentItemId: "",
      };

    case trainingTypes.DELETE_TRAINING_REQUEST_FAILED:
      return {
        ...state,
        error: "error occured",
        opsInProgress: false,
        currentItemId: "",
      };
    case trainingTypes.DELETE_TRAINING_REQUEST_ENDED:
      return {
        ...state,
        opsInProgress: false,
        currentItemId: "",
      };
    case trainingTypes.EDIT_TRAINING_REQUEST_ENDED:
      return {
        ...state,
        opsInProgress: false,
        data: [],
      };

    case trainingTypes.UPDATE_TRAINING_REQUEST_BEGINS:
      return {
        ...state,
        opsInProgress: true,
        loading: false,
      };

    case trainingTypes.UPDATE_TRAINING_REQUEST_SUCCESS:
      return {
        ...state,
        opsInProgress: false,
        data: [],
      };

    case trainingTypes.UPDATE_TRAINING_REQUEST_FAILED:
      return {
        ...state,
        errors: action.payload.error,
        opsInProgress: false,
      };

    case trainingTypes.REDIRECT_TO_TRAINING_REQUEST_VIEW:
      return {
        ...state,
        data: [],
        redirectTo: action.payload,
      };

    default:
      return state;
  }
};
