import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CONSTANTS from "../../common/constants.json";
import InputData from "../Reusables/InputData";
import 'react-toastify/dist/ReactToastify.css'
import isEmpty from "../../utils/isEmpty";
import DynamicOptions from "../Reusables/DynamicOptions";
import { addInternalJobApplication } from "../../actions/Module-Recruitment/jobApplicationActions";
import { getAllCountries, getAllStatesInCountry } from "../../actions/locationActions";
import { SpinnerGrow } from "../Reusables/loader/Loader";
import { clearErrors } from "../../actions/Network-Actions/networkActions";
import { getAllStaffRequests } from "../../actions/Module-Recruitment/staffRequisitionActions";
import jwt_decode from "jwt-decode";


class EmployeeJobApplicationApply extends Component {
    constructor() {
        super();
        this.state = {
            country_id: '',
            state_id: '',
            job_title: '',
            years_in_role: '',
            date_of_availability: '',
            uploaded_cv_file_path: '',
        };
    };

    componentDidMount(){
        this.props.getAllCountries()
        this.props.getAllStaffRequests()
    }


    handleSubmit = (e) => {
        e.preventDefault();
        const decoded = jwt_decode(localStorage.jwtToken);
        const application = {
            application_type: "internal",
            country_id: this.state.country_id,
            state_id: this.state.state_id,
            job_title: this.state.job_title,
            date_of_availability: this.state.date_of_availability,
            uploaded_cv_file_path: this.state.uploaded_cv_file_path,
            years_in_role: this.state.years_in_role,
            user_id: decoded.sub
        };
        return this.props.addInternalJobApplication(application);
    };

    handleChange = (e) => {
        if(e.target.name === "country_id") {
        this.props.getAllStatesInCountry(e.target.value)
        }
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectOptions = ({ value, name }) => {
        this.setState({ ...this.state, [name]: value });
    };

    componentWillUnmount() {
        this.props.clearErrors()
    }


    render() {

    const { errors, isLoading, networkResponse, countries, states, requests } = this.props;
    if (networkResponse.redirectTo !== null) {
        return <Redirect to={networkResponse.redirectTo} />;
    }
    return (
    <form onSubmit={this.handleSubmit}>

        <div className="inner-page">
          <div className="my-card">
            <div className="my-card-header">Job Application</div>
            <div className="my-card-body">
              <div className="mini-card">
                <div className="mini-card-header">Apply For a Job</div>
                <div className="row">
                    <div className="form-group col-md-6 ">
                        <DynamicOptions
                            storeInputs={this.handleChange}
                            inputTitle="Name"
                            optionsTitle="Country"
                            optionsValue={countries || []}
                            name="country_id"
                            value={this.state.country_id}
                            invalidFeedback={
                                isEmpty(errors) ? null : errors.data.errors.country_id
                            }
                        />

                    </div>

                    <div className="form-group col-md-6 ">
                        <DynamicOptions
                            storeInputs={this.handleChange}
                            inputTitle="State"
                            optionsTitle="Choose..."
                            optionsValue={states || []}
                            name="state_id"
                            value={this.state.state_id}
                            invalidFeedback={
                                isEmpty(errors) ? null : errors.data.errors.state_id
                            }
                        />
                    </div>


                    <div className="form-group col-md-12">
                            <DynamicOptions
                                storeInputs={this.handleChange}
                                inputTitle="Job Title"
                                optionsTitle="Job Title"
                                optionsValue={requests || []}
                                name="job_title"
                                value={this.state.job_title}
                                invalidFeedback={
                                    isEmpty(errors) ? null : errors.data.errors.job_title
                                }
                            />

                    </div>
                <div className="form-group col-md-6 ">
                    <InputData
                        inputType="text"
                        inputTitle="Years in role"
                        value={this.state.years_in_role}
                        onChange={this.handleChange}
                        name="years_in_role"
                        inputError={
                        isEmpty(errors)
                            ? null
                            : errors.data.errors.years_in_role
                        }
                    />

                </div>

                <div className="form-group col-md-6 ">
                    <InputData
                        inputType="date"
                        inputTitle="Date of Availability"
                        value={this.state.date_of_availability}
                        onChange={this.handleChange}
                        name="date_of_availability"
                        inputError={
                            isEmpty(errors)
                            ? null
                            : errors.data.errors.date_of_availability
                        }
                    />

                </div>
                <div className="form-group col-md-6 ">
                    <InputData
                        inputType="text"
                        inputTitle="CV"
                        value={this.state.uploaded_cv_file_path}
                        onChange={this.handleChange}
                        name="uploaded_cv_file_path"
                        inputError={
                            isEmpty(errors)
                            ? null
                            : errors.data.errors.uploaded_cv_file_path
                        }
                    />

                </div>
            </div>
            </div>
            <div className=" form-button">
                <button className="button primary mr-3">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#5f6368"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    disabled={isLoading}
                >
                    <path stroke="none" d="M0 0h24v24H0z"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                </svg>
                { isLoading ? (<SpinnerGrow/>) : "Save"}
            </button>
                <Link
                    to={CONSTANTS.ROUTES.RECRUITMENT_JOB_APPLICATIONS}
                    className="button secondary">
                    Cancel
                </Link>
            </div>
            </div>
        </div>
        </div>
    </form>

    );
}
}

const mapStateToProps = (state) => {
    return {
        errors: state.errors,
        isLoading: state.jobApplications.loading,
        networkResponse: state.networkResponse,
        countries: state.location.countries,
        states: state.location.states,
        grades: state.grades.grades,
        requests: state.staffRequisitions.allStaffRequest,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        addInternalJobApplication: (data, type) => dispatch(addInternalJobApplication(data, type)),
        getAllCountries: () => dispatch(getAllCountries()),
        getAllStatesInCountry: (id) => dispatch(getAllStatesInCountry(id)),
        clearErrors: () => dispatch(clearErrors()),
        getAllStaffRequests: () => dispatch(getAllStaffRequests())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeJobApplicationApply);