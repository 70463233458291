import React from "react";
import { messagesSVG } from "./Reusables/NonComponent/svgs";
import { Link } from "react-router-dom";
import UserIcon from "../assets/icons/user.png"
const Message = () => {
  return (
    <li className="nav-item dropdown nav-messages">
      <Link
        className="nav-link dropdown-toggle"
        to="#"
        id="messageDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {messagesSVG()}
        <div className="ring"></div>
      </Link>
      <div className="dropdown-menu" aria-labelledby="messageDropdown">
        <div className="dropdown-header">
          <div className="head">Messages</div>
        </div>
        <div className="dropdown-body">
          <div className="dropdown-item-bef">
            <Link to="" className="dropdown-item">
              <div className="figure">
                <img src={UserIcon} alt="userr" />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Leonardo Payne</p>
                  <p className="sub-text text-muted">2 min ago</p>
                </div>
                <p className="sub-text text-muted">Project status</p>
              </div>
            </Link>
          </div>
          <div className="dropdown-item-bef">
            <Link to=" " className="dropdown-item">
              <div className="figure">
                <img src={UserIcon} alt="userr" />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Carl Henson</p>
                  <p className="sub-text text-muted">30 min ago</p>
                </div>
                <p className="sub-text text-muted">Client meeting</p>
              </div>
            </Link>
          </div>
          <div className="dropdown-item-bef">
            <Link to="" className="dropdown-item">
              <div className="figure">
                <img src={UserIcon} alt="userr" />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Jensen Combs</p>
                  <p className="sub-text text-muted">1 hrs ago</p>
                </div>
                <p className="sub-text text-muted">Project updates</p>
              </div>
            </Link>
          </div>
          <div className="dropdown-item-bef">
            <Link to="" className="dropdown-item">
              <div className="figure">
                <img src={UserIcon} alt="userr" />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Amiah Burton</p>
                  <p className="sub-text text-muted">2 hrs ago</p>
                </div>
                <p className="sub-text text-muted">Project deadline</p>
              </div>
            </Link>
          </div>
          <div className="dropdown-item-bef">
            <Link to="" className="dropdown-item">
              <div className="figure">
                <img src={UserIcon} alt="userr" />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Yaretzi Mayo</p>
                  <p className="sub-text text-muted">5 hr ago</p>
                </div>
                <p className="sub-text text-muted">New record</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="dropdown-footer d-flex align-items-center justify-content-center">
          <Link to="" className="">
            View all
          </Link>
        </div>
      </div>
    </li>
  );
};
export default Message;
