import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { checkUserRole } from "./middlewares/checkUserRole";


const initialState = {};

const middleware = [checkUserRole ,thunk];
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

export default createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ || compose
    )
);