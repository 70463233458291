import React from "react";
import { Link, Redirect } from "react-router-dom";
import CONSTANTS from "../../common/constants.json";
import isEmpty from "../../utils/isEmpty";
import { CLEAR_ERRORS } from "../../actions/types";
import { useSelector, useDispatch } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { SpinnerGrow } from "../../Components/Reusables/loader/Loader";
import PasswordField from "../../Components/Reusables/PasswordField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer } from "react-toastify";

import ReCAPTCHA from "react-google-recaptcha";
import process from "../../env"
import { SectionLazyLoadTable } from "../../Components/Reusables/LazyLoads";

const Register = () => {
  const dispatch = useDispatch();
  let recaptchaRef;// = React.createRef();
  const { errors, networkResponse, auth } = useSelector((state) => state);

  const [userInput, setUserInput, setData] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    company_name: "",
    rc_number: "",
    business_type: 1,
    password_validation: false,
    registration_type: "",
    recaptcha_token:'false'
  });

  React.useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_ERRORS });
    };
  }, []);

  const onChangeRecaptcha = (value) => {
		setUserInput({
      ...userInput,
      recaptcha_token: value,
    });
	}

  const handleChange = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };
  const phoneChange = (value, data, e, formattedValue) => {
    // setUserInput({ ...userInput, [e.target.name]: e.target.value });
 
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    dispatch(registerUser(userInput));
    recaptchaRef.reset()
    userInput.recaptcha_token = null

  };
  if (networkResponse.redirectTo !== null) {
    return <Redirect to={networkResponse.redirectTo} />;
  }

  const handleChangePhone = (e) => {
    setUserInput({
      ...userInput,
      phone: e,
    });
  };
  return (
    <div className="col-lg-7 col-sm-7 col-xs-12 form-container">
      <ToastContainer />
      
      <form
        onSubmit={handleSubmit}
        className="p-2 col-lg-8 form-child col-sm-12"
      >
        <div className="mb-3">
          <h2>Create an account</h2>
          <p className="h6">
            Already have an account?{" "}
            <Link to={CONSTANTS.ROUTES.LOGIN} className="">
              Sign in
            </Link>
          </p>
        </div>
        <div className="row"></div>
        <div className="row">
          <div className="col-6">
            <div className="my-3">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control form-control"
                pattern="^[a-zA-Z]+$"
                title="First name should only contain alphabets"
                name="first_name"
                id="first_name"
                onChange={handleChange}
                value={userInput.first_name}
                required
              />
              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors.first_name)
                  ? null
                  : errors?.data?.errors?.first_name}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="my-3">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                pattern="^[a-zA-Z]+$"
                title="Last name should only contain alphabets"
                className="form-control form-control"
                name="last_name"
                id="last_name"
                onChange={handleChange}
                value={userInput.last_name}
                required
              />
              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors?.last_name)
                  ? null
                  : errors.data.errors?.last_name}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* <div className="col-6">
            <div className="my-3">
              <label htmlFor="business_type">Business Type</label>
              <select
                required
                onChange={handleChange}
                name="business_type"
                value={userInput.business_type}
                id=""
                className="form-control"
              >
                <option defaultValue>Choose</option>
                <option value="1">Business Owner</option>
                <option value="0">Financial Provider</option>
                <option value="">Professional</option>
                <option value="">Trade Promoter</option>
              </select>
              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors?.business_type) ? null : errors?.data?.errors?.business_type}
              </div>
            </div>
          </div>
           */}
          <div className="col-6">
            <div className="my-3">
              <label htmlFor="company_name">Company Name</label>
              <input
                type="text"
                // pattern="^[a-zA-Z]+$"
                // title="Company name should only contain alphabets"
                className="form-control form-control"
                name="company_name"
                id="company_name"
                onChange={handleChange}
                value={userInput.company_name}
                required
              />
              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors?.company_name)
                  ? null
                  : errors?.data?.errors?.company_name}
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="my-3">
              <label htmlFor="rc_number">Rc Number</label>
              <input
                type="text"
                pattern="^[0-9]+$"
                className={`${
                  isEmpty(errors?.data?.errors?.rc_number)
                    ? "form-control"
                    : "form-control is-invalid"
                }`}
                name="rc_number"
                id="rc_number"
                onChange={handleChange}
                value={userInput.rc_number}
                required
              />
              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors?.rc_number)
                  ? null
                  : errors?.data?.errors?.rc_number}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="my-3">
              <label htmlFor="phone">Phone Number</label>
              <PhoneInput
                country={"ng"}
                value={userInput?.phone}
                onChange={handleChangePhone}
                autoFormat={false}
                inputProps={{ name: "phone" }}
              />
              {/* <input
                type="text"
                pattern="[0-9]{11}"
                className={`${
                  isEmpty(errors?.data?.errors?.phone) ? "form-control" : "form-control is-invalid"
                }`}
                name="phone"
                id="phone"
                onChange={handleChange}
                value={userInput.phone}
                required
              /> */}

              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors?.phone)
                  ? null
                  : errors?.data?.errors?.phone}
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="my-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className={`${
                  isEmpty(errors?.data?.errors?.email)
                    ? "form-control"
                    : "form-control is-invalid"
                }`}
                name="email"
                id="email"
                onChange={handleChange}
                value={userInput.email}
                required
              />
              <div className="invalid-feedback">
                {isEmpty(errors?.data?.errors?.email)
                  ? null
                  : errors?.data?.errors?.email}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="my-3">
              <label htmlFor="password">Password</label>
              <PasswordField
                value={userInput?.password}
                name="password"
                setChange={handleChange}
                errors={errors?.data?.errors?.password ?? null}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="my-3">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <PasswordField
                value={userInput?.confirmPassword}
                name="confirmPassword"
                setChange={handleChange}
                errors={errors?.data?.errors?.confirmPassword ?? null}
              />
            </div>
          </div>
        </div>

        <div className="ml-3 form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="loggedin"
            required
          />
          <label className="form-check-label" htmlFor="loggedin">
            I accept the terms and conditions 
          </label>
        </div>

        <div className="mb-3">
          <ReCAPTCHA sitekey={process.env.RECAPTCHA_SITE} ref={el => { recaptchaRef = el; }}
          onChange={onChangeRecaptcha} errors={errors?.data?.errors?.recaptcha_token ?? null}/>
          {errors?.data?.errors?.recaptcha_token && (
            <small> <span className="text-danger">{ errors?.data?.errors?.recaptcha_token }</span></small>
          )}          
        </div>
        <button disabled={auth.authLoading} className="button primary">
          {auth.authLoading ? <SpinnerGrow /> : "Sign Up"}
        </button>
      </form>
    </div>
  );
};
export default Register;
