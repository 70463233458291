import { DELETE_PROCESS,GET_ALL_PROCESS, GET_A_PROCESS, CREATE_PROCESS,  PROCESS_CRUD_ENDS, PROCESS_CRUD_STARTS, PROCESS_LOADING_STARTS, PROCESS_LOADING_ENDS} from "../../actions/types"


const INITIAL_STATE = {
    data: [],
    loading: false,
    process:{},
    crudInProgress:false

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROCESS_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case PROCESS_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case CREATE_PROCESS:
            const newData = [...state.data, action.payload]
            return {
                ...state,
                data: newData
            }
        case GET_ALL_PROCESS:
            return {
                ...state,
                data: action.payload
            }
        // case GET_A_PROCESS:
        //     return {
        //         ...state,
        //         data: action.payload
        //     }
        case DELETE_PROCESS:
            const filtered = state.data.filter(item => item.id !== action.payload)
            return {
                ...state,
                data: filtered
            }
        case PROCESS_CRUD_STARTS:
            return {
                ...state,
                crudInProgress: true
            }
        case PROCESS_CRUD_ENDS:
            return {
                ...state,
                crudInProgress: false
            }
        default:
            return state
    }
}