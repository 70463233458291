import { GET_ALL_JOB_APPLICATIONS_COMMENTS, COMMENT_LOADING_ENDS, COMMENT_LOADING_STARTS, COMMENT_DATA_LOADED, COMMENT_DATA_LOADED_ENDS } from "../../actions/types";


const INITIAL_STATE = {
    comments: undefined,
    loading: false,
    dataLoaded: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_JOB_APPLICATIONS_COMMENTS:
            return {
                ...state,
                comments: action.payload,
            };
        case COMMENT_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case COMMENT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case COMMENT_DATA_LOADED:
            return {
                ...state,
                dataLoaded: true
            }
        case COMMENT_DATA_LOADED_ENDS:
            return {
                ...state,
                dataLoaded: false
            }
        default:
            return state;
    }
};
