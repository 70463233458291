import {
    GET_ALL_JOB_APPLICATIONS,
    GET_JOB_APPLICATION,
    GET_JOB_APPLICATIONS_BY_TYPE,
    DELETE_JOB_APPLICATION,
    JOB_APPLICATION_LOADER_STARTS,
    JOB_APPLICATION_LOADER_ENDS,
    JOB_APPLICATION_CRUD_STARTS,
    JOB_APPLICATION_CRUD_ENDS
} from "../../actions/types";

const INITIAL_STATE = {
    allJobApplications: undefined,
    jobApplication: undefined,
    loading: false,
    crudInProgress:false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_JOB_APPLICATIONS:
            return {
                ...state,
                allJobApplications: action.payload,
            };
        
        case JOB_APPLICATION_CRUD_STARTS:
            return {
                ...state,
                crudInProgress:true
            }
        case JOB_APPLICATION_CRUD_ENDS:
            return {
                ...state,
                crudInProgress:false
            }
        case JOB_APPLICATION_LOADER_STARTS:
            return {
                ...state,
                loading: true
            }
        case JOB_APPLICATION_LOADER_ENDS:
            return {
                ...state,
                loading: false
            }
        case GET_JOB_APPLICATION:
            return {
                ...state,
                jobApplication: action.payload,
            };
        case GET_JOB_APPLICATIONS_BY_TYPE:
            return {
                ...state,
                allJobApplications: action.payload,
            };
        case DELETE_JOB_APPLICATION:
            return {
                ...state,
                allJobApplications: state.allJobApplications.filter(
                    (item) => item.id !== action.payload
                ),
            };

        default:
            return state;
    }
};
