import {
  FETCH_TIMESHEET_BEGINS,
  FETCH_TIMESHEET_SUCCESS,
  FETCH_TIMESHEET_FAILED,
  FETCH_SINGLE_TIMESHEET_SUCCESS,
  FETCH_SINGLE_TIMESHEET_FAILED,
  DELETE_TIMESHEET_BEGINS,
  DELETE_TIMESHEET_SUCCESS,
  DELETE_TIMESHEET_FAILED,
  SAVE_TIMESHEET_BEGINS,
  SAVE_TIMESHEET_SUCCESS,
  SAVE_TIMESHEET_FAILED,
  EDIT_TIMESHEET_BEGINS,
  EDIT_TIMESHEET_SUCCESS,
  UPDATE_TIMESHEET_BEGINS,
  UPDATE_TIMESHEET_SUCCESS,
  UPDATE_TIMESHEET_FAILED,
  NETWORK_SAVE_TIMESHEET_FAILED,
  SAVE_TIMESHEET_ENDED,
  REDIRECT_TO_TIMESHEET,
  REDIRECT_TO,
  SET_CLOCK_STATUS,
  HIDE_DELETE_MODAL,
  SHOW_DELETE_MODAL,
  CLOCKING_STARTS,
  CLOCKING_ENDS
} from "../../actions/types";

let initialState = {
  data: [],
  attendance:{},
  loading: false,
  opsInProgress: false,
  error:{},
  currentItem:{},
  currentItemId:'',
  statusCode: null,
  redirectTo: null,
  clockStatus:null,
  showDeleteModal:false,
  clocking: false
};

export const timeSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOCKING_STARTS:
      return {
        ...state,
        clocking: true
      }
    case  CLOCKING_ENDS:
      return {
        ...state,
        clocking: false
      }
    case FETCH_TIMESHEET_BEGINS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        error:false,
        data: action.payload.data?.returnData,
        clockStatus:action.payload.data?.clock_status
      };
    case FETCH_TIMESHEET_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

      case FETCH_SINGLE_TIMESHEET_SUCCESS:
        return {
          ...state,
          loading: false,
          error:false,
          attendance: action.payload,
        };
      case SET_CLOCK_STATUS:
        return {
          clockStatus: action.payload,
        };

        case FETCH_SINGLE_TIMESHEET_FAILED:
          return {
            ...state,
            loading: true,
            error: action.payload.error,
          };

      case DELETE_TIMESHEET_BEGINS:
        return {
          ...state,
          opsInProgress:true,
        }

    case DELETE_TIMESHEET_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
        opsInProgress:false,
      };
    case DELETE_TIMESHEET_FAILED:
      return {
        ...state,
        opsInProgress: false,
      };

      case SAVE_TIMESHEET_BEGINS:
        return {
          ...state,
          opsInProgress:true
        }
      case SAVE_TIMESHEET_SUCCESS:
          return{
            ...state,
            opsInProgress:false,
            data:action.payload.data
          }

          // case NETWORK_SAVE_TIMESHEET_FAILED:
          //   return{
          //     ...state,
          //     opsInProgress:false,
          //   }
      
            case SAVE_TIMESHEET_FAILED:
            return{
              ...state,
              opsInProgress:false,
              error:action.payload
            }

      case SAVE_TIMESHEET_ENDED:
            return{
              ...state,
              opsInProgress:false
             
            }
      case REDIRECT_TO:
              return {
                  ...state,
                  redirectTo: action.payload
              }

      case EDIT_TIMESHEET_BEGINS:
                return {
                  ...state,
                  opsInProgress:true,
                };
      case EDIT_TIMESHEET_SUCCESS:
                return {
                  ...state,
                  opsInProgress:false,
                  currentItemId:action.payload.id
                };
        
                case UPDATE_TIMESHEET_BEGINS:
                  return{
                    ...state,
                    opsInProgress:true,
                      
                  }
                case UPDATE_TIMESHEET_SUCCESS:
                  return{
                    ...state,
                    data:action.payload.data,
                    opsInProgress:true,
                      
                  }
                case UPDATE_TIMESHEET_FAILED:
                  return{
                    ...state,
                    error:action.payload,
                    opsInProgress:false,
                      
                  }

                case REDIRECT_TO_TIMESHEET:
              return {
                  ...state,
                  redirectTo: action.payload
              }
                case SHOW_DELETE_MODAL:
              return {
                  ...state,
                  showDeleteModal:true
              }
                case HIDE_DELETE_MODAL:
              return {
                  ...state,
                  showDeleteModal:false
              }
        

    default:
      return state;
  }
};
