import React, { useState, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";

const InnerSideBar = (props) => {
  const { data = [] } = props;
  const [activeLink, setActiveLink] = useState(0);

  const handleActive = useCallback((id) => {
    setActiveLink(id);
  }, []);
  const setActive = useCallback(() => {
    //  callback
  }, []);

  return (
    <>
      <div className="inner-nav">
        <nav className="nav">
          {data?.map((item, index) => {
            const { path, name, details, id } = item;
            return (
              <Link
                key={index}
                onClick={() => handleActive(id)}
                to={path}
                className={`navlist ${(id === 0 && props.location.pathname === '/time') ?  "active" : (id === 1 && props.location.pathname === '/time/timesheet-view') ? "active" : (id === 2 && props.location.pathname === '/time/overtimesheet-view') ? "active" : "" }`}
              >
                <div className="content">
                  <div className="title">{name}</div>
                  <div className="details text-muted">{details}</div>
                </div>
              </Link>
            );
          })}
        </nav>
      </div>
    </>
  );
};

export default withRouter(InnerSideBar);
