import {
    GET_ALL_DEPLOYMENT,
    GET_DEPLOYMENT,
    DELETE_JOB_DEPLOYMENT,
} from "../../actions/types";

const INITIAL_STATE = {
    allDeployments:undefined,
    deployment: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_DEPLOYMENT:
            return {
                ...state,
                allDeployments: action.payload,
            };
        case GET_DEPLOYMENT:
            return {
                ...state,
                deployment: action.payload,
            };
        case DELETE_JOB_DEPLOYMENT:
            return {
                ...state,
                allDeployments: state.allDeployments.filter(
                    (item) => item.id !== action.payload
                ),
            };

        default:
            return state;
    }
};
