import isEmpty from "../utils/isEmpty";

import { LOG_OUT_USER, SET_CURRENT_USER, CHECK_USER_ROLE, ROLE_LOADING_DONE, ROLE_LOADING, AUTH_LOADING_STARTS, AUTH_LOADING_ENDS, INCREMENT_PASSWORD_COUNT, CLEAR_PASSWORD_COUNT } from "../actions/types";

const initialState = {
    isAuthenticated: false,
    user: {},
    isAdmin: false,
    userPermissions: {},
    user_role: "",
    loading: false,
    authLoading: false,
    pwdCount: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case LOG_OUT_USER:
            return {
                ...state,
                user: {},
            };
        case ROLE_LOADING:
            return {
                ...state,
                loading: true
            }
        case ROLE_LOADING_DONE: 
            return {
                ...state,
                loading: false
            }
        case CHECK_USER_ROLE:
            return {
                ...state,
                // isAdmin: false,
                isAdmin: action.payload.isUserAdmin,
                userPermissions: action.payload.returnData,
                user_role: action.payload.user_role
                // user_role: "Employee"
            }
        case AUTH_LOADING_STARTS:
            return {
                ...state,
                authLoading: true
            }
        case AUTH_LOADING_ENDS:
            return {
                ...state,
                authLoading: false
            }
        case INCREMENT_PASSWORD_COUNT:
            return {
                ...state,
                pwdCount: state.pwdCount + 1
            }
        case CLEAR_PASSWORD_COUNT:
            return {
                ...state,
                pwdCount: 0
            }
        default:
            return state;
    }
}