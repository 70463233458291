import { GET_ALL_WORK_DAYS, GET_SALARY_PRORATE_SETTING } from "../../actions/types"

const INITIAL_STATE = {
    workDays: undefined,
    salaryProrateSetting: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_WORK_DAYS:
            return {
                ...state,
                workDays: action.payload
            }
        case GET_SALARY_PRORATE_SETTING:
            return {
                ...state,
                salaryProrateSetting: action.payload
            }
        default:
            return state
    }
}