import { BULK_SALARY_ERROR, BULK_SALARY_RESET, BULK_SALARY_SUCCESS, CLEAR_BULK_SALARY_ERROR, LOGGING_OUT_ENDS, LOGGING_OUT_STARTS } from "../actions/types"

const initialState = {
    logoutModal: false,
    bulkSalaryModalError: false,
    bulkSalaryModalSuccess: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGGING_OUT_STARTS:
            return {
                ...state,
                logoutModal: true
            }
        case LOGGING_OUT_ENDS:
            return {
                ...state,
                logoutModal: false
            }
        case BULK_SALARY_ERROR:
            return {
                ...state,
                bulkSalaryModalError: true
            }
        case CLEAR_BULK_SALARY_ERROR:
            return {
                ...state,
                bulkSalaryModalError: false
            }
        case BULK_SALARY_SUCCESS:
            return {
                ...state,
                bulkSalaryModalSuccess: true
            }
        case BULK_SALARY_RESET:
            return {
                ...state,
                bulkSalaryModalSuccess: false,
                bulkSalaryModalError: false
            }
        default:
            return state
    }
}