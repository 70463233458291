import {
  GET_UNITS_IN_DEPARTMENT,
  GET_GRADES_IN_DEPARTMENT,
  GET_DESIGNATIONS_IN_DEPARTMENT,
} from "../actions/types";

const initialState = {
  units: [],
  grades: [],
  designations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_UNITS_IN_DEPARTMENT:
      return {
        ...state,
        units: action.payload,
      };
    case GET_GRADES_IN_DEPARTMENT:
      return {
        ...state,
        grades: action.payload,
      };
    case GET_DESIGNATIONS_IN_DEPARTMENT:
      return {
        ...state,
        designations: action.payload,
      };
    default:
      return state;
  }
};
