import {
    GET_ALL_STAFF_REQUESTS,
    GET_STAFF_REQUEST,
    GET_STAFF_REQUEST_DROPDOWN,
    DELETE_STAFF_REQUEST,
    RECRUIT_MODULE_LOADING_STARTS,
    RECRUIT_MODULE_LOADING_ENDS
} from "../../actions/types";

const INITIAL_STATE = {
    allStaffRequest: undefined,
    staffRequest: undefined,
    requestDropdown: undefined,
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_STAFF_REQUESTS:
            return {
                ...state,
                allStaffRequest: action.payload,
            };
        case GET_STAFF_REQUEST:
            return {
                ...state,
                staffRequest: action.payload,
            };
        case GET_STAFF_REQUEST_DROPDOWN:
            return {
                ...state,
                requestDropdown: action.payload,
            };
        case DELETE_STAFF_REQUEST:
            return {
                ...state,
                allStaffRequest: state.allStaffRequest.filter(
                    (item) => item.id !== action.payload
                ),
            };
        case RECRUIT_MODULE_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case RECRUIT_MODULE_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};
