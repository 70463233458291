import { AWARD_LOADING_ENDS, AWARD_LOADING_STARTS, DELETE_AWARD, GET_ALL_AWARDS } from "../../actions/types"


const INITIAL_STATE = {
    awards: undefined,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AWARD_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case AWARD_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case GET_ALL_AWARDS:
            return {
                ...state,
                awards: action.payload
            }
        case DELETE_AWARD:
            return {
                ...state,
                awards: state.awards.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}