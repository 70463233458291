import React, {useState, useEffect} from 'react'
import { notify } from "../../utils/notification";
import axios from 'axios'
import process from '../../env';
import CONSTANTS from '../../common/constants.json'
import PasswordField from '../../Components/Reusables/PasswordField';
import { ToastContainer } from 'react-toastify';


const service_url = process.env.SERVICE_URL


const ForgotPassword = (props) => {
    const { match , history } = props
    const { token, user_id } = match.params
    const  [userInputs, setUserInputs] = useState({})
    const [errorMsg, setErrorMsg] = useState({})   
    const  [loading, setLoading] = useState(false)
  
    const forgotPassword = async (data)=>{ 

        setLoading(true)

        try {
           
            let result =  await axios.post(`${service_url}reset-password`, data)           
         notify(result.data.returnMessage, 'success') 
         setLoading(false)
         return history.push(CONSTANTS.ROUTES.LOGIN)
                 
        } catch (error) {
          setErrorMsg(error.response.data?.errors)
            setLoading(false)   
            if( error.response.status ===408){
               return notify('Request timeout, try again', 'error')
            }
            if( error.response.status){
               return notify(error.response.data?.message, 'error')
           }
          
    }

}
            useEffect(() => {
                
                return () => {
                    setErrorMsg({})
                  //  cleanup
                }
            }, [])


    const handleChange =(e)=>{
 
            setUserInputs({...userInputs, [e.target.name]: e.target.value})
  
    }



    const {new_password, confirm_newPassword} = userInputs
    const handleSubmit = (e)=>{
        e.preventDefault()
    const data = {
        new_password, 
        confirm_newPassword,
        token
    }
    forgotPassword(data)
        return;
    }
    
    const buttonDisabled = ()=>{

        if(loading) return true

    }
    return (
        <div className="col-lg-7 col-sm-7 col-xs-12 form-container">
            <ToastContainer />
            <form onSubmit= {handleSubmit} className="mt-5 col-lg-8 form-child col-sm-12 col-xs-12">
                <div className="mb-3">
                <h2>Reset Password</h2>
                </div>
                <div className="my-4">
                <p>
                    For security reasons, we do NOT store your password. So be rest
                    assured that we will never send your password via password.
                </p>
                </div>
                <div className="my-3">
                <label htmlFor="new_password">Password</label>
                <PasswordField  
                value={userInputs?.new_password} 
                name='new_password'
                setChange ={handleChange}
                disabled = {loading}
                />
                    {errorMsg?.new_password && <span className="invalid-feedback">
                {errorMsg?.new_password} 
                </span>}
                </div>
                <div className="my-3">
                <label htmlFor="confirm_newPassword">Confirm Password</label>
                <PasswordField  
                value={userInputs?.confirm_newPassword} 
                name='confirm_newPassword'
                setChange ={handleChange}
                disabled = {loading}
                />
                {errorMsg?.confirm_newPassword && <span className="invalid-feedback">
                {errorMsg?.confirm_newPassword}
                </span>}         
                </div>

                <button type="submit" className="button primary" disabled = {loading}>
                    {loading ? (
                        <>
                         <div className="spinner-border .text-light mr-2" role="status">
                 
                         <span className="sr-only">Loading...</span>
                         </div>
                           {/* Send Reset link */} Loading
                           </>

                    ): 'Reset'}
              
        
                </button>

                
  
            </form>
        </div>
    );
}

export default ForgotPassword;