import { DELETE_UNIT, GET_ALL_UNITS } from "../../actions/types"

const INITIAL_STATE = {
    units: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_UNITS:
            return {
                ...state,
                units: action.payload
            }
        case DELETE_UNIT:
            return {
                ...state,
                units: state.units.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}