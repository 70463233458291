import { GET_ALL_ASSETS_CATEGORIES, ASSETS_CATEGORIES_LOADING_STARTS, ASSETS_CATEGORIES_LOADING_ENDS, ASSETS_CATEGORIES_CRUD_STARTS, ASSETS_CATEGORIES_CRUD_ENDS } from "../../actions/types"

const INITIAL_STATE = {
    assetsCategories:[],
    isLoading:false,
    crudOperation:false
}

const assetCategoriesReducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ASSETS_CATEGORIES:
          // console.log("action eduer", action)
            return {
                ...state,
                assetsCategories: action.payload
            }      
        case ASSETS_CATEGORIES_LOADING_STARTS:
            return {
                ...state,
                isLoading:true,
            }      
        case ASSETS_CATEGORIES_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true,
            }      
        case ASSETS_CATEGORIES_CRUD_ENDS:
            return {
                ...state,
                crudOperation:false,
            }      
        case ASSETS_CATEGORIES_LOADING_ENDS:
            return {
                ...state,
                isLoading:false,
            }      
        default:
            return state
    }
}



export default assetCategoriesReducer;