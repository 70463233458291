import React, {useState, useEffect} from 'react'
import { notify } from "../../utils/notification";
import validateEmail from '../../utils/validateEmail';
import process from '../../env';

import axios from 'axios'

const service_url = process.env.SERVICE_URL
const ForgotPassword = () => {
    const  [email, setEmai] = useState('')
    const  [loading, setloading] = useState(false)


    const forgotPassword = async (data)=>{
             setloading(true)
             try {
                 let result =  await axios.post(`${service_url}request-password-reset`, data)
                 setloading(false)   
                 return notify(result.data.returnMessage, 'success')
                 
             } catch (error) {
                 setloading(false)   
                 console.log(error,'errors')
                 if( error?.response?.status ===408){
                    return notify('Request timeout, try again', 'error')
                 }
                 if( error?.response?.status){
                    return notify(error?.response?.data?.returnMessage, 'error')
                 }
               
             }
    
     }

    const handleChange =(e)=>{
        setEmai(e.target.value)
    }
    const handleSubmit = (e)=>{
        e.preventDefault()
        if(!email) return notify('enter an email to proceeed', 'error')
        const emailResult =  validateEmail(email)
    if(!emailResult) return notify('enter a correct email to proceeed', 'error')
    //console.log('data',{email})
    forgotPassword({email})
        return;
    }
    return (
        <div className="col-lg-7 col-sm-7 col-xs-12 form-container">
            <form onSubmit= {handleSubmit}className="mt-5 col-lg-8 form-child col-sm-12 col-xs-12">
                <div className="mb-3">
                <h2>Forgot Password</h2>
                </div>
                <div className="my-4">
                <p>
                    Enter the email address you used when you joined and we’ll send
                    you instructions to reset your password.
                </p>
                <p>
                    For security reasons, we do NOT store your password. So be rest assured that we will never send your password via email.
                </p>
                </div>
                <div className="my-3">
                <label htmlFor="email">Email address</label>
                <input  className="form-control" id="email"  disabled = {loading} value= {email} onChange= {handleChange}/>
                </div>

                <button type="submit" className="button primary" disabled = {loading}>
                    {loading ? (
                        <>
                         <div className="spinner-border .text-light mr-2" role="status">
                 
                         <span className="sr-only">Loading...</span>
                         </div>
                           {/* Send Reset link */} Loading
                           </>

                    ): 'Send Reset link'}
              
        
                </button>

                
  
            </form>
        </div>
    );
}

export default ForgotPassword;