import {
  AWARDS_REPORT,
  FETCH_ERROR,
  GET_ALL_AWARD_TYPES,
} from "../../actions/types";

const initialState = {
  awardsReports: {},
  getAllAwardsTypes: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AWARDS_REPORT:
      return {
        ...state,
        awardsReports: action.payload,
      };

    case GET_ALL_AWARD_TYPES:
      return {
        ...state,
        getAllAwardsTypes: action.payload,
      };
    default:
      return state;
  }
};
