import {
  GET_ALL_APPROVAL_TYPES,
  VIEW_APPROVAL_TYPE,
  EDIT_APPROVAL_TYPE,
  UPDATE_APPROVAL_TYPE,
  DELETE_APPROVAL_TYPE,
  CREATE_APPROVAL_TYPE,
} from "../../actions/types";

const iniState = {
  approvalTypes: [],
  singleApprovalType: {},
};

export default (state = iniState, action) => {
  switch (action.type) {
    case GET_ALL_APPROVAL_TYPES:
      return {
        ...state,
        approvalTypes: action.payload,
      };
    case VIEW_APPROVAL_TYPE:
      return {
        ...state,
        singleApprovalType: action.payload,
      };
    case DELETE_APPROVAL_TYPE:
      console.log(state.approvalTypes, "sup", action.payload);
      return {
        ...state,
        approvalTypes: state.approvalTypes.filter(
          (item) => item.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
