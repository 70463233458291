import axios from "axios"

import CONSTANTS from "../../common/constants.json"
import process from "../../env"
import { notify } from "../../utils/notification"
import { clearNetworkResponse } from "../Network-Actions/networkActions"
import {
    GET_ALL_STAFF_REQUESTS,
    GET_STAFF_REQUEST,
    DELETE_STAFF_REQUEST,
    GET_STAFF_REQUEST_DROPDOWN,
    GET_ERRORS,
    IS_LOADING,
    NOT_LOADING,
    REDIRECT_TO,
    SHOW_NETWORK_RESPONSE,
    FETCH_ERROR,
    GET_STATUS_CODE,
    RECRUIT_MODULE_LOADING_STARTS,
    RECRUIT_MODULE_LOADING_ENDS
} from "../types"

const service_url = process.env.SERVICE_URL

export const getAllStaffRequests = () => {
    return async (dispatch) => {

        try {
            dispatch({
                type: RECRUIT_MODULE_LOADING_STARTS
            })
    
            const res = await axios.get(`${service_url}get-all-requests-for-new-staff`);
    
            dispatch({
                type: GET_ALL_STAFF_REQUESTS,
                payload: res.data.returnData
            });
    
            dispatch({
                type: RECRUIT_MODULE_LOADING_ENDS
            })
        } catch (error) {
            dispatch({ type: FETCH_ERROR })
            dispatch({
                type: RECRUIT_MODULE_LOADING_ENDS
            })
            if(error.response.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            }
        }
    }
}

export const getStaffRequest = (id) => {
    return async (dispatch) => {

        const res = await axios.get(`${service_url}get-request-for-new-staff/${id}`)

        dispatch({
            type: GET_STAFF_REQUEST,
            payload: res.data.returnData
        })
    }
}

export const getStaffRequestDropdown = (id) => {
    return async (dispatch) => {

        const res = await axios.get(`${service_url}get-new-staff-requisitions`)

        dispatch({
            type: GET_STAFF_REQUEST_DROPDOWN,
            payload: res.data.returnData
        })
    }
}

export const addRequest = (data) => {
    return (dispatch) => {
        dispatch({
            type: RECRUIT_MODULE_LOADING_STARTS
        })

        const sendDataUrl = axios.post(`${service_url}save-request-for-new-staff`, data)
        const getNewdataUrl = axios.get(`${service_url}get-all-requests-for-new-staff`)

        axios.all([sendDataUrl, getNewdataUrl]).then(axios.spread((...responses) => {
            if (responses[0].status === 200 && responses[1].status === 200) {

                dispatch({
                    type: GET_ALL_STAFF_REQUESTS,
                    payload: responses[1].data.returnData
                });

                dispatch({
                    type: RECRUIT_MODULE_LOADING_ENDS
                })

                dispatch({
                    type: REDIRECT_TO,
                    payload: CONSTANTS.ROUTES.RECRUITMENT
                });

                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: responses[0]
                })

                dispatch(clearNetworkResponse())
            }
        })).catch((err) => {
            dispatch({
                type: RECRUIT_MODULE_LOADING_ENDS
            })
            if (err.response) {

                if (err.response.status === 422) {

                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });

                    return notify('There were some errors in your input', 'error')

                }else if(err.response.status === 500) {
                    dispatch({
                        type: GET_STATUS_CODE,
                        payload: err.response
                    })
                } else {

                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {

                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        });
    }
}

export const updateRequest = (data, id) => {
    return (dispatch) => {

        dispatch({
            type: RECRUIT_MODULE_LOADING_STARTS
        })

        const sendDataUrl = axios.patch(`${service_url}update-request-for-staff/${id}`, data)
        const getNewdataUrl = axios.get(`${service_url}get-all-requests-for-new-staff`)

        axios.all([sendDataUrl, getNewdataUrl]).then(axios.spread((...responses) => {
            if (responses[0].status === 200 && responses[1].status === 200) {

                dispatch({
                    type: RECRUIT_MODULE_LOADING_ENDS
                })

                dispatch({
                    type: GET_ALL_STAFF_REQUESTS,
                    payload: responses[1].data.returnData
                });

                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: responses[0]
                })

                dispatch({
                    type: REDIRECT_TO,
                    payload: CONSTANTS.ROUTES.RECRUITMENT
                })

                dispatch(clearNetworkResponse())
            }
        })).catch((err) => {
            dispatch({
                type: RECRUIT_MODULE_LOADING_ENDS
            })
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else if(err.response.status === 500) {
                    dispatch({
                        type: GET_STATUS_CODE,
                        payload: err.response
                    })
                } else {
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                dispatch({
                    type: NOT_LOADING,
                });
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        });
    }
}

export const deleteStaffRequest = (id) => {
    return (dispatch) => {
        axios.delete(`${service_url}delete-staff-request/${id}`).then(res => {
            dispatch({
                type: DELETE_STAFF_REQUEST,
                payload: id
            })
            dispatch({
                type: SHOW_NETWORK_RESPONSE,
                payload: res
            })
            dispatch(clearNetworkResponse())
        }).catch((err) => {
            if (err.response) {
                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: err.response.data.returnMessage
                })
                dispatch(clearNetworkResponse())
            } else {
                return notify('Sorry, something went wrong! Most likely your network,', 'error')
            }
        });
    }
}



export const searchStaffRequests = (query) => {
    return async (dispatch) => {

        try {
            console.log(`%c you are searching for ${query}`, 'background:green; color:white; font-size:20px')
            // dispatch({
            //     type: IS_LOADING
            // });
    
            // const res = await axios.get(`${service_url}get-all-requests-for-new-staff ${query}`);
    
            // dispatch({
            //     type: GET_ALL_STAFF_REQUESTS,
            //     payload: res.data.returnData
            // });
    
            // dispatch({
            //     type: NOT_LOADING,
            // });
        } catch (error) {
            console.log("i am called")
            console.log(error)
            dispatch({ type: FETCH_ERROR })
        }
    }
}
