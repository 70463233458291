import { CLEAR_NETWORK_RESPONSE, REDIRECT_TO, SHOW_NETWORK_RESPONSE, FETCH_ERROR } from "../actions/types"

const initialState = {
    response: null,
    statusCode: null,
    redirectTo: null,
    fetchError: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NETWORK_RESPONSE:
            return {
                ...state,
                response: action.payload.data.returnMessage,
                statusCode: action.payload.status
            }
        case CLEAR_NETWORK_RESPONSE:
            return {
                ...state,
                response: null,
                statusCode: null,
                redirectTo: null,
                fetchError: false
            }
        case REDIRECT_TO:
            return {
                ...state,
                redirectTo: action.payload
            }
        case FETCH_ERROR:
            return {
                ...state,
                fetchError: true
            }
        default:
            return state
    }
}