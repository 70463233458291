import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import CONSTANTS from "../../common/constants.json";

const LandingPage = () => {
  const [isClickeduHamburger, setIsClickedHamburger] = useState(false);
  const [isClickedArrow, setIsClickedArrow] = useState(false);

  const clickHandler = (e) => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-menu");

    if (isClickeduHamburger) {
      hamburger.classList.add("active");
      navMenu.classList.add("active");
    } else {
      hamburger.classList.remove("active");
      navMenu.classList.remove("active");
    }
  };

  const clickHandlerArrow = () => {
    const optionsContainer = document.querySelector(".options-link");

    if (isClickedArrow) {
      optionsContainer.classList.add("d-none");
    } else {
      optionsContainer.classList.remove("d-none");
    }
  };

  return (
    <div class="main--container">
      <div class="">
        <div class="nav-wrapper">
          <nav
            class="
                  navbar-landing navbar-expand-lg
                  nav-custom
                  fixed-top
                  navbar-light
                  bg-transparent
                "
          >
            <div class="container">
              <a class="navbar-brand mr-auto" href="#">
                <img src={Logo} alt="" width="100%" height="100%" />
              </a>
              {/* <!-- <button
                    class="navbar-toggler hamburger"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button> --> */}
              <button
                class="hamburger btn-hamburger"
                onClick={(e) => {
                  setIsClickedHamburger(!isClickeduHamburger);
                  clickHandler(e);
                }}
              >
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
              </button>
              <div class="p-3 nav-menu" id="navbarTogglerDemo01">
                <ul
                  class="
                        desktop-nav
                        navbar-nav
                        ml-5
                        d-flex
                        justify-content-between
                   
                        align-items-end
                        mt-lg-0
                      "
                >
                  <li class="nav-item">Home</li>
                  <li class="">
                    <div
                      class="nav-link__dropdown"
                      onClick={(e) => {
                        setIsClickedArrow(!isClickedArrow);
                        clickHandlerArrow(e);
                      }}
                    >
                      <a
                        href="#"
                        class="
                              d-flex
                              justify-content-between
                              align-items-center
                              options-link__dropdown
                            "
                      >
                        <span>Product</span>
                        <svg
                          class="arrow-down"
                          width="10"
                          height="7"
                          viewBox="0 0 10 7"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.175 0.158325L5 3.97499L8.825 0.158325L10 1.33332L5 6.33332L0 1.33332L1.175 0.158325Z" />
                        </svg>
                      </a>
                    </div>
                    <div class="options-link d-none">
                      <a href="#"> first option</a>
                      <a href="#">second option</a>
                    </div>
                  </li>
                  <li class="nav-item">Services</li>
                  <li class="nav-item">Pricing</li>
                  <li class="nav-item">Collaborative edge</li>
                  {/* <li class="nav-item active">
                        <div class="text-uppercase font-weight-bold">features</div>
                      </li>  */}
                </ul>
                <ul class="navbar-nav align-items-center mt-lg-0 ml-auto">
                  <li class="nav-item ml-auto">
                    <button
                      class="btn btn-white mx-lg-2 mb-lg-0  mb-4"
                      type="submit"
                    >
                      <Link to={CONSTANTS.ROUTES.LOGIN}>Sign in</Link>
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-green mx-lg-2" type="submit">
                      <Link to={CONSTANTS.ROUTES.REGISTER}>Get started</Link>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div class="container-sm">
          <div class="header-body">
            <div class="row">
              <div class="col-lg-5">
                <p class="mb-5 h1 font-weight-bold">
                  Simple, affordable payroll for small businesses
                </p>
                <p>
                  BMAC Invoicing is a free invoicing tool that allowsyou to
                  create professional invoices in seconds.
                </p>
                <button class="btn btn-green mx-0" type="submit">
                  Start my 30days free trial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-body">
        <div class="container mt-5">
          <h1 class="font-weight-bold text-center">
            Take a look at our big set of features
          </h1>

          <div class="row">
            <div class="col-lg-4">
              <div class="card card-custom-landing">
                <div class="">
                  <svg
                    width="77"
                    height="77"
                    viewBox="0 0 77 77"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9668 40.3092L58.9665 40.2419L38.419 52.9812L17.9668 40.3092Z"
                      fill="#EC723A"
                    />
                    <path
                      d="M58.7923 40.3358L17.7926 40.4009L38.3407 27.6627L58.7923 40.3358Z"
                      fill="#ED7230"
                    />
                    <g opacity="0.759693">
                      <path
                        d="M54.8159 29.7666L54.8457 47.9486L21.9146 48.0026L21.8848 29.8206L54.8159 29.7666Z"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M58.8991 40.1073L58.9407 65.434L17.941 65.5012L58.8991 40.1073Z"
                      fill="#E7630F"
                    />
                    <path
                      d="M18.008 65.6358L17.9665 40.3092L38.4187 52.9812L18.008 65.6358Z"
                      fill="#EC723A"
                    />
                  </svg>
                </div>
                <div class="card-body">
                  <h5 class="card-title font-weight-bold">
                    Built-in digital wallet
                  </h5>
                  <p class="card-text">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </p>
                  <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 my-3 my-lg-0">
              <div class="card card-custom-landing">
                <div class="">
                  <svg
                    width="77"
                    height="77"
                    viewBox="0 0 77 77"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9668 40.3092L58.9665 40.2419L38.419 52.9812L17.9668 40.3092Z"
                      fill="#EC723A"
                    />
                    <path
                      d="M58.7923 40.3358L17.7926 40.4009L38.3407 27.6627L58.7923 40.3358Z"
                      fill="#ED7230"
                    />
                    <g opacity="0.759693">
                      <path
                        d="M54.8159 29.7666L54.8457 47.9486L21.9146 48.0026L21.8848 29.8206L54.8159 29.7666Z"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M58.8991 40.1073L58.9407 65.434L17.941 65.5012L58.8991 40.1073Z"
                      fill="#E7630F"
                    />
                    <path
                      d="M18.008 65.6358L17.9665 40.3092L38.4187 52.9812L18.008 65.6358Z"
                      fill="#EC723A"
                    />
                  </svg>
                </div>
                <div class="card-body">
                  <h5 class="card-title font-weight-bold">
                    256-bit encryption
                  </h5>
                  <p class="card-text">
                    This card has supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-custom-landing">
                <div class="">
                  <svg
                    width="77"
                    height="77"
                    viewBox="0 0 77 77"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9668 40.3092L58.9665 40.2419L38.419 52.9812L17.9668 40.3092Z"
                      fill="#EC723A"
                    />
                    <path
                      d="M58.7923 40.3358L17.7926 40.4009L38.3407 27.6627L58.7923 40.3358Z"
                      fill="#ED7230"
                    />
                    <g opacity="0.759693">
                      <path
                        d="M54.8159 29.7666L54.8457 47.9486L21.9146 48.0026L21.8848 29.8206L54.8159 29.7666Z"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M58.8991 40.1073L58.9407 65.434L17.941 65.5012L58.8991 40.1073Z"
                      fill="#E7630F"
                    />
                    <path
                      d="M18.008 65.6358L17.9665 40.3092L38.4187 52.9812L18.008 65.6358Z"
                      fill="#EC723A"
                    />
                  </svg>
                </div>
                <div class="card-body">
                  <h5 class="card-title font-weight-bold">
                    Send & receive payments
                  </h5>
                  <p class="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This card has even longer
                    content than the first to show that equal height action.
                  </p>
                  <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-4">
              <div class="card card-custom-landing">
                <div class="">
                  <svg
                    width="77"
                    height="77"
                    viewBox="0 0 77 77"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9668 40.3092L58.9665 40.2419L38.419 52.9812L17.9668 40.3092Z"
                      fill="#EC723A"
                    />
                    <path
                      d="M58.7923 40.3358L17.7926 40.4009L38.3407 27.6627L58.7923 40.3358Z"
                      fill="#ED7230"
                    />
                    <g opacity="0.759693">
                      <path
                        d="M54.8159 29.7666L54.8457 47.9486L21.9146 48.0026L21.8848 29.8206L54.8159 29.7666Z"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M58.8991 40.1073L58.9407 65.434L17.941 65.5012L58.8991 40.1073Z"
                      fill="#E7630F"
                    />
                    <path
                      d="M18.008 65.6358L17.9665 40.3092L38.4187 52.9812L18.008 65.6358Z"
                      fill="#EC723A"
                    />
                  </svg>
                </div>
                <div class="card-body">
                  <h5 class="card-title font-weight-bold">
                    Monthly & weekly reports
                  </h5>
                  <p class="card-text">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </p>
                  <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 my-3 my-lg-0">
              <div class="card card-custom-landing">
                <div class="">
                  <svg
                    width="77"
                    height="77"
                    viewBox="0 0 77 77"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9668 40.3092L58.9665 40.2419L38.419 52.9812L17.9668 40.3092Z"
                      fill="#EC723A"
                    />
                    <path
                      d="M58.7923 40.3358L17.7926 40.4009L38.3407 27.6627L58.7923 40.3358Z"
                      fill="#ED7230"
                    />
                    <g opacity="0.759693">
                      <path
                        d="M54.8159 29.7666L54.8457 47.9486L21.9146 48.0026L21.8848 29.8206L54.8159 29.7666Z"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M58.8991 40.1073L58.9407 65.434L17.941 65.5012L58.8991 40.1073Z"
                      fill="#E7630F"
                    />
                    <path
                      d="M18.008 65.6358L17.9665 40.3092L38.4187 52.9812L18.008 65.6358Z"
                      fill="#EC723A"
                    />
                  </svg>
                </div>
                <div class="card-body">
                  <h5 class="card-title font-weight-bold">
                    256-bit encryption
                  </h5>
                  <p class="card-text">
                    This card has supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-custom-landing">
                <div class="">
                  <svg
                    width="77"
                    height="77"
                    viewBox="0 0 77 77"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9668 40.3092L58.9665 40.2419L38.419 52.9812L17.9668 40.3092Z"
                      fill="#EC723A"
                    />
                    <path
                      d="M58.7923 40.3358L17.7926 40.4009L38.3407 27.6627L58.7923 40.3358Z"
                      fill="#ED7230"
                    />
                    <g opacity="0.759693">
                      <path
                        d="M54.8159 29.7666L54.8457 47.9486L21.9146 48.0026L21.8848 29.8206L54.8159 29.7666Z"
                        fill="white"
                      />
                    </g>
                    <path
                      d="M58.8991 40.1073L58.9407 65.434L17.941 65.5012L58.8991 40.1073Z"
                      fill="#E7630F"
                    />
                    <path
                      d="M18.008 65.6358L17.9665 40.3092L38.4187 52.9812L18.008 65.6358Z"
                      fill="#EC723A"
                    />
                  </svg>
                </div>
                <div class="card-body">
                  <h5 class="card-title font-weight-bold">
                    Send & receive payments
                  </h5>
                  <p class="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This card has even longer
                    content than the first to show that equal height action.
                  </p>
                  <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <div class="container mt-5 p-2">
            {/* <!-- <h1 class="font-weight-bold text-center">Our products</h1> --> */}
            <div class="row first-group align-items-center">
              <div class="col-lg-6">
                <div class="mb-5">
                  <img
                    src="./assets/Macbook.png"
                    alt=""
                    class="dashboard-img position-lg-absolute"
                    height="100%"
                    width="100%"
                  />
                  {/* <!-- <div class="green-eclipse_outline d-none d-lg-flex"></div> --> */}
                </div>
              </div>
              <div class="col-lg-6 ml-auto p-sm-4 mt-xs-5 right-text-wrapper">
                <div class="right-text right-text__animation">
                  <h1 class="font-weight-bold mb-4">
                    Send and recieve payments easily
                  </h1>
                  <p>
                    BMAC Invoice is an online invoicing software that helps you
                    craft professional invoices, record payment, send quotes and
                    get paid faster—all for free!
                  </p>
                  <ul class="list-features__container">
                    <li class="list-feature">
                      <div class="row">
                        <div class="col-2">
                          <svg
                            width="100%"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="6.98643">
                              <path
                                d="M32.2402 22.2986C32.2402 16.1272 27.0928 11.1581 20.8572 11.4505C15.3682 11.7078 10.8408 16.1952 10.5367 21.6817C10.3159 25.6632 12.2469 29.201 15.2669 31.2626C15.6643 31.5339 15.8921 31.9923 15.8921 32.4735V33.2438H27.1495V32.2758C27.1495 31.8122 27.3579 31.3652 27.7336 31.0936C30.4608 29.1216 32.2402 25.9213 32.2402 22.2986Z"
                                fill="#FFCE00"
                              />
                            </g>
                            <path
                              d="M28.6341 36.5528H14.8889C13.9752 36.5528 13.2344 35.8121 13.2344 34.8983C13.2344 33.9845 13.9752 33.2439 14.8889 33.2439H28.6341C29.5478 33.2439 30.2886 33.9845 30.2886 34.8983C30.2886 35.8121 29.5478 36.5528 28.6341 36.5528Z"
                              fill="#BEB3B4"
                            />
                            <path
                              d="M28.634 36.0013H14.8889C14.0696 36.0013 13.3941 35.4043 13.2622 34.6226C13.247 34.7127 13.2344 34.8038 13.2344 34.8983C13.2344 35.812 13.9751 36.5528 14.8889 36.5528H28.634C29.5478 36.5528 30.2885 35.812 30.2885 34.8983C30.2885 34.8038 30.2759 34.7127 30.2607 34.6226C30.1288 35.4043 29.4533 36.0013 28.634 36.0013Z"
                              fill="#A49D9E"
                            />
                            <path
                              d="M27.5083 39.8619H16.0136C15.1696 39.8619 14.4854 39.1777 14.4854 38.3336V38.0811C14.4854 37.2371 15.1696 36.5529 16.0136 36.5529H27.5083C28.3523 36.5529 29.0366 37.2371 29.0366 38.0811V38.3336C29.0366 39.1777 28.3523 39.8619 27.5083 39.8619Z"
                              fill="#AAA4A6"
                            />
                            <path
                              d="M27.6248 39.3104H15.8971C15.1979 39.3104 14.6217 38.7133 14.5091 37.9315C14.4961 38.0217 14.4854 38.1128 14.4854 38.2074C14.4854 39.121 15.1174 39.8618 15.8971 39.8618H27.6248C28.4045 39.8618 29.0366 39.121 29.0366 38.2074C29.0366 38.1128 29.0257 38.0217 29.0128 37.9315C28.9002 38.7133 28.3239 39.3104 27.6248 39.3104Z"
                              fill="#848081"
                            />
                            <path
                              d="M21.761 43.8495C19.7675 43.8495 18.1514 42.2335 18.1514 40.2399V39.8618H25.3706V40.2399C25.3706 42.2335 23.7546 43.8495 21.761 43.8495Z"
                              fill="#848081"
                            />
                            <path
                              d="M25.3705 39.865V40.2401C25.3705 40.3699 25.3633 40.4997 25.3488 40.6295H18.1729C18.1585 40.4997 18.1514 40.3699 18.1514 40.2401V39.865H25.3705Z"
                              fill="#6B6A6B"
                            />
                            <path
                              d="M28.8606 22.1287C28.8606 26.2604 25.5112 29.6098 21.3795 29.6098C17.2478 29.6098 13.8984 26.2604 13.8984 22.1287C13.8984 17.997 17.2478 14.6476 21.3795 14.6476C25.5112 14.6476 28.8606 17.997 28.8606 22.1287Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M28.0626 22.1287C28.0626 25.8197 25.0704 28.8118 21.3794 28.8118C17.6884 28.8118 14.6963 25.8197 14.6963 22.1287C14.6963 18.4377 17.6884 15.4456 21.3794 15.4456C25.0704 15.4456 28.0626 18.4377 28.0626 22.1287Z"
                              fill="#E4A314"
                            />
                            <path
                              d="M27.614 22.1287C27.614 25.5718 24.8228 28.363 21.3798 28.363C17.9367 28.363 15.1455 25.5718 15.1455 22.1287C15.1455 18.6856 17.9367 15.8945 21.3798 15.8945C24.8228 15.8945 27.614 18.6856 27.614 22.1287Z"
                              fill="#F5B81E"
                            />
                            <path
                              d="M21.7607 5.1143C21.7607 5.04085 21.7607 5 21.7607 5"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M33.7041 10.4046C33.756 10.3526 33.7849 10.3237 33.7849 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M38.4082 22.5901C38.4817 22.5901 38.5225 22.5901 38.5225 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.81813 10.4046C9.76619 10.3526 9.7373 10.3237 9.7373 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M5.1143 22.5901C5.04085 22.5901 5 22.5901 5 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M24.2832 23.325C24.2832 24.3013 23.5086 24.866 22.0237 24.866H19.3974C19.2345 24.866 19.1025 24.734 19.1025 24.5711V19.5124C19.1025 19.3496 19.2345 19.2175 19.3974 19.2175H21.8626C23.2746 19.2175 24.0007 19.8066 24.0007 20.6863C24.0007 21.251 23.7103 21.6866 23.2503 21.9287C23.8797 22.1305 24.2832 22.6147 24.2832 23.325ZM20.4021 20.2021V21.5336H21.701C22.3384 21.5336 22.6853 21.3075 22.6853 20.8637C22.6853 20.4199 22.3384 20.2021 21.701 20.2021H20.4021ZM22.9678 23.1876C22.9678 22.7116 22.6044 22.4856 21.9271 22.4856H20.4021V23.8815H21.9271C22.6044 23.8815 22.9678 23.6718 22.9678 23.1876Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 17.4114C20.1328 17.4114 19.9668 17.5773 19.9668 17.7821V19.2175H20.7082V17.7821C20.7082 17.5773 20.5422 17.4114 20.3375 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 17.4114C21.5839 17.4114 21.418 17.5773 21.418 17.7821V19.2175H22.1592V17.7821C22.1592 17.5773 21.9933 17.4114 21.7886 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 26.5134C21.9933 26.5134 22.1592 26.3474 22.1592 26.1427V24.7073H21.418V26.1427C21.418 26.3474 21.5839 26.5134 21.7886 26.5134Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 26.5134C20.5422 26.5134 20.7082 26.3474 20.7082 26.1427V24.7073H19.9668V26.1427C19.9668 26.3474 20.1328 26.5134 20.3375 26.5134Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                        <div class="col-10">
                          <h3 class="font-weight-bold">Send payments</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eos molestias aliquid dignissimos a eius nulla
                            sunt asperiores.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="list-feature">
                      <div class="row">
                        <div class="col-2">
                          <svg
                            width="100%"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="6.98643">
                              <path
                                d="M32.2402 22.2986C32.2402 16.1272 27.0928 11.1581 20.8572 11.4505C15.3682 11.7078 10.8408 16.1952 10.5367 21.6817C10.3159 25.6632 12.2469 29.201 15.2669 31.2626C15.6643 31.5339 15.8921 31.9923 15.8921 32.4735V33.2438H27.1495V32.2758C27.1495 31.8122 27.3579 31.3652 27.7336 31.0936C30.4608 29.1216 32.2402 25.9213 32.2402 22.2986Z"
                                fill="#FFCE00"
                              />
                            </g>
                            <path
                              d="M28.6341 36.5528H14.8889C13.9752 36.5528 13.2344 35.8121 13.2344 34.8983C13.2344 33.9845 13.9752 33.2439 14.8889 33.2439H28.6341C29.5478 33.2439 30.2886 33.9845 30.2886 34.8983C30.2886 35.8121 29.5478 36.5528 28.6341 36.5528Z"
                              fill="#BEB3B4"
                            />
                            <path
                              d="M28.634 36.0013H14.8889C14.0696 36.0013 13.3941 35.4043 13.2622 34.6226C13.247 34.7127 13.2344 34.8038 13.2344 34.8983C13.2344 35.812 13.9751 36.5528 14.8889 36.5528H28.634C29.5478 36.5528 30.2885 35.812 30.2885 34.8983C30.2885 34.8038 30.2759 34.7127 30.2607 34.6226C30.1288 35.4043 29.4533 36.0013 28.634 36.0013Z"
                              fill="#A49D9E"
                            />
                            <path
                              d="M27.5083 39.8619H16.0136C15.1696 39.8619 14.4854 39.1777 14.4854 38.3336V38.0811C14.4854 37.2371 15.1696 36.5529 16.0136 36.5529H27.5083C28.3523 36.5529 29.0366 37.2371 29.0366 38.0811V38.3336C29.0366 39.1777 28.3523 39.8619 27.5083 39.8619Z"
                              fill="#AAA4A6"
                            />
                            <path
                              d="M27.6248 39.3104H15.8971C15.1979 39.3104 14.6217 38.7133 14.5091 37.9315C14.4961 38.0217 14.4854 38.1128 14.4854 38.2074C14.4854 39.121 15.1174 39.8618 15.8971 39.8618H27.6248C28.4045 39.8618 29.0366 39.121 29.0366 38.2074C29.0366 38.1128 29.0257 38.0217 29.0128 37.9315C28.9002 38.7133 28.3239 39.3104 27.6248 39.3104Z"
                              fill="#848081"
                            />
                            <path
                              d="M21.761 43.8495C19.7675 43.8495 18.1514 42.2335 18.1514 40.2399V39.8618H25.3706V40.2399C25.3706 42.2335 23.7546 43.8495 21.761 43.8495Z"
                              fill="#848081"
                            />
                            <path
                              d="M25.3705 39.865V40.2401C25.3705 40.3699 25.3633 40.4997 25.3488 40.6295H18.1729C18.1585 40.4997 18.1514 40.3699 18.1514 40.2401V39.865H25.3705Z"
                              fill="#6B6A6B"
                            />
                            <path
                              d="M28.8606 22.1287C28.8606 26.2604 25.5112 29.6098 21.3795 29.6098C17.2478 29.6098 13.8984 26.2604 13.8984 22.1287C13.8984 17.997 17.2478 14.6476 21.3795 14.6476C25.5112 14.6476 28.8606 17.997 28.8606 22.1287Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M28.0626 22.1287C28.0626 25.8197 25.0704 28.8118 21.3794 28.8118C17.6884 28.8118 14.6963 25.8197 14.6963 22.1287C14.6963 18.4377 17.6884 15.4456 21.3794 15.4456C25.0704 15.4456 28.0626 18.4377 28.0626 22.1287Z"
                              fill="#E4A314"
                            />
                            <path
                              d="M27.614 22.1287C27.614 25.5718 24.8228 28.363 21.3798 28.363C17.9367 28.363 15.1455 25.5718 15.1455 22.1287C15.1455 18.6856 17.9367 15.8945 21.3798 15.8945C24.8228 15.8945 27.614 18.6856 27.614 22.1287Z"
                              fill="#F5B81E"
                            />
                            <path
                              d="M21.7607 5.1143C21.7607 5.04085 21.7607 5 21.7607 5"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M33.7041 10.4046C33.756 10.3526 33.7849 10.3237 33.7849 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M38.4082 22.5901C38.4817 22.5901 38.5225 22.5901 38.5225 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.81813 10.4046C9.76619 10.3526 9.7373 10.3237 9.7373 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M5.1143 22.5901C5.04085 22.5901 5 22.5901 5 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M24.2832 23.325C24.2832 24.3013 23.5086 24.866 22.0237 24.866H19.3974C19.2345 24.866 19.1025 24.734 19.1025 24.5711V19.5124C19.1025 19.3496 19.2345 19.2175 19.3974 19.2175H21.8626C23.2746 19.2175 24.0007 19.8066 24.0007 20.6863C24.0007 21.251 23.7103 21.6866 23.2503 21.9287C23.8797 22.1305 24.2832 22.6147 24.2832 23.325ZM20.4021 20.2021V21.5336H21.701C22.3384 21.5336 22.6853 21.3075 22.6853 20.8637C22.6853 20.4199 22.3384 20.2021 21.701 20.2021H20.4021ZM22.9678 23.1876C22.9678 22.7116 22.6044 22.4856 21.9271 22.4856H20.4021V23.8815H21.9271C22.6044 23.8815 22.9678 23.6718 22.9678 23.1876Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 17.4114C20.1328 17.4114 19.9668 17.5773 19.9668 17.7821V19.2175H20.7082V17.7821C20.7082 17.5773 20.5422 17.4114 20.3375 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 17.4114C21.5839 17.4114 21.418 17.5773 21.418 17.7821V19.2175H22.1592V17.7821C22.1592 17.5773 21.9933 17.4114 21.7886 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 26.5134C21.9933 26.5134 22.1592 26.3474 22.1592 26.1427V24.7073H21.418V26.1427C21.418 26.3474 21.5839 26.5134 21.7886 26.5134Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 26.5134C20.5422 26.5134 20.7082 26.3474 20.7082 26.1427V24.7073H19.9668V26.1427C19.9668 26.3474 20.1328 26.5134 20.3375 26.5134Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                        <div class="col-10">
                          <h3 class="font-weight-bold">Send payments</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eos molestias aliquid dignissimos a eius nulla
                            sunt asperiores.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="list-feature">
                      <div class="row">
                        <div class="col-2">
                          <svg
                            width="100%"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="6.98643">
                              <path
                                d="M32.2402 22.2986C32.2402 16.1272 27.0928 11.1581 20.8572 11.4505C15.3682 11.7078 10.8408 16.1952 10.5367 21.6817C10.3159 25.6632 12.2469 29.201 15.2669 31.2626C15.6643 31.5339 15.8921 31.9923 15.8921 32.4735V33.2438H27.1495V32.2758C27.1495 31.8122 27.3579 31.3652 27.7336 31.0936C30.4608 29.1216 32.2402 25.9213 32.2402 22.2986Z"
                                fill="#FFCE00"
                              />
                            </g>
                            <path
                              d="M28.6341 36.5528H14.8889C13.9752 36.5528 13.2344 35.8121 13.2344 34.8983C13.2344 33.9845 13.9752 33.2439 14.8889 33.2439H28.6341C29.5478 33.2439 30.2886 33.9845 30.2886 34.8983C30.2886 35.8121 29.5478 36.5528 28.6341 36.5528Z"
                              fill="#BEB3B4"
                            />
                            <path
                              d="M28.634 36.0013H14.8889C14.0696 36.0013 13.3941 35.4043 13.2622 34.6226C13.247 34.7127 13.2344 34.8038 13.2344 34.8983C13.2344 35.812 13.9751 36.5528 14.8889 36.5528H28.634C29.5478 36.5528 30.2885 35.812 30.2885 34.8983C30.2885 34.8038 30.2759 34.7127 30.2607 34.6226C30.1288 35.4043 29.4533 36.0013 28.634 36.0013Z"
                              fill="#A49D9E"
                            />
                            <path
                              d="M27.5083 39.8619H16.0136C15.1696 39.8619 14.4854 39.1777 14.4854 38.3336V38.0811C14.4854 37.2371 15.1696 36.5529 16.0136 36.5529H27.5083C28.3523 36.5529 29.0366 37.2371 29.0366 38.0811V38.3336C29.0366 39.1777 28.3523 39.8619 27.5083 39.8619Z"
                              fill="#AAA4A6"
                            />
                            <path
                              d="M27.6248 39.3104H15.8971C15.1979 39.3104 14.6217 38.7133 14.5091 37.9315C14.4961 38.0217 14.4854 38.1128 14.4854 38.2074C14.4854 39.121 15.1174 39.8618 15.8971 39.8618H27.6248C28.4045 39.8618 29.0366 39.121 29.0366 38.2074C29.0366 38.1128 29.0257 38.0217 29.0128 37.9315C28.9002 38.7133 28.3239 39.3104 27.6248 39.3104Z"
                              fill="#848081"
                            />
                            <path
                              d="M21.761 43.8495C19.7675 43.8495 18.1514 42.2335 18.1514 40.2399V39.8618H25.3706V40.2399C25.3706 42.2335 23.7546 43.8495 21.761 43.8495Z"
                              fill="#848081"
                            />
                            <path
                              d="M25.3705 39.865V40.2401C25.3705 40.3699 25.3633 40.4997 25.3488 40.6295H18.1729C18.1585 40.4997 18.1514 40.3699 18.1514 40.2401V39.865H25.3705Z"
                              fill="#6B6A6B"
                            />
                            <path
                              d="M28.8606 22.1287C28.8606 26.2604 25.5112 29.6098 21.3795 29.6098C17.2478 29.6098 13.8984 26.2604 13.8984 22.1287C13.8984 17.997 17.2478 14.6476 21.3795 14.6476C25.5112 14.6476 28.8606 17.997 28.8606 22.1287Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M28.0626 22.1287C28.0626 25.8197 25.0704 28.8118 21.3794 28.8118C17.6884 28.8118 14.6963 25.8197 14.6963 22.1287C14.6963 18.4377 17.6884 15.4456 21.3794 15.4456C25.0704 15.4456 28.0626 18.4377 28.0626 22.1287Z"
                              fill="#E4A314"
                            />
                            <path
                              d="M27.614 22.1287C27.614 25.5718 24.8228 28.363 21.3798 28.363C17.9367 28.363 15.1455 25.5718 15.1455 22.1287C15.1455 18.6856 17.9367 15.8945 21.3798 15.8945C24.8228 15.8945 27.614 18.6856 27.614 22.1287Z"
                              fill="#F5B81E"
                            />
                            <path
                              d="M21.7607 5.1143C21.7607 5.04085 21.7607 5 21.7607 5"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M33.7041 10.4046C33.756 10.3526 33.7849 10.3237 33.7849 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M38.4082 22.5901C38.4817 22.5901 38.5225 22.5901 38.5225 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.81813 10.4046C9.76619 10.3526 9.7373 10.3237 9.7373 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M5.1143 22.5901C5.04085 22.5901 5 22.5901 5 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M24.2832 23.325C24.2832 24.3013 23.5086 24.866 22.0237 24.866H19.3974C19.2345 24.866 19.1025 24.734 19.1025 24.5711V19.5124C19.1025 19.3496 19.2345 19.2175 19.3974 19.2175H21.8626C23.2746 19.2175 24.0007 19.8066 24.0007 20.6863C24.0007 21.251 23.7103 21.6866 23.2503 21.9287C23.8797 22.1305 24.2832 22.6147 24.2832 23.325ZM20.4021 20.2021V21.5336H21.701C22.3384 21.5336 22.6853 21.3075 22.6853 20.8637C22.6853 20.4199 22.3384 20.2021 21.701 20.2021H20.4021ZM22.9678 23.1876C22.9678 22.7116 22.6044 22.4856 21.9271 22.4856H20.4021V23.8815H21.9271C22.6044 23.8815 22.9678 23.6718 22.9678 23.1876Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 17.4114C20.1328 17.4114 19.9668 17.5773 19.9668 17.7821V19.2175H20.7082V17.7821C20.7082 17.5773 20.5422 17.4114 20.3375 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 17.4114C21.5839 17.4114 21.418 17.5773 21.418 17.7821V19.2175H22.1592V17.7821C22.1592 17.5773 21.9933 17.4114 21.7886 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 26.5134C21.9933 26.5134 22.1592 26.3474 22.1592 26.1427V24.7073H21.418V26.1427C21.418 26.3474 21.5839 26.5134 21.7886 26.5134Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 26.5134C20.5422 26.5134 20.7082 26.3474 20.7082 26.1427V24.7073H19.9668V26.1427C19.9668 26.3474 20.1328 26.5134 20.3375 26.5134Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                        <div class="col-10">
                          <h3 class="font-weight-bold">Send payments</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eos molestias aliquid dignissimos a eius nulla
                            sunt.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row second-group">
              <div class="col-lg-6 ml-auto p-sm-4 mt-xs-5 left-text-wrapper">
                <div class="left-text left-text__animation">
                  <h1 class="font-weight-bold mb-4">
                    Send and recieve payments easily
                  </h1>
                  <p>
                    A useful cloud accounting software with everything you need
                    to track your business and manage your transactions
                  </p>
                  <ul class="list-features__container">
                    <li class="list-feature">
                      <div class="row">
                        <div class="col-2">
                          <svg
                            width="100%"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="6.98643">
                              <path
                                d="M32.2402 22.2986C32.2402 16.1272 27.0928 11.1581 20.8572 11.4505C15.3682 11.7078 10.8408 16.1952 10.5367 21.6817C10.3159 25.6632 12.2469 29.201 15.2669 31.2626C15.6643 31.5339 15.8921 31.9923 15.8921 32.4735V33.2438H27.1495V32.2758C27.1495 31.8122 27.3579 31.3652 27.7336 31.0936C30.4608 29.1216 32.2402 25.9213 32.2402 22.2986Z"
                                fill="#FFCE00"
                              />
                            </g>
                            <path
                              d="M28.6341 36.5528H14.8889C13.9752 36.5528 13.2344 35.8121 13.2344 34.8983C13.2344 33.9845 13.9752 33.2439 14.8889 33.2439H28.6341C29.5478 33.2439 30.2886 33.9845 30.2886 34.8983C30.2886 35.8121 29.5478 36.5528 28.6341 36.5528Z"
                              fill="#BEB3B4"
                            />
                            <path
                              d="M28.634 36.0013H14.8889C14.0696 36.0013 13.3941 35.4043 13.2622 34.6226C13.247 34.7127 13.2344 34.8038 13.2344 34.8983C13.2344 35.812 13.9751 36.5528 14.8889 36.5528H28.634C29.5478 36.5528 30.2885 35.812 30.2885 34.8983C30.2885 34.8038 30.2759 34.7127 30.2607 34.6226C30.1288 35.4043 29.4533 36.0013 28.634 36.0013Z"
                              fill="#A49D9E"
                            />
                            <path
                              d="M27.5083 39.8619H16.0136C15.1696 39.8619 14.4854 39.1777 14.4854 38.3336V38.0811C14.4854 37.2371 15.1696 36.5529 16.0136 36.5529H27.5083C28.3523 36.5529 29.0366 37.2371 29.0366 38.0811V38.3336C29.0366 39.1777 28.3523 39.8619 27.5083 39.8619Z"
                              fill="#AAA4A6"
                            />
                            <path
                              d="M27.6248 39.3104H15.8971C15.1979 39.3104 14.6217 38.7133 14.5091 37.9315C14.4961 38.0217 14.4854 38.1128 14.4854 38.2074C14.4854 39.121 15.1174 39.8618 15.8971 39.8618H27.6248C28.4045 39.8618 29.0366 39.121 29.0366 38.2074C29.0366 38.1128 29.0257 38.0217 29.0128 37.9315C28.9002 38.7133 28.3239 39.3104 27.6248 39.3104Z"
                              fill="#848081"
                            />
                            <path
                              d="M21.761 43.8495C19.7675 43.8495 18.1514 42.2335 18.1514 40.2399V39.8618H25.3706V40.2399C25.3706 42.2335 23.7546 43.8495 21.761 43.8495Z"
                              fill="#848081"
                            />
                            <path
                              d="M25.3705 39.865V40.2401C25.3705 40.3699 25.3633 40.4997 25.3488 40.6295H18.1729C18.1585 40.4997 18.1514 40.3699 18.1514 40.2401V39.865H25.3705Z"
                              fill="#6B6A6B"
                            />
                            <path
                              d="M28.8606 22.1287C28.8606 26.2604 25.5112 29.6098 21.3795 29.6098C17.2478 29.6098 13.8984 26.2604 13.8984 22.1287C13.8984 17.997 17.2478 14.6476 21.3795 14.6476C25.5112 14.6476 28.8606 17.997 28.8606 22.1287Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M28.0626 22.1287C28.0626 25.8197 25.0704 28.8118 21.3794 28.8118C17.6884 28.8118 14.6963 25.8197 14.6963 22.1287C14.6963 18.4377 17.6884 15.4456 21.3794 15.4456C25.0704 15.4456 28.0626 18.4377 28.0626 22.1287Z"
                              fill="#E4A314"
                            />
                            <path
                              d="M27.614 22.1287C27.614 25.5718 24.8228 28.363 21.3798 28.363C17.9367 28.363 15.1455 25.5718 15.1455 22.1287C15.1455 18.6856 17.9367 15.8945 21.3798 15.8945C24.8228 15.8945 27.614 18.6856 27.614 22.1287Z"
                              fill="#F5B81E"
                            />
                            <path
                              d="M21.7607 5.1143C21.7607 5.04085 21.7607 5 21.7607 5"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M33.7041 10.4046C33.756 10.3526 33.7849 10.3237 33.7849 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M38.4082 22.5901C38.4817 22.5901 38.5225 22.5901 38.5225 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.81813 10.4046C9.76619 10.3526 9.7373 10.3237 9.7373 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M5.1143 22.5901C5.04085 22.5901 5 22.5901 5 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M24.2832 23.325C24.2832 24.3013 23.5086 24.866 22.0237 24.866H19.3974C19.2345 24.866 19.1025 24.734 19.1025 24.5711V19.5124C19.1025 19.3496 19.2345 19.2175 19.3974 19.2175H21.8626C23.2746 19.2175 24.0007 19.8066 24.0007 20.6863C24.0007 21.251 23.7103 21.6866 23.2503 21.9287C23.8797 22.1305 24.2832 22.6147 24.2832 23.325ZM20.4021 20.2021V21.5336H21.701C22.3384 21.5336 22.6853 21.3075 22.6853 20.8637C22.6853 20.4199 22.3384 20.2021 21.701 20.2021H20.4021ZM22.9678 23.1876C22.9678 22.7116 22.6044 22.4856 21.9271 22.4856H20.4021V23.8815H21.9271C22.6044 23.8815 22.9678 23.6718 22.9678 23.1876Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 17.4114C20.1328 17.4114 19.9668 17.5773 19.9668 17.7821V19.2175H20.7082V17.7821C20.7082 17.5773 20.5422 17.4114 20.3375 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 17.4114C21.5839 17.4114 21.418 17.5773 21.418 17.7821V19.2175H22.1592V17.7821C22.1592 17.5773 21.9933 17.4114 21.7886 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 26.5134C21.9933 26.5134 22.1592 26.3474 22.1592 26.1427V24.7073H21.418V26.1427C21.418 26.3474 21.5839 26.5134 21.7886 26.5134Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 26.5134C20.5422 26.5134 20.7082 26.3474 20.7082 26.1427V24.7073H19.9668V26.1427C19.9668 26.3474 20.1328 26.5134 20.3375 26.5134Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                        <div class="col-10">
                          <h3 class="font-weight-bold">Send payments</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eos molestias aliquid dignissimos a eius nulla
                            sunt asperiores.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="list-feature">
                      <div class="row">
                        <div class="col-2">
                          <svg
                            width="100%"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="6.98643">
                              <path
                                d="M32.2402 22.2986C32.2402 16.1272 27.0928 11.1581 20.8572 11.4505C15.3682 11.7078 10.8408 16.1952 10.5367 21.6817C10.3159 25.6632 12.2469 29.201 15.2669 31.2626C15.6643 31.5339 15.8921 31.9923 15.8921 32.4735V33.2438H27.1495V32.2758C27.1495 31.8122 27.3579 31.3652 27.7336 31.0936C30.4608 29.1216 32.2402 25.9213 32.2402 22.2986Z"
                                fill="#FFCE00"
                              />
                            </g>
                            <path
                              d="M28.6341 36.5528H14.8889C13.9752 36.5528 13.2344 35.8121 13.2344 34.8983C13.2344 33.9845 13.9752 33.2439 14.8889 33.2439H28.6341C29.5478 33.2439 30.2886 33.9845 30.2886 34.8983C30.2886 35.8121 29.5478 36.5528 28.6341 36.5528Z"
                              fill="#BEB3B4"
                            />
                            <path
                              d="M28.634 36.0013H14.8889C14.0696 36.0013 13.3941 35.4043 13.2622 34.6226C13.247 34.7127 13.2344 34.8038 13.2344 34.8983C13.2344 35.812 13.9751 36.5528 14.8889 36.5528H28.634C29.5478 36.5528 30.2885 35.812 30.2885 34.8983C30.2885 34.8038 30.2759 34.7127 30.2607 34.6226C30.1288 35.4043 29.4533 36.0013 28.634 36.0013Z"
                              fill="#A49D9E"
                            />
                            <path
                              d="M27.5083 39.8619H16.0136C15.1696 39.8619 14.4854 39.1777 14.4854 38.3336V38.0811C14.4854 37.2371 15.1696 36.5529 16.0136 36.5529H27.5083C28.3523 36.5529 29.0366 37.2371 29.0366 38.0811V38.3336C29.0366 39.1777 28.3523 39.8619 27.5083 39.8619Z"
                              fill="#AAA4A6"
                            />
                            <path
                              d="M27.6248 39.3104H15.8971C15.1979 39.3104 14.6217 38.7133 14.5091 37.9315C14.4961 38.0217 14.4854 38.1128 14.4854 38.2074C14.4854 39.121 15.1174 39.8618 15.8971 39.8618H27.6248C28.4045 39.8618 29.0366 39.121 29.0366 38.2074C29.0366 38.1128 29.0257 38.0217 29.0128 37.9315C28.9002 38.7133 28.3239 39.3104 27.6248 39.3104Z"
                              fill="#848081"
                            />
                            <path
                              d="M21.761 43.8495C19.7675 43.8495 18.1514 42.2335 18.1514 40.2399V39.8618H25.3706V40.2399C25.3706 42.2335 23.7546 43.8495 21.761 43.8495Z"
                              fill="#848081"
                            />
                            <path
                              d="M25.3705 39.865V40.2401C25.3705 40.3699 25.3633 40.4997 25.3488 40.6295H18.1729C18.1585 40.4997 18.1514 40.3699 18.1514 40.2401V39.865H25.3705Z"
                              fill="#6B6A6B"
                            />
                            <path
                              d="M28.8606 22.1287C28.8606 26.2604 25.5112 29.6098 21.3795 29.6098C17.2478 29.6098 13.8984 26.2604 13.8984 22.1287C13.8984 17.997 17.2478 14.6476 21.3795 14.6476C25.5112 14.6476 28.8606 17.997 28.8606 22.1287Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M28.0626 22.1287C28.0626 25.8197 25.0704 28.8118 21.3794 28.8118C17.6884 28.8118 14.6963 25.8197 14.6963 22.1287C14.6963 18.4377 17.6884 15.4456 21.3794 15.4456C25.0704 15.4456 28.0626 18.4377 28.0626 22.1287Z"
                              fill="#E4A314"
                            />
                            <path
                              d="M27.614 22.1287C27.614 25.5718 24.8228 28.363 21.3798 28.363C17.9367 28.363 15.1455 25.5718 15.1455 22.1287C15.1455 18.6856 17.9367 15.8945 21.3798 15.8945C24.8228 15.8945 27.614 18.6856 27.614 22.1287Z"
                              fill="#F5B81E"
                            />
                            <path
                              d="M21.7607 5.1143C21.7607 5.04085 21.7607 5 21.7607 5"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M33.7041 10.4046C33.756 10.3526 33.7849 10.3237 33.7849 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M38.4082 22.5901C38.4817 22.5901 38.5225 22.5901 38.5225 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.81813 10.4046C9.76619 10.3526 9.7373 10.3237 9.7373 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M5.1143 22.5901C5.04085 22.5901 5 22.5901 5 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M24.2832 23.325C24.2832 24.3013 23.5086 24.866 22.0237 24.866H19.3974C19.2345 24.866 19.1025 24.734 19.1025 24.5711V19.5124C19.1025 19.3496 19.2345 19.2175 19.3974 19.2175H21.8626C23.2746 19.2175 24.0007 19.8066 24.0007 20.6863C24.0007 21.251 23.7103 21.6866 23.2503 21.9287C23.8797 22.1305 24.2832 22.6147 24.2832 23.325ZM20.4021 20.2021V21.5336H21.701C22.3384 21.5336 22.6853 21.3075 22.6853 20.8637C22.6853 20.4199 22.3384 20.2021 21.701 20.2021H20.4021ZM22.9678 23.1876C22.9678 22.7116 22.6044 22.4856 21.9271 22.4856H20.4021V23.8815H21.9271C22.6044 23.8815 22.9678 23.6718 22.9678 23.1876Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 17.4114C20.1328 17.4114 19.9668 17.5773 19.9668 17.7821V19.2175H20.7082V17.7821C20.7082 17.5773 20.5422 17.4114 20.3375 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 17.4114C21.5839 17.4114 21.418 17.5773 21.418 17.7821V19.2175H22.1592V17.7821C22.1592 17.5773 21.9933 17.4114 21.7886 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 26.5134C21.9933 26.5134 22.1592 26.3474 22.1592 26.1427V24.7073H21.418V26.1427C21.418 26.3474 21.5839 26.5134 21.7886 26.5134Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 26.5134C20.5422 26.5134 20.7082 26.3474 20.7082 26.1427V24.7073H19.9668V26.1427C19.9668 26.3474 20.1328 26.5134 20.3375 26.5134Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                        <div class="col-10">
                          <h3 class="font-weight-bold">Send payments</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eos molestias aliquid dignissimos a eius nulla
                            sunt asperiores.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="list-feature">
                      <div class="row">
                        <div class="col-2">
                          <svg
                            width="100%"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="6.98643">
                              <path
                                d="M32.2402 22.2986C32.2402 16.1272 27.0928 11.1581 20.8572 11.4505C15.3682 11.7078 10.8408 16.1952 10.5367 21.6817C10.3159 25.6632 12.2469 29.201 15.2669 31.2626C15.6643 31.5339 15.8921 31.9923 15.8921 32.4735V33.2438H27.1495V32.2758C27.1495 31.8122 27.3579 31.3652 27.7336 31.0936C30.4608 29.1216 32.2402 25.9213 32.2402 22.2986Z"
                                fill="#FFCE00"
                              />
                            </g>
                            <path
                              d="M28.6341 36.5528H14.8889C13.9752 36.5528 13.2344 35.8121 13.2344 34.8983C13.2344 33.9845 13.9752 33.2439 14.8889 33.2439H28.6341C29.5478 33.2439 30.2886 33.9845 30.2886 34.8983C30.2886 35.8121 29.5478 36.5528 28.6341 36.5528Z"
                              fill="#BEB3B4"
                            />
                            <path
                              d="M28.634 36.0013H14.8889C14.0696 36.0013 13.3941 35.4043 13.2622 34.6226C13.247 34.7127 13.2344 34.8038 13.2344 34.8983C13.2344 35.812 13.9751 36.5528 14.8889 36.5528H28.634C29.5478 36.5528 30.2885 35.812 30.2885 34.8983C30.2885 34.8038 30.2759 34.7127 30.2607 34.6226C30.1288 35.4043 29.4533 36.0013 28.634 36.0013Z"
                              fill="#A49D9E"
                            />
                            <path
                              d="M27.5083 39.8619H16.0136C15.1696 39.8619 14.4854 39.1777 14.4854 38.3336V38.0811C14.4854 37.2371 15.1696 36.5529 16.0136 36.5529H27.5083C28.3523 36.5529 29.0366 37.2371 29.0366 38.0811V38.3336C29.0366 39.1777 28.3523 39.8619 27.5083 39.8619Z"
                              fill="#AAA4A6"
                            />
                            <path
                              d="M27.6248 39.3104H15.8971C15.1979 39.3104 14.6217 38.7133 14.5091 37.9315C14.4961 38.0217 14.4854 38.1128 14.4854 38.2074C14.4854 39.121 15.1174 39.8618 15.8971 39.8618H27.6248C28.4045 39.8618 29.0366 39.121 29.0366 38.2074C29.0366 38.1128 29.0257 38.0217 29.0128 37.9315C28.9002 38.7133 28.3239 39.3104 27.6248 39.3104Z"
                              fill="#848081"
                            />
                            <path
                              d="M21.761 43.8495C19.7675 43.8495 18.1514 42.2335 18.1514 40.2399V39.8618H25.3706V40.2399C25.3706 42.2335 23.7546 43.8495 21.761 43.8495Z"
                              fill="#848081"
                            />
                            <path
                              d="M25.3705 39.865V40.2401C25.3705 40.3699 25.3633 40.4997 25.3488 40.6295H18.1729C18.1585 40.4997 18.1514 40.3699 18.1514 40.2401V39.865H25.3705Z"
                              fill="#6B6A6B"
                            />
                            <path
                              d="M28.8606 22.1287C28.8606 26.2604 25.5112 29.6098 21.3795 29.6098C17.2478 29.6098 13.8984 26.2604 13.8984 22.1287C13.8984 17.997 17.2478 14.6476 21.3795 14.6476C25.5112 14.6476 28.8606 17.997 28.8606 22.1287Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M28.0626 22.1287C28.0626 25.8197 25.0704 28.8118 21.3794 28.8118C17.6884 28.8118 14.6963 25.8197 14.6963 22.1287C14.6963 18.4377 17.6884 15.4456 21.3794 15.4456C25.0704 15.4456 28.0626 18.4377 28.0626 22.1287Z"
                              fill="#E4A314"
                            />
                            <path
                              d="M27.614 22.1287C27.614 25.5718 24.8228 28.363 21.3798 28.363C17.9367 28.363 15.1455 25.5718 15.1455 22.1287C15.1455 18.6856 17.9367 15.8945 21.3798 15.8945C24.8228 15.8945 27.614 18.6856 27.614 22.1287Z"
                              fill="#F5B81E"
                            />
                            <path
                              d="M21.7607 5.1143C21.7607 5.04085 21.7607 5 21.7607 5"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M33.7041 10.4046C33.756 10.3526 33.7849 10.3237 33.7849 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M38.4082 22.5901C38.4817 22.5901 38.5225 22.5901 38.5225 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M9.81813 10.4046C9.76619 10.3526 9.7373 10.3237 9.7373 10.3237"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M5.1143 22.5901C5.04085 22.5901 5 22.5901 5 22.5901"
                              stroke="#FFD31D"
                              stroke-width="10"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                            <path
                              d="M24.2832 23.325C24.2832 24.3013 23.5086 24.866 22.0237 24.866H19.3974C19.2345 24.866 19.1025 24.734 19.1025 24.5711V19.5124C19.1025 19.3496 19.2345 19.2175 19.3974 19.2175H21.8626C23.2746 19.2175 24.0007 19.8066 24.0007 20.6863C24.0007 21.251 23.7103 21.6866 23.2503 21.9287C23.8797 22.1305 24.2832 22.6147 24.2832 23.325ZM20.4021 20.2021V21.5336H21.701C22.3384 21.5336 22.6853 21.3075 22.6853 20.8637C22.6853 20.4199 22.3384 20.2021 21.701 20.2021H20.4021ZM22.9678 23.1876C22.9678 22.7116 22.6044 22.4856 21.9271 22.4856H20.4021V23.8815H21.9271C22.6044 23.8815 22.9678 23.6718 22.9678 23.1876Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 17.4114C20.1328 17.4114 19.9668 17.5773 19.9668 17.7821V19.2175H20.7082V17.7821C20.7082 17.5773 20.5422 17.4114 20.3375 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 17.4114C21.5839 17.4114 21.418 17.5773 21.418 17.7821V19.2175H22.1592V17.7821C22.1592 17.5773 21.9933 17.4114 21.7886 17.4114Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M21.7886 26.5134C21.9933 26.5134 22.1592 26.3474 22.1592 26.1427V24.7073H21.418V26.1427C21.418 26.3474 21.5839 26.5134 21.7886 26.5134Z"
                              fill="#1E1E1E"
                            />
                            <path
                              d="M20.3375 26.5134C20.5422 26.5134 20.7082 26.3474 20.7082 26.1427V24.7073H19.9668V26.1427C19.9668 26.3474 20.1328 26.5134 20.3375 26.5134Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                        <div class="col-10">
                          <h3 class="font-weight-bold">Send payments</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Eos molestias aliquid dignissimos a eius nulla
                            sunt.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="">
                  <img
                    src="./assets/Macbook.png"
                    alt=""
                    class="dashboard-img img-mg"
                    height="100%"
                    width="100%"
                  />

                  <img
                    src="./assets/Ellipse 39.png"
                    class="big-orange-ellipse__right d-none d-lg-flex"
                    alt=""
                    width="60%"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="second-to-last-section">
        <div class="text-center">
          <h2 class="font-weight-bold">The plan that's right for you</h2>
          <p class="mt-3">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate
            laborum quasi nobis, <br />
            alias enim quam nemo accusamus autem! Itaque sapiente sint odit
            aliquid amet architecto aliquam numquam. Illum, deserunt officiis.
          </p>
        </div>
      </div>

      <div class="secondary-color_background p-2 mt-5">
        <div class="start-wrapper container">
          <div class="start start-animation d-lg-flex justify-content-between">
            <p class="font-weight-bold h1 text-white text-capitalize">
              Get started for <span class="accent-color">free</span> today
            </p>

            <button
              class="btn btn-green-white_border font-weight-bold"
              type="submit"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
      <div class="container pt-4">
        <div class="row mx-auto">
          <div class="col-lg-4 mx-auto mb-2 mb-lg-0 d-flex align-items-center">
            <span class="mr-2">
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5 0C9.17819 0 0 9.17819 0 20.5C0 31.8218 9.17819 41 20.5 41C31.8218 41 41 31.8218 41 20.5C41 9.17819 31.8218 0 20.5 0ZM20.5 4.45434C29.3618 4.45434 36.5432 11.6404 36.5432 20.5C36.5432 29.3596 29.3618 36.5432 20.5 36.5432C11.6382 36.5432 4.45684 29.3596 4.45684 20.5C4.45687 11.6404 11.6382 4.45434 20.5 4.45434ZM28.3552 10.7655L16.4736 22.6496L12.6198 18.7958L8.83861 22.5745L12.6924 26.4283L16.4986 30.2345L20.2773 26.4533L32.1614 14.5717L28.3552 10.7655Z"
                  fill="#047B40"
                />
              </svg>
            </span>
            <div class="">Customer Support</div>
          </div>
          <div class="col-lg-4 mx-auto mb-2 mb-lg-0 row d-flex align-items-center">
            <span class="mr-2">
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 20.5C0 9.17819 9.17819 0 20.5 0C31.8218 0 41 9.17819 41 20.5C41 31.8218 31.8218 41 20.5 41C9.17819 41 0 31.8218 0 20.5ZM5.51913 14.7467C7.14548 10.5137 10.5139 7.14391 14.7469 5.51693L17.015 10.906C15.6155 11.416 14.344 12.2249 13.2889 13.2764C12.2339 14.3279 11.4207 15.5967 10.906 16.9945L5.51913 14.7467ZM14.7301 35.4744C10.5054 33.8456 7.14397 30.4806 5.51959 26.2542L10.906 24.026C11.4171 25.4201 12.2269 26.6856 13.2787 27.7338C14.3304 28.782 15.5986 29.5876 16.9945 30.094L14.7301 35.4744ZM35.4744 26.2699C33.8479 30.4883 30.4904 33.8466 26.2719 35.4737L24.0465 30.094C25.4361 29.5791 26.697 28.7678 27.7413 27.7163C28.7857 26.6649 29.5885 25.3986 30.094 24.0055L35.4744 26.2699ZM26.28 5.5273C30.4941 7.15581 33.8481 10.5127 35.4737 14.7281L30.094 16.9535C29.5805 15.572 28.7742 14.3181 27.7303 13.2777C26.6864 12.2374 25.4297 11.4353 24.0465 10.9265L26.28 5.5273ZM14.35 20.5C14.35 23.903 17.097 26.65 20.5 26.65C23.903 26.65 26.65 23.903 26.65 20.5C26.65 17.097 23.903 14.35 20.5 14.35C17.097 14.35 14.35 17.097 14.35 20.5Z"
                  fill="#047B40"
                />
              </svg>
            </span>
            <div class="">Support teams across the world</div>
          </div>
          <div class="col-lg-4 row mx-auto mb-2 mb-lg-0 d-flex align-items-center">
            <span class="mr-2">
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 20.5C0 9.17819 9.17819 0 20.5 0C31.8218 0 41 9.17819 41 20.5C41 31.8218 31.8218 41 20.5 41C9.17819 41 0 31.8218 0 20.5ZM36.5432 20.5C36.5432 11.6404 29.3618 4.45434 20.5 4.45434C11.6382 4.45434 4.45687 11.6404 4.45684 20.5C4.45684 29.3596 11.6382 36.5432 20.5 36.5432C29.3618 36.5432 36.5432 29.3596 36.5432 20.5ZM26.4314 14.6234V17.8214C27.7024 17.8214 28.9734 19.1006 28.9734 20.593V28.055C28.9734 29.3342 27.7024 30.6134 26.2196 30.6134H14.5687C13.2977 30.6134 12.0267 29.3342 12.0267 27.8418V20.3798C12.0267 19.1006 13.2977 17.8214 14.5687 17.8214V14.6234C14.5687 11.6386 17.5344 9.29335 20.5001 9.29335C23.4657 9.29335 26.4314 11.6386 26.4314 14.6234ZM17.3226 17.8214H23.6776V14.6234C23.6776 12.9178 22.1947 11.8518 20.5001 11.8518C18.8054 11.8518 17.3226 12.9178 17.3226 14.6234V17.8214Z"
                  fill="#047B40"
                />
              </svg>
            </span>
            <div class="">Safe & Secure online payment</div>
          </div>
        </div>

        <div class="row mt-5 container bottom-menu">
          <div class="col-lg-4 mt-3">
            <p>MENU</p>
            <div>Home</div>
            <div>About Us</div>
            <div>Services</div>
            <div>Products</div>
            <div>Career</div>
            <div>Contacts</div>
          </div>
          <div class="col-lg-4 mt-3">
            <p>ADDRESS</p>
            <div>Plot 46, Adewale Ishawu Str</div>
            <div>Off Modupe Johnson Crescent</div>
            <div>Surulere, Lagos</div>
            <div>0907-842-2233</div>
            <div>0811-582-8444</div>
            <div>business@onebmac.com</div>
          </div>
          <div class="col-lg-4 mt-3">
            <p>COLLABORATIVE EDGE</p>
            <div>
              Sign up for Collaborative Edge - our newsletter for <br />
              entrepreneurs, professionals and business influencers
            </div>
          </div>
        </div>

        <div class="container mt-5 mb-2">
          <div class="row mx-2">
            <div class="col-lg-2 d-flex justify-content-between">
              <span class="">
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 25 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.6183 2.37575C23.713 2.76628 22.7278 3.04735 21.713 3.15682C22.7666 2.53089 23.5556 1.54202 23.9319 0.375752C22.9433 0.963885 21.8602 1.37629 20.7308 1.59469C20.2587 1.09003 19.6878 0.687999 19.0535 0.413643C18.4193 0.139287 17.7354 -0.00151415 17.0444 1.22793e-05C14.2485 1.22793e-05 12 2.26628 12 5.04735C12 5.43788 12.0473 5.82841 12.1243 6.20415C7.93786 5.98522 4.20414 3.98522 1.72189 0.923089C1.2696 1.69562 1.03258 2.57524 1.0355 3.47042C1.0355 5.2219 1.92603 6.76628 3.28402 7.67456C2.48374 7.64305 1.7022 7.42308 1.00296 7.03255V7.09468C1.00296 9.54734 2.73668 11.5799 5.04733 12.0473C4.61348 12.16 4.16718 12.2177 3.71893 12.2189C3.39053 12.2189 3.07988 12.1864 2.76627 12.142C3.40532 14.142 5.26627 15.5947 7.48224 15.642C5.74852 17 3.57692 17.7988 1.21893 17.7988C0.795858 17.7988 0.405325 17.784 0 17.7367C2.23668 19.1716 4.89053 20 7.74852 20C17.0266 20 22.1035 12.3136 22.1035 5.64202C22.1035 5.42309 22.1035 5.20415 22.0887 4.98522C23.071 4.26628 23.9319 3.37575 24.6183 2.37575Z"
                    fill="#047B40"
                  />
                </svg>
              </span>
              <span class="">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6172 0C5.09516 0 0.618164 4.477 0.618164 9.999C0.618164 14.989 4.27416 19.125 9.05516 19.878V12.89H6.51516V9.999H9.05516V7.796C9.05516 5.288 10.5482 3.905 12.8312 3.905C13.9252 3.905 15.0712 4.1 15.0712 4.1V6.559H13.8072C12.5672 6.559 12.1792 7.331 12.1792 8.122V9.997H14.9502L14.5072 12.888H12.1792V19.876C16.9602 19.127 20.6162 14.99 20.6162 9.999C20.6162 4.477 16.1392 0 10.6172 0Z"
                    fill="#047B40"
                  />
                </svg>
              </span>
              <span class="">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7319 6.66525C8.89563 6.66525 7.39712 8.16376 7.39712 10C7.39712 11.8362 8.89563 13.3348 10.7319 13.3348C12.5681 13.3348 14.0666 11.8362 14.0666 10C14.0666 8.16376 12.5681 6.66525 10.7319 6.66525ZM20.7336 10C20.7336 8.61907 20.7461 7.25064 20.6686 5.87221C20.591 4.27113 20.2258 2.85017 19.055 1.67938C17.8817 0.506085 16.4632 0.14334 14.8622 0.065788C13.4812 -0.0117644 12.1128 0.000744113 10.7344 0.000744113C9.35344 0.000744113 7.98502 -0.0117644 6.60659 0.065788C5.0055 0.14334 3.58454 0.508587 2.41375 1.67938C1.24046 2.85267 0.877715 4.27113 0.800163 5.87221C0.722611 7.25314 0.735119 8.62157 0.735119 10C0.735119 11.3784 0.722611 12.7494 0.800163 14.1278C0.877715 15.7289 1.24296 17.1498 2.41375 18.3206C3.58705 19.4939 5.0055 19.8567 6.60659 19.9342C7.98752 20.0118 9.35594 19.9993 10.7344 19.9993C12.1153 19.9993 13.4837 20.0118 14.8622 19.9342C16.4632 19.8567 17.8842 19.4914 19.055 18.3206C20.2283 17.1473 20.591 15.7289 20.6686 14.1278C20.7486 12.7494 20.7336 11.3809 20.7336 10ZM10.7319 15.131C7.89245 15.131 5.60091 12.8394 5.60091 10C5.60091 7.16058 7.89245 4.86903 10.7319 4.86903C13.5713 4.86903 15.8628 7.16058 15.8628 10C15.8628 12.8394 13.5713 15.131 10.7319 15.131ZM16.073 5.8572C15.41 5.8572 14.8747 5.32184 14.8747 4.65889C14.8747 3.99594 15.41 3.46058 16.073 3.46058C16.7359 3.46058 17.2713 3.99594 17.2713 4.65889C17.2715 4.81631 17.2406 4.97222 17.1805 5.1177C17.1203 5.26317 17.0321 5.39535 16.9208 5.50666C16.8094 5.61798 16.6773 5.70624 16.5318 5.76639C16.3863 5.82654 16.2304 5.8574 16.073 5.8572Z"
                    fill="#047B40"
                  />
                </svg>
              </span>
            </div>
            <div class="col-lg-10 mt-3 mt-lg-0 bottom-wrapper">
              COPYRIGHT IKOOBA TECHNOLOGIES 2020 -
              <span class="bottom-terms">
                TERMS & CONDITIONS PRIVACY POLICY
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
