import { DELETE_OTHER_DEDUCTION, GET_ALL_OTHER_DEDUCTION } from "../../actions/types"

const INITIAL_STATE = {
    deductions: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_OTHER_DEDUCTION:
            return {
                ...state,
                taxes: action.payload
            }
        case DELETE_OTHER_DEDUCTION:
            return {
                ...state,
                taxes: state.taxes.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}