import {
  GET_PERFORMANCE_REPORT,
  GET_PERFORMANCE_REPORT_DETAILS,
  GET_EMPLOYMENT_REPORT,
  GET_EMPLOYMENT_REPORT_STARTS,
  GET_EMPLOYMENT_REPORT_ENDS,
  GET_PERFORMANCE_REPORT_LOADING_ENDS,
  GET_PERFORMANCE_REPORT_LOADING_STARTS,
} from "../../actions/types";

const initialState = {
  performanceReport: {},
  performanceDetailsReport: {},
  employmentReport: {},
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PERFORMANCE_REPORT:
      return {
        ...state,
        performanceReport: action.payload,
      };
    case GET_PERFORMANCE_REPORT_DETAILS:
      return {
        ...state,
        performanceDetailsReport: action.payload,
      };
    case GET_EMPLOYMENT_REPORT:
      return {
        ...state,
        employmentReport: action.payload,
      };
    case GET_EMPLOYMENT_REPORT_STARTS:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYMENT_REPORT_ENDS:
      return {
        ...state,
        loading: false,
      };
    case GET_PERFORMANCE_REPORT_LOADING_STARTS:
      return {
        ...state,
        loading: true,
      };
    case GET_PERFORMANCE_REPORT_LOADING_ENDS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
