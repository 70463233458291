import { trainingTypes } from "../../actions/types";

let initialState = {
  trainings: [],
};

export const getTrainingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case trainingTypes.GET_TRAINING_REQUEST_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.payload,
      };

    case trainingTypes.GET_TRAINING_REQUEST_TRAININGS_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
