import { DELETE_LEAVE_CATEGORY, GET_ALL_LEAVE_CATEGORY } from "../../actions/types"

const INITIAL_STATE = {
    leaveCategories: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LEAVE_CATEGORY:
            return {
                ...state,
                leaveCategories: action.payload
            }
        case DELETE_LEAVE_CATEGORY:
            return {
                ...state,
                leaveCategories: state.leaveCategories.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}