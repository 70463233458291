import {
  GET_ALL_PROCESS_AUTH,
  VIEW_PROCESS_AUTH,
  EDIT_PROCESS_AUTH,
  DELETE_PROCESS_AUTH,
  CREATE_PROCESS_AUTH,
} from "../../actions/types";

const iniState = {
  processAuths: [],
  singleProcessAuth: {},
};

export default (state = iniState, action) => {
  switch (action.type) {
    case GET_ALL_PROCESS_AUTH:
      console.log(action.payload, "action payload");
      return {
        ...state,
        processAuths: action.payload,
      };
    case VIEW_PROCESS_AUTH:
      return {
        ...state,
        processAuths: action.payload,
      };
    case DELETE_PROCESS_AUTH:
      console.log(state.approvalTypes, "sup", action.payload);
      return {
        ...state,
        processAuths: state.approvalTypes.filter(
          (item) => item.id !== action.payload
        ),
      };
    case EDIT_PROCESS_AUTH:
      return {
        ...state,
        processAuths: action.payload,
      };
    default:
      return state;
  }
};
