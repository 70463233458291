import React,{useState} from "react";
import Logo from "../assets/images/logo.png";
    

const TermsAndCondition = () => {

  return (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-5 col-lg-4 side-img d-none d-sm-flex">
                <div className="side-img__text">
                    <img src={Logo} alt="" />
                    <p className="h1 font-weight-bold text-white w-75 text-wrap">
                        Collaborative Software for Business
                    </p>
                </div>
            </div>
            <div className="col-lg-7 col-sm-7 col-xs-12">
                <div className="mt-3">
                    <h3 align="center"> Terms and Condition</h3>
                </div>

            </div>
        </div>
    </div>
  );
};

export default TermsAndCondition;
