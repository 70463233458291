import React from "react";
import { Link, useLocation } from "react-router-dom";
// import CONSTANTS from "../../common/constants.json";

const NavItem = ({ Svg, name, path, handleClick }) => {
  const location = useLocation()
  return (
    <li className="nav-item" onClick={(id)=> handleClick(id)} >
      <Link
        to={path}
        className={`nav-link ${location.pathname === path ? "active" : null}`}
      >
        <Svg/>
        <span className="link-title">{name}</span>
      </Link>
    </li>
  );
};

export default NavItem;
