import { DELETE_DEPARTMENT, GET_ALL_DEPARTMENT, DEPARTMENT_LOADING_STARTS, DEPARTMENT_LOADING_ENDS } from "../../actions/types"

const INITIAL_STATE = {
    departments: undefined,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_DEPARTMENT:
            return {
                ...state,
                departments: action.payload
            }
        case DELETE_DEPARTMENT:
            return {
                ...state,
                departments: state.departments.filter(item => item.id !== action.payload)
            }
        case DEPARTMENT_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case DEPARTMENT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}