import React, {useEffect,Suspense} from "react";
import { Switch } from "react-router-dom";
import { ErrorHandler } from "../errorHandlers/ErrorHandler";
import PrivateRoute from "./PrivateRoute";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../actions/authActions"
import store from "../store";
import setAuthToken from "../utils/setAuthToken";
import CONSTANTS from "../common/constants.json";
import { history } from "../utils/history";

const ProtectedRoutes = ({routes}) => { 
  
  
  useEffect(() => {
    console.log(routes, 'ROUTESSSS');
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));
    
      // Check for expired token
      const currentTime = Date.now() / 1000;
      console.log(currentTime,decoded.exp ,"currentTime")
      if (decoded.exp < currentTime) {
        // Logout user
        localStorage.removeItem("jwtToken");
        localStorage.clear()        
        window.location.href = CONSTANTS.ROUTES.LOGIN;
      }
    }
  }, []); 


    return ( 
  <Suspense fallback =''>
  <ErrorHandler>
  <Switch>
        {routes?.map(({ component: Component, path, ...others}) => (
          <PrivateRoute
            path={path}
            key={path}
 
            {...others}
            
          >  
         <Component />
       
          </PrivateRoute>
        )
        )}
        </Switch>
        </ErrorHandler>
    </Suspense>
    
  
)}
  
  export default ProtectedRoutes;