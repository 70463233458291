import React, { useState, useEffect} from "react";
import  { useDispatch, connect} from "react-redux"
import isEmpty from "../../../utils/isEmpty";
import { addJobApplicatonComment, getJobApplicationComments } from "../../../actions/Module-Recruitment/jobApplicationActions";
import { CLEAR_ERRORS } from "../../../actions/types";
import jwt_decode from "jwt-decode";
import { SpinnerGrow } from "../../Reusables/loader/Loader";


const Comment =(props)=> {
    const dispatch = useDispatch()
    const { jobApplicationID, errors, isLoading, comments } = props
    const { sub } = jwt_decode(localStorage.jwtToken);
    const [state, setState] = useState({
        job_application_id: "",
        commenter: null,
        comment: '',
      })

    useEffect(()=> {
        dispatch(getJobApplicationComments(jobApplicationID))
        return () => dispatch({type:CLEAR_ERRORS})
    }, [])


  const handleSubmit = (e) => {
    e.preventDefault();
    const comments = {
      job_application_id: jobApplicationID,
      commenter: sub,
      comment: state?.comment,
    };
    dispatch(addJobApplicatonComment(comments, jobApplicationID))
    if(props.dataLoaded) {
      setState({comment: '', job_application_id: '', commenter: null})
    }

  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  console.log("dataLoaded", props.dataLoaded);
    return (
      <form onSubmit={handleSubmit}>
        <div className="applicant-comment">
          <div className="comments">
            {!comments ? (<p>Loading</p>) : comments.length ? comments.map(comment => (
            <div key={comment.id} className="comment grr">
              <div className="thumb">
                <img
                  src="../../assets/icons/user.png"
                  className="user-image"
                  alt="profile"
                />
              </div>
              <div className="detls">
                <div className="fst">
                    <span className="nme">
                        {comment.commenter.first_name} {comment.commenter.surname}
                    </span>
                  <span className="dte">{comment.created_at}</span>
                </div>
                <div className="cmt">
                {comment.comment}
                  </div>
              </div>
            </div>
          )) : (<p>No comments</p>)}
          </div>
          <div className="comment-box">
            <div className="grr">
              <div className="thumb">
                <img
                  src="../../assets/icons/user.png"
                  className="user-image"
                  alt="profile"
                />
              </div>
              <textarea
                className={`form-control ${errors?.data?.errors?.comment ? 'is-invalid' : ''}`}
                name="comment"
                onChange={handleChange}
              >{state.comment}</textarea>
              {isEmpty(errors)
                ? null
                : (
                  <div className="invalid-feedback" style={{
                    gridColumn: '2/3',
                  }}>
                    {errors.data.errors.comment}
                  </div>
                )}
            </div>
            <div className="text-right">
              <button disabled={isLoading} ype="submit" className="button primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-send"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="10" y1="14" x2="21" y2="3" />
                  <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                </svg>
                  { isLoading ? <SpinnerGrow/> : "Comment"}
                </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

const mapStateToProps = state => {
    return {
      errors: state.errors,
      isLoading: state.jobApplicationComments.loading,
      networkResponse: state.networkResponse,
      comments: state.jobApplicationComments.comments,
      dataLoaded: state.jobApplicationComments.dataLoaded
    };
};

export default connect(mapStateToProps)(Comment);