import React, { Component } from "react";
import Notif from "./Notification";
import Message from "./Message";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CONSTANTS from "../common/constants.json";
import { logoutUser } from "../actions/authActions";
import UserIcon from "../assets/icons/user.png"
import { HeaderLazyLoads } from "./Reusables/LazyLoads";
import { $ } from "react-jquery-plugin"
import ChangePasswordSVG from "./Reusables/ChangePasswordSVG";
import axios from "axios"
import jwt_decode from "jwt-decode";
import process from "../env"

class Navbar extends Component {
  service_url = process.env.SERVICE_URL
  decoded = jwt_decode(localStorage.jwtToken);


  toggleSideBar = () => {
    if ($("body").hasClass("sidebar-open")) {
      $("body").removeClass("sidebar-open");
      $(".mobile").removeClass('is-active')
    } else if (!$("body").hasClass("sidebar-open")) {
      $("body").addClass("sidebar-open");
      $(".mobile").addClass('is-active')
    }
  }
  // getEmployeeProfile = () => {
  //   axios.get(`${this.service_url}get-employee-profile/${this.decoded.sub}`)
  //     .then(response => {
  //       setProfile(response.data.returnData);
  //       console.log(response.data.returnData, 'profile')
  //     }).catch(error => {
  //       dispatch(getErrorStatusCode(error.response))
  //     })
  // }
  userProfile = () => {
    console.log(this.props, 'emplo')
    var user_profile = this.props?.logged_in_username;
    if (user_profile) {
      user_profile = user_profile.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')
    }
    return user_profile
  }


  render() {
    const { isAuthenticated } = this.props?.auth ?? false;
    const _LazyHeader = <HeaderLazyLoads />;
    return isAuthenticated ? (
      <nav className="navbar">
        <div onClick={this.toggleSideBar} className="sidebar-toggler mobile">
          <div className="sidebar-toggler-box">
            <div className="sidebar-toggler-icon"></div>
          </div>
        </div>

        <div className="navbar-content">
          {/* {_LazyHeader} */}
          {
            // this.props.company_name === undefined ? _LazyHeader : <ul className="navbar-nav">
            // <li className="nav-item nav-company">
            //   <div>{this.props.company_name}</div>
            // </li>
            this.props.logged_in_username === undefined ? _LazyHeader : <ul className="navbar-nav">
              <li className="nav-item nav-company">
                <div>{this.props.logged_in_username}</div>
              </li>
              {/* <Message />
            <Notif /> */}
              <li className="nav-item dropdown nav-profile">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="profileDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {/* <img
                  src={UserIcon}
                  className="user-image"
                  alt="profile"
                /> */}
                  {this.props.employee.employee_picture_filename ? (
                    <div >
                      <img src={process.env.BASE_URL + this.props.employee.employee_picture_filename} alt={this.userProfile()} style={{ width: '35px', height: '35px',borderRadius:'17px'}} />
                    </div>
                  ) : (
                    <div className="navCircle">{this.userProfile()}</div>
                  )}
                </Link>
                <div className="dropdown-menu" aria-labelledby="profileDropdown">
                  <div className="top">
                    <Link to="" className="dropdown-item">
                      <div className="content">
                        <div className="figure">
                          {/* <img src={UserIcon} alt="" /> */}
                          {/* {profile.employee_picture_filename ? (
                          <div >
                            <img src={process.env.BASE_URL + profile.employee_picture_filename} alt={profile.full_name} className="rounded-circle" width="130" height="130" />
                          </div>
                        ) : (
                          <div className="navCircle" style={{width: '60px',lineHeight: '60px',fontSize: '28px'}}>{this.userProfile()}</div>

                        )} */}

                          {this.props.employee.employee_picture_filename ? (
                            <div >
                              <img src={process.env.BASE_URL + this.props.employee.employee_picture_filename} alt={this.userProfile()} style={{ width: '60px', height: '60px', borderRadius:'30px'}} />
                            </div>
                          ) : (
                            <div className="navCircle" style={{ width: '60px', lineHeight: '60px', fontSize: '28px' }}>{this.userProfile()}</div>

                          )}
                        </div>
                        <div className="info">
                          <p className="">{this.props.logged_in_username}</p>
                          <p className="text-muted">{this.props.company_name}</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <hr />

                  <Link to="/settings" className="dropdown-item">
                    <div className="content">
                      <div className="thumb">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-photo"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          strokeWidth="1"
                          stroke="#2e343b"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <line x1="15" y1="8" x2="15.01" y2="8" />
                          <rect x="4" y="4" width="16" height="16" rx="3" />
                          <path d="M4 15l4 -4a3 5 0 0 1 3 0l 5 5" />
                          <path d="M14 14l1 -1a3 5 0 0 1 3 0l 2 2" />
                        </svg>
                      </div>
                      <p>My Profile</p>
                    </div>
                  </Link>
                  {this.props.auth.isAdmin !== 'Employee' ? (
                    <Link
                    to="/settings/company-view"
                    className="dropdown-item"
                  >
                    <div className="content">
                      <div className="thumb">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-settings"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          strokeWidth="1"
                          stroke="#2e343b"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <circle cx="12" cy="12" r="3" />
                        </svg>{" "}
                      </div>
                      <p>Company Settings</p>
                    </div>
                  </Link>
                  ) :''}
                  

                  {/* <Link to="" className="dropdown-item">
                  <div className="content">
                    <div className="thumb">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-help"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1"
                        stroke="#2e343b"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="12" cy="12" r="9" />
                        <line x1="12" y1="17" x2="12" y2="17.01" />
                        <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                      </svg>
                    </div>
                    <p>Help and Support</p>
                  </div>
                </Link> */}

                  <hr />

                  <button className="dropdown-item"

                    data-toggle="modal"
                    data-target="#passwordmodal"
                  >
                    <div className="content">
                      <div className="thumb">
                        <ChangePasswordSVG />

                      </div>
                      <p>Change Password</p>
                    </div>
                  </button>


                  <hr />
                  <Link to="" onClick={this.props.logOutUser} className="dropdown-item"
                    data-toggle="modal"
                    data-target="#logoutmodal"
                  >
                    <div className="content">
                      <div className="thumb">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-logout"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          strokeWidth="1"
                          stroke="#2e343b"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                          <path d="M7 12h14l-3 -3m0 6l3 -3" />
                        </svg>{" "}
                      </div>
                      <p>Logout</p>
                    </div>
                  </Link>


                </div>
              </li>
            </ul>
          }




        </div>
      </nav>
    ) : (
      <nav className="navbar">
        <div className="sidebar-toggler mobile">
          <div className="sidebar-toggler-box">
            <div className="sidebar-toggler-icon"></div>
          </div>
        </div>
        <div className="navbar-content">
          <ul className="navbar-nav">
            <li className="nav-item nav-company">
              <div>
                <Link to={CONSTANTS.ROUTES.LOGIN}>Login</Link>
              </div>
            </li>
            <li className="nav-item nav-company">
              <div>
                <Link to={CONSTANTS.ROUTES.REGISTER}>Register</Link>
              </div>
            </li>
            {/* <li className="nav-item dropdown nav-profile"> 
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src={UserIcon}
              className="user-image"
              alt="profile"
            />
          </Link>
          <div className="dropdown-menu" aria-labelledby="profileDropdown">
            <div className="top">
              <Link to="" className="dropdown-item">
                <div className="content">
                  <div className="figure">
                    <img src={UserIcon} alt="" />
                  </div>
                  <div className="info">
                    <p className="">Iyere Ifeany Festus</p>
                    <p className="text-muted">Sam Afemikhe Firm</p>
                  </div>
                </div>
              </Link>
            </div>

            <hr />

            <Link to="" className="dropdown-item">
              <div className="content">
                <div className="thumb">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-photo"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#2e343b"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="15" y1="8" x2="15.01" y2="8" />
                    <rect x="4" y="4" width="16" height="16" rx="3" />
                    <path d="M4 15l4 -4a3 5 0 0 1 3 0l 5 5" />
                    <path d="M14 14l1 -1a3 5 0 0 1 3 0l 2 2" />
                  </svg>
                </div>
                <p>My Profile</p>
              </div>
            </Link>

            <Link
              to="Modules/Settings/myProfileView.html"
              className="dropdown-item"
            >
              <div className="content">
                <div className="thumb">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-settings"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#2e343b"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <circle cx="12" cy="12" r="3" />
                  </svg>{" "}
                </div>
                <p>Company Settings</p>
              </div>
            </Link>

            <Link to="" className="dropdown-item">
              <div className="content">
                <div className="thumb">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-help"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#2e343b"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="12" y1="17" x2="12" y2="17.01" />
                    <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                  </svg>
                </div>
                <p>Help and Support</p>
              </div>
            </Link>

            <hr />

            <Link to="" className="dropdown-item">
              <div className="content">
                <div className="thumb">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-logout"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#2e343b"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                    <path d="M7 12h14l-3 -3m0 6l3 -3" />
                  </svg>{" "}
                </div>
                <p>Logout</p>
              </div>
            </Link>
          </div>
        </li>*/}
          </ul>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUserProfile: state.currentUserProfile,
  currentUserCompany: state.currentUserCompany,
  auth: state.auth,
  company_name: state.dashboard.company_name,
  logged_in_username: state.dashboard.logged_in_username,
  employee: state.dashboard.employee


});
const mapDispatchToProps = (dispatch) => {
  return {
    logOutUser: () => dispatch(logoutUser())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
