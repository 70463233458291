import { DELETE_GRADE, GET_ALL_GRADES, GRADE_LOADING_STARTS, GRADE_LOADING_ENDS } from "../../actions/types"

const INITIAL_STATE = {
    grades: undefined,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_GRADES:
            return {
                ...state,
                grades: action.payload
            }
        case DELETE_GRADE:
            return {
                ...state,
                grades: state.grades.filter(item => item.id !== action.payload)
            }
        case GRADE_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case GRADE_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}