import {  EMPLOYEE_DOCUMENTS_LOADING_ENDS, EMPLOYEE_DOCUMENTS_LOADING_STARTS,GET_ALL_EMPLOYEE_DOCUMENTS, DELETE_EMPLOYEE_DOCUMENT } from "../../actions/types"

const INITIAL_STATE = {
    loading:false,
    data:[]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case EMPLOYEE_DOCUMENTS_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case EMPLOYEE_DOCUMENTS_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }
        case GET_ALL_EMPLOYEE_DOCUMENTS:
            return {
                ...state,
                data:action?.payload
            }
        case DELETE_EMPLOYEE_DOCUMENT:
            return {
                ...state,
                data: state.data.filter(doc => doc.id !== action.payload)
            }
        default:
            return state
    }
}