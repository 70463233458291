import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-center">
      <p className="text-muted text-center text-md-left">
        Copyright © { new Date().getFullYear()}{" "}
        <Link to="https://ikooba.com/" target="_blank">
          Ikooba Technologies
        </Link>
        .
      </p>
    </footer>
  );
};
export default Footer;
