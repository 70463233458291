import {
  FETCH_SINGLE_OVERTIME_BEGINS,
  FETCH_SINGLE_OVERTIME_SUCCESS,
  FETCH_SINGLE_OVERTIME_FAILURE,
  FETCH_OVERTIME_BEGINS,
  FETCH_OVERTIME_FAILURE,
  FETCH_OVERTIME_SUCCESS,
  DELETE_OVERTIME_SUCCESS,
  DELETE_OVERTIME_FAILED,
  DELETE_OVERTIME_BEGINS,
  SAVE_OVERTIME_BEGINS,
  SAVE_OVERTIME_SUCCESS,
  SAVE_OVERTIME_FAILED,
  SAVE_OVERTIME_ENDED,
  EDIT_OVERTIME_BEGINS,
  EDIT_OVERTIME_SUCCESS,
  EDIT_OVERTIME_FAILED,
  UPDATE_OVERTIME_SUCCESS,
  UPDATE_OVERTIME_FAILED,
  UPDATE_OVERTIME_BEGINS,
  SHOW_NETWORK_RESPONSE,
  CLEAR_NETWORK_RESPONSE,
  REDIRECT_TO,
  CLEAR_SINGLE_ERROR
} from "../../actions/types";

let initialState = {
  data: [],
  //firstLoading:true,
  overTime:{},
  loading: false,
  opsInProgress:false,
  errors:{},
  currentItem:{},
  currentItemId:'',
  statusCode: null,
  redirectTo: null
};

export const overTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case FETCH_OVERTIME_BEGINS:
      return {
        ...state,
        loading: true,
      };

      case FETCH_SINGLE_OVERTIME_SUCCESS:
        return {
          ...state,
          loading: false,
          error:false,
          overTime: action.payload,
        };

        case FETCH_SINGLE_OVERTIME_FAILURE:
          return {
            ...state,
            loading: true,
            error: action.payload.error,
          };
      
    case FETCH_OVERTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        opsInProgress:false,
        error: false,
        data: action.payload.data,
      };

    case FETCH_OVERTIME_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        opsInProgress:false,
      };
    case DELETE_OVERTIME_BEGINS:
      return {
        ...state,
        opsInProgress:true,
        currentItemId:action.payload.id
      };
    case DELETE_OVERTIME_SUCCESS:
      return {
        ...state,
        loading: false,  
        data: state.data.filter((item) => item.id !== action.payload.id),
        opsInProgress:false,
      };

    case DELETE_OVERTIME_FAILED:
      return {
        ...state,
        loading: false,
        opsInProgress:false,
      };


      case EDIT_OVERTIME_BEGINS:
        return {
          ...state,
          opsInProgress:true,
        };
      case EDIT_OVERTIME_SUCCESS:
        return {
          ...state,
          opsInProgress:false,
          currentItemId:action.payload.id
        };

        case UPDATE_OVERTIME_BEGINS:
          return{
            ...state,
            opsInProgress:true,
              
          }
        case UPDATE_OVERTIME_SUCCESS:
          return{
            ...state,
            data:action.payload.data,
            opsInProgress:true,
              
          }
        case UPDATE_OVERTIME_FAILED:
          return{
            ...state,
            error:action.payload,
            opsInProgress:false,
              
          }

          case SAVE_OVERTIME_BEGINS:
            return {
              ...state,
              opsInProgress:true
            };
      
          case SAVE_OVERTIME_SUCCESS:
            return {
              ...state,
              data: action.payload.data,
              opsInProgress:false
            };
          case SAVE_OVERTIME_FAILED:
            return {
              ...state,
              errors: action.payload.errors,
              opsInProgress:false
            };
          case SAVE_OVERTIME_ENDED:
            return {
              ...state,
              errors: {},
              opsInProgress:false
            };
         
            // case SHOW_NETWORK_RESPONSE:
            //   return {
            //       ...state,
            //       statusCode: action.statusCode
            //   }
          case CLEAR_NETWORK_RESPONSE:
              return {
                  ...state,
                  statusCode: null,
                  redirectTo: null
              }
          case REDIRECT_TO:
              return {
                  ...state,
                  redirectTo: action.payload
              }
          case CLEAR_SINGLE_ERROR:
            let err= action.payload.item
              return {
                  ...state,
                  errors:Object.fromEntries(Object.entries(state.errors)
                  .filter(([key, value]) => key !== err))
              }
    default:
      return state;
  }
};

