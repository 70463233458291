import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import Page500 from "./error-components/Page500"
import Page404 from "./error-components/Page404"
import { CLEAR_STATUS_CODE } from "../actions/types"
import { useHistory } from 'react-router-dom';


const ErrorStatusContext = React.createContext();


export const ErrorHandler = ({ children }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const apiStatusCode = useSelector(state => state.apiStatusCode.statusCode)


    React.useEffect(() => {
        
        const unlisten = history.listen(() => dispatch({ type: CLEAR_STATUS_CODE }));

        return unlisten
    },[])

    const renderContent = () => {
        if(apiStatusCode === 500) {
            return <Page500/>
        }

        if(apiStatusCode === 404) {
            return <Page404/>
        }

        return children
    }

    return (
        <ErrorStatusContext.Provider>
            {renderContent()}
        </ErrorStatusContext.Provider>
    )

}