import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import CONSTANTS from "../../common/constants.json";
import employeeRecruitmentNav from "./employeeRecruitmentRoutes"
import InnerSideBar from "../InnerSideBar";
import EmployeeJobApplicationView from "./EmployeeJobApplicationView"
import EmployeeJobApplicationApply from "./EmployeeJobApplicationApply";
import EmployeeApplicationListView from "./EmployeeApplicationListView";
import EmployeeJobApplicationComments from "./EmployeeApplicationComments";


class index extends Component {

    render() {
        return (
        <>
            <Router>
            <>
                <div className="page-content-title">
                <button className="page-hamburger" id="page-hamburger">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-dots-vertical"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="#2e343b"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="12" cy="19" r="1" />
                    <circle cx="12" cy="5" r="1" />
                    </svg>
                </button>
                <div>My Applications</div>
                </div>
                <div className="page-content-inner">
                <InnerSideBar data={employeeRecruitmentNav}/>
                <Switch>
                    <Route
                        exact
                        component={EmployeeJobApplicationView}
                        path={CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS}
                    />
                    <Route
                        component={EmployeeJobApplicationApply}
                        path={CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS_APPLY}
                    />
                    <Route
                        component={EmployeeApplicationListView}
                        path={CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS_DETAILS_LIST}
                    />
                    <Route
                        component={EmployeeJobApplicationComments}
                        path={`${CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS_INFO}/:id`}
                    />
                </Switch>
                </div>
            </>
            </Router>
        </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deployments: state.deployments
    }
}
export default connect(mapStateToProps)(index);
