import {
    GET_ALL_MONTHLY_PAYROLL,
    DELETE_MONTHLY_PAYROLL,
    MONTHLY_PAYROLL_LOADING_ENDS,
    MONTHLY_PAYROLL_LOADING_STARTS,
    GET_SINGLE_MONTHLY_PAYROLL,

    PAYSLIP_MONTHLY_APPROVAL_CRUD_STARTS,
    PAYSLIP_MONTHLY_APPROVAL_CRUD_ENDS,
    APPROVE_MONTHLY_PAYSLIP_APPROVAL,
    REJECT_MONTHLY_PAYSLIP_APPROVAL

} from "../../actions/types"

const INITIAL_STATE = {
    monthlypayroll: undefined,
    loading: false,
    crudOperation: false,
}

const changePayslipStatus = (arr, id, modifyStatus)=>{
    return arr.map((item)=> {
        const changes = item.id === id ? modifyStatus:item.approval_status
        return {
            ...item,
         approval_status:changes
    
        }
    })
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAYSLIP_MONTHLY_APPROVAL_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true
            }
            case PAYSLIP_MONTHLY_APPROVAL_CRUD_ENDS:
                return {
                    ...state,
                    crudOperation:false
                }

        case GET_SINGLE_MONTHLY_PAYROLL:
            return {
                ...state,
                monthlypayroll: action.payload,
            };
        case GET_ALL_MONTHLY_PAYROLL:
            return {
                ...state,
                monthlypayroll: action.payload,
            };
        case MONTHLY_PAYROLL_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case MONTHLY_PAYROLL_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case DELETE_MONTHLY_PAYROLL:
            return {
                ...state,
                monthlypayroll: state.monthlypayroll.filter(item => item.id !== action.payload)
            }

            case APPROVE_MONTHLY_PAYSLIP_APPROVAL:
                const approve = (changePayslipStatus(state.monthlypayroll, action.payload, 'approved'))
                return {
                    ...state,
                    monthlypayroll: approve
                }
            case REJECT_MONTHLY_PAYSLIP_APPROVAL:
                const reject = (changePayslipStatus(state.monthlypayroll, action.payload, 'Rejected')) 
                return {
                    ...state,
                    monthlypayroll: reject
                }
        default:
            return state;
    }
};
