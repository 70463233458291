import React from "react";
import { Link } from "react-router-dom";
import process from "../../../env"
import axios from "axios"
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux"
import { getErrorStatusCode } from "../../../errorHandlers/errorDispatcher"
import isEmpty from "../../../utils/isEmpty"
import { FormLazyLoad } from "../../Reusables/LazyLoads"


const service_url = process.env.SERVICE_URL

const EmployeeProfileView = (props) => {
  const dispatch = useDispatch()
  const [profile, setProfile] = React.useState({});

  const decoded = jwt_decode(localStorage.jwtToken);

  React.useEffect(() => {
    axios.get(`${service_url}get-employee-profile/${decoded.sub}`)
      .then(response => {
        setProfile(response.data.returnData);
      }).catch(error => {
        dispatch(getErrorStatusCode(error.response))
      })
  },[])
  return isEmpty(profile) ? <FormLazyLoad/> : (
    <div className="inner-page">
      <div className="my-card">
        <div className="my-card-header">My Profile</div>
        <div className="my-card-body">
          <div className="prof-img">
            <div className="image">
              <img src="../../assets/icons/user.png" alt="" />
            </div>
            <div className="name">{profile?.first_name}</div>
            <div className="id text-muted">{profile?.emp_code}</div>
          </div>

          <div className="mini-card">
            <div className="mini-card-header">Work Details</div>

            <div className="details">
              <div className="label text-muted">employee id</div>
              <div className="value">{profile?.emp_code}</div>
            </div>

            <div className="details">
              <div className="label text-muted">department id</div>
              <div className="value">{profile?.department?.department_name ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">unit</div>
              <div className="value">{profile?.unit?.unit_name ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">grade</div>
              <div className="value">{profile?.grade?.grade_name ?? "-------"}</div>
            </div>
          </div>

          <div className="mini-card">
            <div className="mini-card-header">Personal Details</div>

            <div className="details">
              <div className="label text-muted">first name</div>
              <div className="value">{profile?.first_name ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">middle name</div>
              <div className="value">{profile?.middlename ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">last name</div>
              <div className="value">{profile?.surname ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">Email</div>
              <div className="value">{profile?.email ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">phone number</div>
              <div className="value">{profile?.phone ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">Home Address</div>
              <div className="value">
              {profile?.home_address ?? "-------"}
              </div>
            </div>

            <div className="details">
              <div className="label text-muted">date of birth</div>
              <div className="value">{new Date(profile?.date_of_birth).toLocaleDateString('en-us', { day: "2-digit", month: "long", year: "numeric"}) ?? "-------"}</div>
            </div>
            <div className="details">
              <div className="label text-muted">gender</div>
              <div className="value">{profile?.gender ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">Maritial Status</div>
              <div className="value">{profile?.marital_status ?? "-------"}</div>
            </div>
          </div>

          <div className="mini-card">
            <div className="mini-card-header">Spouse Details - If Married</div>

            <div className="details">
              <div className="label text-muted">name</div>
              <div className="value">{profile?.spouse_firstname ? `${profile?.spouse_firstname} ${profile?.spouse_surname}` : "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">email</div>
              <div className="value">{profile?.spouse_email ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">phone number</div>
              <div className="value">{profile?.spouse_phone ?? "-------"}</div>
            </div>
          </div>

        {profile?.children.map(child => (
          <div className="mini-card" key={child.id}>
            <div className="mini-card-header">Children</div>

            <div className="details">
              <div className="label text-muted">name</div>
              <div className="value">{child?.name_of_child}</div>
            </div>

            <div className="details">
              <div className="label text-muted">date of birth</div>
              <div className="value">{child?.date_of_birth}</div>
            </div>

            <div className="details">
              <div className="label text-muted">gender</div>
              <div className="value">{child?.gender}</div>
            </div>
          </div>
        ))}


          <div className="mini-card">
            <div className="mini-card-header">Next of Kin</div>

            <div className="details">
              <div className="label text-muted">name</div>
              <div className="value">{profile?.nok_name ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">relationship</div>
              <div className="value">{profile?.relationship ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">phone number</div>
              <div className="value">{profile?.nok_contact_phone ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">address</div>
              <div className="value">
              {profile?.nok_address ?? "-------"}
              </div>
            </div>
          </div>
          <div className="mini-card">
            <div className="mini-card-header">Emergency Contact</div>

            <div className="details">
              <div className="label text-muted">name</div>
              <div className="value">{profile?.emergency_contact_name ?? "-------"}</div>
            </div>

            <div className="details">
              <div className="label text-muted">phone number</div>
              <div className="value">{profile?.emergency_phone ?? "-------"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfileView;
