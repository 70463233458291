import React, { useState } from "react";
import isEmpty from "../../utils/isEmpty";

export default function PasswordField({
  value,
  errors,
  setChange,
  name: userInputName,
  hasPattern,
  isSignUp,
  ...others
}) {
  const [visible, setVisible] = useState();
  const toggle = () => {
    setVisible(() => !visible);
  };

  const _eye = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={22}
      height={22}
      className="position-absolute svg-toggle-password"
      title="Toggle 
    Password Security"
      style={{ right: "14px", top: "20%", cursor: "pointer" }}
      onClick={toggle}
    >
      <title>Hide/Show Password</title>
      <path d="M24,9A23.654,23.654,0,0,0,2,24a23.633,23.633,0,0,0,44,0A23.643,23.643,0,0,0,24,9Zm0,25A10,10,0,1,1,34,24,10,10,0,0,1,24,34Zm0-16a6,6,0,1,0,6,6A6,6,0,0,0,24,18Z" />
      <rect
        x="20.133"
        y="2.117"
        height={44}
        transform="translate(23.536 -8.587) rotate(45)"
      />
      <rect
        x={22}
        y="3.984"
        width={4}
        height={44}
        transform="translate(25.403 -9.36) rotate(45)"
        style={{ fill: "#fff" }}
        className={visible ? "closed-eye" : "open-eye"}
      />
    </svg>
  );
  return (
    <div className="position-relative">
      <input
        type={`${visible ? "text" : "password"}`}
        className={`${
          errors === null ? "form-control" : "form-control is-invalid"
        }`}
        name={userInputName}
        onChange={setChange}
        value={value}
        pattern={
          hasPattern
            ? "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            : null
        }
        title="Password should contain Uppercase, lowercase, number and a special character"
        required
        {...others}
      />
      {_eye}
      {isSignUp ? (
        <small style={{ opacity: 0.7 }}>
          Password should contain Uppercase, lowercase, number and a special
          character{" "}
        </small>
      ) : null}
      <div className="invalid-feedback">{errors}</div>
    </div>
  );
}
