import { DELETE_EMPLOYEE_QUERY, DELETE_EMPLOYEE, DELETE_EMPLOYEE_AWARDS, DELETE_EMPLOYEE_EXIT, DELETE_EMPLOYEE_TAX_EXEMPTION, GET_ALL_EMPLOYEES, GET_COMMENDATIONS_AND_AWARDS, GET_EMPLOYEE_EXIT, GET_EMPLOYEE_LEAVE_HISTORY, GET_EMPLOYEE_QUERIES, GET_EMPLOYEE_TAX_EXEMPTIONS, GET_SINGLE_EMPLOYEE, EMPLOYEES_LOADING_STARTS, EMPLOYEES_LOADING_ENDS, GET_EMPLOYEE } from "../../actions/types"

const INITIAL_STATE = {
    employees: undefined,
    leaveHistory: undefined,
    employeeExit: undefined,
    employeeTaxExemptions: undefined,
    employeeCommendations: undefined,
    employeeQueries: undefined,
    employee: {},
    employeeLoading:false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            }
        case GET_EMPLOYEE_LEAVE_HISTORY:
            return {
                ...state,
                leaveHistory: action.payload
            }
        case GET_EMPLOYEE_EXIT:
            return {
                ...state,
                employeeExit: action.payload
            }
        case GET_SINGLE_EMPLOYEE:
            return {
                ...state,
                employee: action.payload
            }
        case GET_EMPLOYEE_TAX_EXEMPTIONS:
            return {
                ...state,
                employeeTaxExemptions: action.payload
            }
        case GET_COMMENDATIONS_AND_AWARDS:
            return {
                ...state,
                employeeCommendations: action.payload
            }
        case GET_EMPLOYEE_QUERIES:
            return {
                ...state,
                employeeQueries: action.payload
            }
        case DELETE_EMPLOYEE_EXIT:
            return {
                ...state,
                employeeExit: state.employeeExit.filter(item => item.id !== action.payload)
            }
        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter(item => item.id !== action.payload)
            }
        case DELETE_EMPLOYEE_AWARDS:
            return {
                ...state,
                employeeCommendations: state.employeeCommendations.filter(item => item.id !== action.payload)
            }
        case DELETE_EMPLOYEE_TAX_EXEMPTION:
            return {
                ...state,
                employeeTaxExemptions: state.employeeTaxExemptions.filter(item => item.id !== action.payload)
            }
        case DELETE_EMPLOYEE_QUERY:
            return {
                ...state,
                employeeQueries: state.employeeQueries.filter(item => item.id !== action.payload)
            }
        case EMPLOYEES_LOADING_STARTS:
            return {
                ...state,
                employeeLoading:true
            }
        case EMPLOYEES_LOADING_ENDS:
            return {
                ...state,
                employeeLoading:false
            }

        default:
            return state
    }
}