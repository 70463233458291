export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_SINGLE_ERROR = "CLEAR_SINGLE_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const IS_LOADING = "IS_LOADING";
export const NOT_LOADING = "NOT_LOADING";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const SET_PROFILE = "SET_PROFILE";
export const SET_COMPANY_PROFILE = "SET_COMPANY_PROFILE";

//User Role Type
export const CHECK_USER_ROLE = "CHECK_USER_ROLE";
export const VERIFY_USER_ROLE = "VERIFY_USER_ROLE";
export const ROLE_LOADING = "ROLE_LOADING";
export const ROLE_LOADING_DONE = "ROLE_LOADING_DONE";

//network
export const SHOW_NETWORK_RESPONSE = "SHOW_NETWORK_RESPONSE";
export const CLEAR_NETWORK_RESPONSE = "CLEAR_NETWORK_RESPONSE";
export const REDIRECT_TO = "REDIRECT_TO";
export const FETCH_ERROR = "FETCH_ERROR";

export const LOGGING_OUT_STARTS = "LOGGING_OUT_STARTS";
export const LOGGING_OUT_ENDS = "LOGGING_OUT_ENDS";
export const BULK_SALARY_ERROR = "BULK_SALARY_ERROR";
export const CLEAR_BULK_SALARY_ERROR = "CLEAR_BULK_SALARY_ERROR";
export const BULK_SALARY_SUCCESS = "BULK_SALARY_SUCCESS";
export const BULK_SALARY_RESET = "BULK_SALARY_RESET";

//Employees Module Action Types
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const EMPLOYEES_LOADING_STARTS = "EMPLOYEES_LOADING_STARTS";
export const EMPLOYEES_LOADING_ENDS = "EMPLOYEES_LOADING_ENDS";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_AWARDS = "DELETE_EMPLOYEE_AWARDS";
export const DELETE_EMPLOYEE_TAX_EXEMPTION = "DELETE_EMPLOYEE_TAX_EXEMPTION";
export const GET_EMPLOYEE_LEAVE_HISTORY = "GET_EMPLOYEE_LEAVE_HISTORY";
export const ADD_EMPLOYEE_EXIT = "ADD_EMPLOYEE_EXIT";
export const GET_EMPLOYEE_EXIT = "GET_EMPLOYEE_EXIT";
export const DELETE_EMPLOYEE_EXIT = "DELETE_EMPLOYEE_EXIT";
export const GET_SINGLE_EMPLOYEE = "GET_SINGLE_EMPLOYEE";
export const GET_EMPLOYEE_TAX_EXEMPTIONS = "GET_EMPLOYEE_TAX_EXEMPTIONS";
export const GET_COMMENDATIONS_AND_AWARDS = "GET_COMMENDATIONS_AND_AWARDS";
export const GET_EMPLOYEE_QUERIES = "GET_EMPLOYEE_QUERIES";
export const DELETE_EMPLOYEE_QUERY = "DELETE_EMPLOYEEE_QUERY";

//Department
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

//access control
export const GET_ALL_ACCESS_CONTROL = "GET_ALL_ACCESS_CONTROL";
export const DELETE_ACCESS_CONTROL = "DELETE_ACCESS_CONTROL";

//Grades
export const GET_ALL_GRADES = "GET_ALL_GRADES";
export const DELETE_GRADE = "DELETE_GRADE";

//leave category
export const GET_ALL_LEAVE_CATEGORY = "GET_ALL_LEAVE_CATEGORY";
export const DELETE_LEAVE_CATEGORY = "DELETE_LEAVE_CATEGORY";

//taxes
export const GET_ALL_TAXES = "GET__ALL_TAXES";
export const DELETE_TAX = "DELETE_TAX";

//Other deduction
export const GET_ALL_OTHER_DEDUCTION = "GET_ALL_OTHER_DEDUCTION";
export const DELETE_OTHER_DEDUCTION = "DELETE_OTHER_DEDUCTION";

//attendance settings
export const GET_ALL_WORK_DAYS = "GET_ALL_WORK_DAYS";
export const GET_SALARY_PRORATE_SETTING = "GET_SALARY_PRORATE_SETTING";

//notification settings
export const GET_NOTIFICATION_SETTING = "GET_NOTIFICATION_SETTING";

//leave request
export const GET_ALL_LEAVE_REQUESTS = "GET_ALL_LEAVE_REQUESTS";
export const DELETE_LEAVE_REQUEST = "DELETE_LEAVE_REQUEST";

//Units
export const GET_ALL_UNITS = "GET_ALL_UNITS";
export const DELETE_UNIT = "DELETE_UNIT";

//training
export const GET_ALL_TRAINING = "GET_ALL_TRAINING";
export const DELETE_TRAINING = "DELETE_TRAINING";

//Overtime
export const FETCH_SINGLE_OVERTIME_BEGINS = "FETCH_SINGLE_OVERTIME_BEGINS";
export const FETCH_SINGLE_OVERTIME_SUCCESS = "FETCH_SINGLE_OVERTIME_SUCCESS";
export const FETCH_SINGLE_OVERTIME_FAILURE = "FETCH_SINGLE_OVERTIME_FAILURE";

export const FETCH_OVERTIME_BEGINS = "FETCH_OVERTIME_BEGINS";
export const FETCH_OVERTIME_SUCCESS = "FETCH_OVERTIME_SUCCESS";
export const FETCH_OVERTIME_FAILURE = "FETCH_OVERTIME_FAILURE";

export const DELETE_OVERTIME_BEGINS = "DELETE_OVERTIME_BEGINS";
export const DELETE_OVERTIME_SUCCESS = "DELETE_OVERTIME_SUCESS";
export const DELETE_OVERTIME_FAILED = "DELETE_OVERTIME_FAILED";

export const EDIT_OVERTIME_BEGINS = "DELETE_OVERTIME_BEGINS";
export const EDIT_OVERTIME_SUCCESS = "EDIT_OVERTIME_SUCCESS";
export const EDIT_OVERTIME_FAILED = "EDIT_OVERTIME_FAILED";

export const UPDATE_OVERTIME_BEGINS = "UPDATE_OVERTIME_BEGINS";
export const UPDATE_OVERTIME_SUCCESS = "UPDATE_OVERTIME_SUCCESS";
export const UPDATE_OVERTIME_FAILED = "UPDATE_OVERTIME_FAILED";

export const SAVE_OVERTIME_BEGINS = "SAVE_OVERTIME_BEGINS";
export const SAVE_OVERTIME_SUCCESS = "SAVE_OVERTIME_SUCESS";
export const SAVE_OVERTIME_FAILED = "SAVE_OVERTIME_FAILED";
export const SAVE_OVERTIME_ENDED = "SAVE_OVERTIME_ENDED";

//Timesheet and Attendance
export const FETCH_TIMESHEET_BEGINS = "FETCH_TIMESHEET_BEGINS";
export const FETCH_TIMESHEET_SUCCESS = "FETCH_TIMESHEET_SUCESS";
export const FETCH_TIMESHEET_FAILED = "FETCH_TIMESHEET_FAILED";

export const FETCH_SINGLE_TIMESHEET_BEGINS = "FETCH_SINGLE_TIMESHEET_BEGINS";
export const FETCH_SINGLE_TIMESHEET_SUCCESS = "FETCH_SINGLE_TIMESHEET_SUCCESS";
export const FETCH_SINGLE_TIMESHEET_FAILED = "FETCH_SINGLE_TIMESHEET_FAILED";

export const DELETE_TIMESHEET_BEGINS = "DELETE_TIMESHEET_BEGINS";
export const DELETE_TIMESHEET_SUCCESS = "DELETE_TIMESHEET_SUCESS";
export const DELETE_TIMESHEET_FAILED = "DELETE_TIMESHEET_FAILED";

export const SAVE_TIMESHEET_BEGINS = "SAVE_TIMESHEET_BEGINS";
export const SAVE_TIMESHEET_SUCCESS = "SAVE_TIMESHEET_SUCESS";
export const SAVE_TIMESHEET_FAILED = "SAVE_TIMESHEET_FAILED";
export const NETWORK_SAVE_TIMESHEET_FAILED = "SAVE_TIMESHEET_FAILED";
export const SAVE_TIMESHEET_ENDED = "SAVE_TIMESHEET_ENDED";
export const SET_CLOCK_STATUS = "SET_CLOCK_STATUS";

export const EDIT_TIMESHEET_BEGINS = "EDIT_TIMESHEET_BEGINS";
export const EDIT_TIMESHEET_SUCCESS = "EDIT_TIMESHEET_SUCCESS";

export const UPDATE_TIMESHEET_BEGINS = "UPDATE_TIMESHEET_BEGINS";
export const UPDATE_TIMESHEET_SUCCESS = "UPDATE_TIMESHEET_SUCCESS";
export const UPDATE_TIMESHEET_FAILED = "UPDATE_TIMESHEET_FAILED";

export const REDIRECT_TO_TIMESHEET = "REDIRECT_TO_TIMESHEET";
// Rectruitment Module Action Types
export const GET_ALL_STAFF_REQUESTS = "GET_ALL_STAFF_REQUESTS";
export const GET_STAFF_REQUEST_DROPDOWN = "GET_STAFF_REQUEST_DROPDOWN";
export const GET_STAFF_REQUEST = "GET_STAFF_REQUEST";
export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const RECRUIT_MODULE_LOADING_STARTS = "RECRUIT_MODULE_LOADING_STARTS";
export const RECRUIT_MODULE_LOADING_ENDS = "RECRUIT_MODULE_LOADING_ENDS";

export const GET_ALL_JOB_APPLICATIONS = "GET_ALL_JOB_APPLICATIONS";
export const GET_JOB_APPLICATION = "GET_JOB_APPLICATION";
export const GET_JOB_APPLICATION_COMMENTS = "GET_JOB_APPLICATION_COMMENT";
export const GET_JOB_APPLICATIONS_BY_TYPE = "GET_JOB_APPLICATIONS_BY_TYPE";
export const DELETE_JOB_APPLICATION = "DELETE_JOB_APPLICATION";
export const GET_JOB_ALL_ASSESMENTS = "GET_JOB_ALL_ASSESMENTS";
export const GET_JOB_ASSESMENT = "GET_JOB_ASSESMENT";
export const GET_JOB_INVITATION = "GET_JOB_INVITATION";
export const GET_JOB_OFFER = "GET_JOB_OFFER";
export const GET_ALL_DEPLOYMENT = "GET_ALL_DEPLOYMENT";
export const GET_DEPLOYMENT = "GET_DEPLOYMENT";
export const DELETE_JOB_DEPLOYMENT = "DELETE_JOB_DEPLOYMENT";
export const GET_ALL_JOB_APPLICATIONS_COMMENTS =
  "GET_ALL_JOB_APPLICATIONS_COMMENTS";
//Performance Management
export const GET_ALL_KPI = "GET_ALL_KPI";
export const GET_ACHIEVEMENTS = "GET__ACHIEVEMENTS";
export const DELETE_ACHIEVEMENT = "DELETE_ACHIEVEMENT";
//dashboard
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

//payroll management
export const PAYSLIP_SEARCH_STARTS = "PAYSLIP_SEARCH_STARTS";
export const PAYSLIP_SEARCH_ENDS = "PAYSLIP_SEARCH_ENDS";
export const PAYSLIP_SEARCH_COMPLETED = "PAYSLIP_SEARCH_COMPLETED";
export const PAYSLIP_RESET = "PAYSLIP_RESET";
export const GET_MANAGE_SALARY = "GET_MANAGE_SALARY";
export const DELETE_MANAGE_SALARY = "DELETE_MANAGE_SALARY";
export const EDIT_MANAGE_SALARY = "EDIT_MANAGE_SALARY";
export const UPDATE_MANAGE_SALARY = "UPDATE_MANAGE_SALARY";

export const PAYROLL_LOADING_STARTS = "PAYROLL_LOADING_STARTS"
export const PAYROLL_LOADING_ENDS = "PAYROLL_LOADING_ENDS"

export const GET_ALL_APPROVED_SALARY = "GET_ALL_APPROVED_SALARY";

// Payroll By month
export const GET_ALL_MONTHLY_PAYROLL = "GET_ALL_MONTHLY_PAYROLL";
export const DELETE_MONTHLY_PAYROLL = "DELETE_MONTHLY_PAYROLL";

export const MONTHLY_PAYROLL_LOADING_STARTS = "MONTHLY_PAYROLL_LOADING_STARTS"
export const MONTHLY_PAYROLL_LOADING_ENDS = "MONTHLY_PAYROLL_LOADING_ENDS"
export const GET_SINGLE_MONTHLY_PAYROLL = "GET_SINGLE_MONTHLY_PAYROLL"

export const SINGLE_EMPLOYEE_PAYROLL_LOADING_STARTS = "SINGLE_EMPLOYEE_PAYROLL_LOADING_STARTS"
export const SINGLE_EMPLOYEE_PAYROLL_LOADING_ENDS = "SINGLE_EMPLOYEE_PAYROLL_LOADING_ENDS"
export const GET_ALL_SINGLE_EMPLOYEE_PAYROLL = "GET_ALL_SINGLE_EMPLOYEE_PAYROLL";

export const CREATE_ADDITIONAL_PAYMENT = "CREATE_ADDITIONAL_PAYMENT";
export const GET_ADDITIONAL_PAYMENT = "GET_ADDITIONAL_PAYMENT";
export const DELETE_ADDITIONAL_PAYMENT = "DELETE_ADDITIONAL_PAYMENT";
export const PAYROLL_EDIT_ADDITIONAL_PAYMENT =
  "PAYROLL_EDIT_ADDITIONAL_PAYMENT";
export const GET_UNIT_ADDITIONAL_PAYMENT = "GET_UNIT_ADDITIONAL_PAYMENT";

export const CREATE_DEDUCTION_PROCESSING = "CREATE_DEDUCTION_PROCESSING";
export const GET_DEDUCTION_PROCESSING = "GET_DEDUCTION_PROCESSING";
export const DELETE_DEDUCTION_PROCESSING = "DELETE_DEDUCTION_PROCESSING";
export const PAYROLL_EDIT_DEDUCTION_PROCESSING =
  "PAYROLL_EDIT_DEDUCTION_PROCESSING";
export const GET_UNIT_DEDUCTION_PROCESSING = "GET_UNIT_DEDUCTION_PROCESSING";
export const GET_SINGLE_KPI = "GET_SINGLE_KPI";

export const GET_ALL_CO_WORKERS = "GET_ALL_CO_WORKERS";

export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_ALL_STATES = "GET_ALL_STATES";

export const GET_APPROVED_SALARIES = "GET_APPROVED_SALARIES";
export const DELETE_APPROVED_SALARY = "DELETE_APPROVED_SALARY";

export const trainingTypes = {
  SAVE_TRAINING_REQUEST_BEGINS: "SAVE_TRAINING_REQUEST_BEGINS",
  SAVE_TRAINING_REQUEST_SUCCESS: "SAVE_TRAINING_REQUEST_SUCESS",
  SAVE_TRAINING_REQUEST_FAILED: "SAVE_TRAINING_REQUEST_FAILED",
  SAVE_TRAINING_REQUEST_ENDED: "SAVE_TRAINING_REQUEST_ENDED",
  // NETWORK_SAVE_TIMESHEET_FAILED: "SAVE_TIMESHEET_FAILED",
  REDIRECT_TO_TRAINING_VIEW: "REDIRECT_TO_TRAINING_VIEW",
  CLEAR_REDIRECT: "CLEAR_REDIRECT",

  //Get trainings
  GET_TRAINING_REQUEST_TRAININGS_BEGINS:
    "GET_TRAINING_REQUEST_TRAININGS_BEGINS",
  GET_TRAINING_REQUEST_TRAININGS_SUCCESS:
    "GET_TRAINING_REQUEST_TRAININGS_SUCCESS",
  GET_TRAINING_REQUEST_TRAININGS_FAILED:
    "GET_TRAINING_REQUEST_TRAININGS_FAILED",
  GET_TRAINING_REQUEST_TRAININGS_ENDED: "GET_TRAINING_REQUEST_TRAININGS_ENDED",

  //Get training request
  GET_TRAINING_REQUEST_BEGINS: "GET_TRAINING_REQUEST_BEGINS",
  GET_TRAINING_REQUEST_SUCCESS: "GET_TRAINING_REQUEST_SUCESS",
  GET_TRAINING_REQUEST_FAILED: "GET_TRAINING_REQUEST_FAILED",
  GET_TRAINING_REQUEST_ENDED: "GET_TRAINING_REQUEST_ENDED",

  //Get single training request
  GET_SINGLE_TRAINING_REQUEST_BEGINS: "GET_SINGLE_TRAINING_REQUEST_BEGINS",
  GET_SINGLE_TRAINING_REQUEST_SUCCESS: "GET_SINGLE_TRAINING_REQUEST_SUCESS",
  GET_SINGLE_TRAINING_REQUEST_FAILED: "GET_SINGLE_TRAINING_REQUEST_FAILED",
  GET_SINGLE_TRAINING_REQUEST_ENDED: "GET_SINGLE_TRAINING_REQUEST_ENDED",

  EDIT_TRAINING_REQUEST_ENDED: "GET_TRAINING_REQUEST_ENDED",

  //Delete training request
  DELETE_TRAINING_REQUEST_BEGINS: "DELETE_TRAINING_REQUEST_BEGINS",
  DELETE_TRAINING_REQUEST_SUCCESS: "DELETE_TRAINING_REQUEST_SUCESS",
  DELETE_TRAINING_REQUEST_FAILED: "DELETE_TRAINING_REQUEST_FAILED",
  DELETE_TRAINING_REQUEST_ENDED: "DELETE_TRAINING_REQUEST_ENDED",

  //Update training request
  UPDATE_TRAINING_REQUEST_BEGINS: "UPDATE_TRAINING_REQUEST_BEGINS",
  UPDATE_TRAINING_REQUEST_SUCCESS: "UPDATE_TRAINING_REQUEST_SUCESS",
  UPDATE_TRAINING_REQUEST_FAILED: "UPDATE_TRAINING_REQUEST_FAILED",
  UPDATE_TRAINING_REQUEST_ENDED: "UPDATE_TRAINING_REQUEST_ENDED",

  //set item id
  SET_TRAINING_REQUEST_ITEM_ID: "SET_TRAINING_REQUEST_ITEM_ID",
  SET_TRAINING_SCORE_CARD_ITEM_ID: "SET_TRAINING_SCORE_CARD_ITEM_ID",
  SET_TRAINING_SETTINGS_ID: "SET_TRAINING_SETTINGS_ID",

  //NETWORK_GET_TIMESHEET_FAILED:"GET_TIMESHEET_FAILED",
  REDIRECT_TO_TRAINING_REQUEST_VIEW: "REDIRECT_TO_TRAINING_REQUEST_VIEW",
  REDIRECT_TO_TRAINING_SCORE_CARD_VIEW: "REDIRECT_TO_TRAINING_SCORE_CARD_VIEW",

  //Get training scorecard
  GET_TRAINING_SCORECARD_BEGINS: "GET_TRAINING_SCORECARD_BEGINS",
  GET_TRAINING_SCORECARD_SUCCESS: "GET_TRAINING_SCORECARD_SUCESS",
  GET_TRAINING_SCORECARD_FAILED: "GET_TRAINING_SCORECARD_FAILED",
  GET_TRAINING_SCORECARD_ENDED: "GET_TRAINING_SCORECARD_ENDED",

  //Get training eligible list
  GET_TRAINING_DEVELOPMENT_ELIGIBLE_BEGINS:
    "GET_TRAINING_DEVELOPMENT_ELIGIBLE_BEGINS",
  GET_TRAINING_DEVELOPMENT_ELIGIBLE_SUCCESS:
    "GET_TRAINING_DEVELOPMENT_ELIGIBLE_SUCESS",
  GET_TRAINING_DEVELOPMENT_ELIGIBLE_FAILED:
    "GET_TRAINING_DEVELOPMENT_ELIGIBLE_FAILED",
  GET_TRAINING_DEVELOPMENT_ELIGIBLE_ENDED:
    "GET_TRAINING_DEVELOPMENT_ELIGIBLE_ENDED",

  //Get training eligible item
  GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_BEGINS:
    "GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_BEGINS",
  GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_SUCCESS:
    "GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_SUCESS",
  GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_FAILED:
    "GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_FAILED",
  GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_ENDED:
    "GET_S_TRAINING_DEVELOPMENT_ELIGIBLE_ENDED",

  //Get training scorecard
  SAVE_TRAINING_SCORECARD_BEGINS: "SAVE_TRAINING_SCORECARD_BEGINS",
  SAVE_TRAINING_SCORECARD_SUCCESS: "SAVE_TRAINING_SCORECARD_SUCESS",
  SAVE_TRAINING_SCORECARD_FAILED: "SAVE_TRAINING_SCORECARD_FAILED",
  SAVE_TRAINING_SCORECARD_ENDED: "SAVE_TRAINING_SCORECARD_ENDED",

  //Get single training scorecard
  GET_SINGLE_TRAINING_SCORECARD_BEGINS: "GET_SINGLE_TRAINING_SCORECARD_BEGINS",
  GET_SINGLE_TRAINING_SCORECARD_SUCCESS: "GET_SINGLE_TRAINING_SCORECARD_SUCESS",
  GET_SINGLE_TRAINING_SCORECARD_FAILED: "GET_SINGLE_TRAINING_SCORECARD_FAILED",
  GET_SINGLE_TRAINING_SCORECARD_ENDED: "GET_SINGLE_TRAINING_SCORECARD_ENDED",

  //Get training scorecard
  DELETE_TRAINING_SCORECARD_BEGINS: "DELETE_TRAINING_SCORECARD_BEGINS",
  DELETE_TRAINING_SCORECARD_SUCCESS: "DELETE_TRAINING_SCORECARD_SUCESS",
  DELETE_TRAINING_SCORECARD_FAILED: "DELETE_TRAINING_SCORECARD_FAILED",
  DELETE_TRAINING_SCORECARD_ENDED: "DELETE_TRAINING_SCORECARD_ENDED",

  //update training scorecard
  UPDATE_TRAINING_SCORECARD_BEGINS: "UPDATE_TRAINING_SCORECARD_BEGINS",
  UPDATE_TRAINING_SCORECARD_SUCCESS: "UPDATE_TRAINING_SCORECARD_SUCESS",
  UPDATE_TRAINING_SCORECARD_FAILED: "UPDATE_TRAINING_SCORECARD_FAILED",
  UPDATE_TRAINING_SCORECARD_ENDED: "UPDATE_TRAINING_SCORECARD_ENDED",
};

// REPORTS
export const GET_DEPARTMENT_REPORT = "GET_DEPARTMENT_REPORT";
export const GET_ALL_DESIGNATION_LIST = "GET_ALL_DESIGNATION_LIST";
export const GET_EMPLOYEE_SUMMARY_LIST_REPORT =
  "GET_EMPLOYEE_SUMMARY_LIST_REPORT";

// perfomance reports
export const GET_PERFORMANCE_REPORT = "GET_PERFORMANCE_REPORT";
export const GET_PERFORMANCE_REPORT_DETAILS = "GET_PERFORMANCE_REPORT_DETAILS";
export const GET_EMPLOYMENT_REPORT = "GET_EMPLOYMENT_REPORT";
export const GET_PERFORMANCE_REPORT_LOADING_STARTS =
  "GET_PERFORMANCE_REPORT_LOADING_STARTS";
export const GET_PERFORMANCE_REPORT_LOADING_ENDS =
  "GET_PERFORMANCE_REPORT_LOADING_ENDS";
export const GET_EMPLOYMENT_REPORT_STARTS = "GET_EMPLOYMENT_REPORT_STARTS";
export const GET_EMPLOYMENT_REPORT_ENDS = "GET_EMPLOYMENT_REPORT_ENDS";

export const GET_ALL_EMPLOYEES_LEAVE_REPORT = "GET_ALL_EMPLOYEES_LEAVE_REPORT";
export const GET_ALL_EMPLOYEES_IN_A_DEPARTMENT_LEAVE_REPORT =
  "GET_ALL_EMPLOYEES_IN_A_DEPARTMENT_LEAVE_REPORT";
export const GET_AN_EMPLOYEE_LEAVE_REPORT = "GET_AN_EMPLOYEE_LEAVE_REPORT";

export const AWARDS_REPORT = "AWARD_REPORT";
export const GET_EMPLOYEE_AWARD_REPORT = "GET_EMPLOYEE_AWARD_REPORT";

// recruitment reports
export const GET_DEPARTMENT_RECRUITMENT_REPORT =
  "GET_DEPARTMENT_RECRUITMENT_REPORT";

// Get rewards type
export const GET_ALL_AWARD_TYPES = "GET_ALL_AWARD_TYPES";

//MESSAGE
export const GET_ALL_MEMO = "GET_ALL_MEMO";
export const DELETE_MEMO = "DELETE_MEMO";
export const GET_ALL_NOTICE = "GET_ALL_NOTICE";

//HIDE DELETE MODAL
export const HIDE_DELETE_MODAL = "HIDE_DELETE_MODAL";
export const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL";

//ASSETS
export const GET_ALL_ASSETS = "GET_ALL_ASSETS";
export const GET_ASSET = "GET_ASSET";
export const ASSETS_LOADING_STARTS = "ASSETS_LOADING_STARTS";
export const ASSETS_LOADING_ENDS = "ASSETS_LOADING_ENDS";
export const ASSETS_CRUD_STARTS = "ASSETS_CRUD_STARTS";
export const ASSETS_CRUD_ENDS = "ASSETS_CRUD_ENDS";
export const DELETE_ASSET = "DELETE_ASSET";

//ASSETS
export const GET_ALL_ASSETS_CATEGORIES = "GET_ALL_ASSETS_CATEGORIES";
export const ASSETS_CATEGORIES_LOADING_STARTS =
  "ASSETS_CATEGORIES_LOADING_STARTS";
export const ASSETS_CATEGORIES_LOADING_ENDS = "ASSETS_CATEGORIES_LOADING_ENDS";
export const ASSETS_CATEGORIES_CRUD_STARTS = "ASSETS_CATEGORIES_CRUD_STARTS";
export const ASSETS_CATEGORIES_CRUD_ENDS = "ASSETS_CATEGORIES_CRUD_ENDS";
//Awards
export const GET_ALL_AWARDS = "GET_ALL_AWARDS";
export const DELETE_AWARD = "DELETE_AWARD";
export const AWARD_LOADING_STARTS = "AWARD_LOADING_STARTS";
export const AWARD_LOADING_ENDS = "AWARD_LOADING_ENDS";

//Designation
export const GET_ALL_DESIGNATIONS = "GET_ALL_DESIGNATIONS";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const DELETE_DESIGNATION = "DELETE_DESIGNATION";
export const DESIGNATION_LOADING_STARTS = "DESIGNATION_LOADING_STARTS";
export const DESIGNATION_LOADING_ENDS = "DESIGNATION_LOADING_ENDS";

//Payroll Report
export const GET_PAYROLL_REPORT = "GET_PAYROLL_REPORT";
export const PAYROLL_REPORT_LOADING_STARTS = "PAYROLL_REPORT_LOADING_STARTS";
export const PAYROLL_REPORT_LOADING_ENDS = "PAYROLL_REPORT_LOADING_ENDS";

//Paystack account details
export const GET_PAYSTACK_ACCOUNT = "GET_PAYSTACK_ACCOUNT";
export const PAYSTACK_ACCOUNT_LOADING_STARTS = "PAYSTACK_ACCOUNT_LOADING_STARTS";
export const PAYSTACK_ACCOUNT_LOADING_ENDS = "PAYSTACK_ACCOUNT_LOADING_ENDS";
export const DELETE_PAYSTACK_ACCOUNT = "DELETE_PAYSTACK_ACCOUNT";

// Paystack dedicated account
export const GET_PAYSTACK_DEDICATED_ACCOUNT = "GET_PAYSTACK_DEDICATED_ACCOUNT";
export const PAYSTACK_DEDICATED_ACCOUNT_LOADING_STARTS = "PAYSTACK_DEDICATED_ACCOUNT_LOADING_STARTS";
export const PAYSTACK_DEDICATED_ACCOUNT_LOADING_ENDS = "PAYSTACK_DEDICATED_ACCOUNT_LOADING_ENDS";
export const DELETE_PAYSTACK_DEDICATED_ACCOUNT = "DELETE_PAYSTACK_DEDICATED_ACCOUNT";
export const PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_STARTS = "PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_STARTS";
export const PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_ENDS = "PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_ENDS";

//Leave Report
export const LEAVE_AND_ATTENDANCE_REPORT_LOADING_STARTS =
  "LEAVE_AND_ATTENDANCE_REPORT_LOADING_STARTS";
export const LEAVE_AND_ATTENDANCE_REPORT_LOADING_ENDS =
  "LEAVE_AND_ATTENDANCE_REPORT_LOADING_ENDS";
export const GET_LEAVE_AND_ATTENDANCE_REPORT =
  "GET_LEAVE_AND_ATTENDANCE_REPORT";

//Attendance Report
export const ATTENDANCE_REPORT_LOADING_STARTS =
  "ATTENDANCE_REPORT_LOADING_STARTS";
export const ATTENDANCE_REPORT_LOADING_ENDS = "ATTENDANCE_REPORT_LOADING_ENDS";
export const GET_ATTENDANCE_REPORT = "GET_ATTENDANCE_REPORT";

// Training Report
export const GET_TRAINING_REPORTS = "GET_TRAINING_REPORTS";

//Employee Attendance
export const FETCH_ATTENDANCE_LIST_BEGINS = "FETCH_ATTENDANCE_LIST_BEGINS";
export const FETCH_ATTENDANCE_LIST_SUCCESS = "FETCH_ATTENDANCE_LIST_SUCCESS";

//export
export const CLOCKING_STARTS = "CLOCKING_STARTS";
export const CLOCKING_ENDS = "CLOCKING_ENDS";

//API status codes
export const GET_STATUS_CODE = "GET_STATUS_CODE";
export const CLEAR_STATUS_CODE = "CLEAR_STATUS_CODE";

//Job Application Loader
export const JOB_APPLICATION_LOADER_STARTS = "JOB_APPLICATION_LOADER_STARTS";
export const JOB_APPLICATION_LOADER_ENDS = "JOB_APPLICATION_LOADER_ENDS";

//Job Application CRUD
export const JOB_APPLICATION_CRUD_STARTS = "JOB_APPLICATION_CRUD_STARTS";
export const JOB_APPLICATION_CRUD_ENDS = "JOB_APPLICATION_CRUD_ENDS";

//Job Application comments loader
export const COMMENT_LOADING_STARTS = "COMMENT_LOADING_STARTS";
export const COMMENT_LOADING_ENDS = "COMMENT_LOADING_ENDS";

//Audit Trail Report
export const GET_ALL_AUDIT_TRAILS = "GET_ALL_AUDIT_TRAILS";
export const AUDIT_LOADING_STARTS = "AUDIT_LOADING_STARTS";
export const AUDIT_LOADING_ENDS = "AUDIT_LOADING_ENDS";
export const DATA_LOADED = "DATA_LOADED";
export const CLEAR_DATA_LOADED = "CLEAR_DATA_LOADED";

//Comment Reducer
export const COMMENT_DATA_LOADED = "COMMENT_DATA_LOADED";
export const COMMENT_DATA_LOADED_ENDS = "COMMENT_DATA_LOADED_ENDS";

//department loader

export const DEPARTMENT_LOADING_STARTS = "DEPARTMENT_LOADING_STARTS";
export const DEPARTMENT_LOADING_ENDS = "DEPARTMENT_LOADING_ENDS";

//Employee documents

export const EMPLOYEE_DOCUMENTS_LOADING_STARTS =
  "EMPLOYEE_DOCUMENTS_LOADING_STARTS";
export const EMPLOYEE_DOCUMENTS_LOADING_ENDS =
  "EMPLOYEE_DOCUMENTS_LOADING_ENDS";
export const GET_ALL_EMPLOYEE_DOCUMENTS = "GET_ALL_EMPLOYEE_DOCUMENTS";

export const DELETE_EMPLOYEE_DOCUMENT = "DELETE_EMPLOYEE_DOCUMENT";

//Employee loader
export const EMPLOYEE_LOADING_STARTS = "EMPLOYEE_LOADING_STARTS";
export const EMPLOYEE_LOADING_ENDS = "EMPLOYEE_LOADING_ENDS";

export const GET_UNITS_IN_DEPARTMENT = "GET_UNITS_IN_DEPARTMENT";
export const GET_GRADES_IN_DEPARTMENT = "GET_GRADES_IN_DEPARTMENT";
export const GET_DESIGNATIONS_IN_DEPARTMENT = "GET_DESIGNATIONS_DEPARTMENT";

//Grade loader
export const GRADE_LOADING_STARTS = "GRADE_LOADING_STARTS";
export const GRADE_LOADING_ENDS = "GRADE_LOADING_ENDS";
//Loans
export const GET_ALL_LOANS = "GET_ALL_LOANS";
export const GET_ALL_LOANS_STARTS = "GET_ALL_LOANS_STARTS";
export const GET_ALL_LOANS_ENDS = "GET_ALL_LOANS_ENDS";
export const GET_LOAN = "GET_LOAN";
export const LOAN_CRUD_STARTS = "LOAN_CRUD_STARTS";
export const LOAN_CRUD_ENDS = "LOAN_CRUD_ENDS";
export const DELETE_LOAN = "DELETE_LOAN";
export const APPROVE_LOAN = "APPRIVE_LOAN";
export const REJECT_LOAN = "REJECT_LOAN";

export const AUTH_LOADING_STARTS = "AUTH_LOADING_STARTS";
export const AUTH_LOADING_ENDS = "AUTH_LOADING_ENDS";

export const INCREMENT_PASSWORD_COUNT = "INCREMENT_PASSWORD_COUNT";
export const CLEAR_PASSWORD_COUNT = "CLEAR_PASSWORD_COUNT";

//approval types
export const GET_ALL_APPROVAL_TYPES = "GET_ALL_APPROVAL_TYPES";
export const VIEW_APPROVAL_TYPE = "VIEW_APPROVAL_TYPE";
export const EDIT_APPROVAL_TYPE = "EDIT_APPROVAL_TYPE";
export const DELETE_APPROVAL_TYPE = "DELETE_APPROVAL_TYPE";
export const CREATE_APPROVAL_TYPE = "CREATE_APPROVAL_TYPE";

//process flows
export const GET_ALL_PROCESS_FLOW = "GET_ALL_PROCESS_FLOW";
export const VIEW_PROCESS_FLOW = "VIEW_PROCESS_FLOW";
export const EDIT_PROCESS_FLOW = "EDIT_PROCESS_FLOW";
export const DELETE_PROCESS_FLOW = "DELETE_PROCESS_FLOW";
export const CREATE_PROCESS_FLOW = "CREATE_PROCESS_FLOW";

// offices
export const GET_ALL_OFFICES = "GET_ALL_OFFICES";
export const GET_AN_OFFICE = "GET_AN_OFFICE";
export const GET_OFFICES_LOADING_STARTS = "GET_OFFICES_LOADING_STARTS";
export const GET_OFFICES_LOADING_ENDS = "GET_OFFICES_LOADING_ENDS";
export const DELETE_OFFICES = "DELETE_OFFICES";
export const OFFICE_CRUD_LOADING_STARTS = "OFFICE_CRUD_LOADING_STARTS";
export const OFFICE_CRUD_LOADING_ENDS = "OFFICE_CRUD_LOADING_ENDS"

// prcesses
export const GET_ALL_PROCESS = "GET_ALL_PROCESS";
export const GET_A_PROCESS = "GET_A_PROCESS";
export const GET_PROCESS_LOADING_STARTS = "GET_PROCESS_LOADING_STARTS";
export const GET_PROCESS_LOADING_ENDS = "GET_PROCESS_LOADING_ENDS";
export const DELETE_PROCESS = "DELETE_PROCESS";
export const CREATE_PROCESS =  "CREATE_PROCESS";
export const EDIT_PROCESS =  "EDIT_PROCESS";
export const PROCESS_CRUD_STARTS = "PROCESS_CRUD_STARTS"
export const PROCESS_CRUD_ENDS = "PROCESS_CRUD_ENDS"
export const PROCESS_LOADING_STARTS = "PROCESS_LOADING_STARTS"
export const PROCESS_LOADING_ENDS = "PROCESS_LOADING_ENDS"



//approval types
export const APPROVAL_WORKFLOW_LOADING_STARTS = "APPROVAL_WORKFLOW_LOADING_STARTS";
export const APPROVAL_WORKFLOW_LOADING_ENDS = "APPROVAL_WORKFLOW_LOADING_ENDS";
export const GET_ALL_APPROVAL_WORKFLOW = "GET_ALL_APPROVAL_WORKFLOW";
export const VIEW_APPROVAL_WORKFLOW = "VIEW_APPROVAL_WORKFLOW";
export const EDIT_APPROVAL_WORKFLOW = "EDIT_APPROVAL_WORKFLOW";
export const DELETE_APPROVAL_WORKFLOW = "DELETE_APPROVAL_WORKFLOW";
export const CREATE_APPROVAL_WORKFLOWS = "CREATE_APPROVAL_WORKFLOWS";
export const   APPROVAL_WORKFLOW_CRUD_STARTS = "APPROVAL_WORKFLOW_CRUD_STARTS"
export const APPROVAL_WORKFLOW_CRUD_ENDS = "APPROVAL_WORKFLOW_CRUD_ENDS"

//process auth
export const GET_ALL_PROCESS_AUTH = "GET_ALL_PROCESS_AUTH";
export const VIEW_PROCESS_AUTH = "VIEW_PROCESS_AUTH";
export const EDIT_PROCESS_AUTH = "EDIT_PROCESS_AUTH";
export const DELETE_PROCESS_AUTH = "DELETE_PROCESS_AUTH";
export const CREATE_PROCESS_AUTH = "CREATE_PROCESS_AUTH";

//loan approval
export const GET_ALL_LOAN_APPROVAL = "GET_ALL_LOAN_APPROVAL";
export const LOAN_APPROVAL_LOADING_STARTS = "LOAN_APPROVAL_LOADING_STARTS";
export const LOAN_APPROVAL_LOADING_ENDS = "LOAN_APPROVAL_LOADING_ENDS";
export const REJECT_LOAN_APPROVAL = "REJECT_LOAN_APPROVAL";
export const APPROVE_LOAN_APPROVAL = "APPROVE_LOAN_APPROVAL";
export const LOAN_APPROVAL_CRUD_ENDS = "LOAN_APPROVAL_CRUD_ENDS"
export const LOAN_APPROVAL_CRUD_STARTS = "LOAN_APPROVAL_CRUD_STARTS"


//leave approval
export const GET_ALL_LEAVE_APPROVAL = "GET_ALL_LEAVE_APPROVAL";
export const LEAVE_APPROVAL_LOADING_STARTS = "LEAVE_APPROVAL_LOADING_STARTS";
export const LEAVE_APPROVAL_LOADING_ENDS = "LEAVE_APPROVAL_LOADING_ENDS";
export const REJECT_LEAVE_APPROVAL = "REJECT_LEAVE_APPROVAL";
export const APPROVE_LEAVE_APPROVAL = "APPROVE_LEAVE_APPROVAL";
export const LEAVE_APPROVAL_CRUD_ENDS = "LEAVE_APPROVAL_CRUD_ENDS"
export const LEAVE_APPROVAL_CRUD_STARTS = "LEAVE_APPROVAL_CRUD_STARTS"


//payslip approval
export const GET_ALL_PAYSLIP_APPROVAL = "GET_ALL_PAYSLIP_APPROVAL";
export const PAYSLIP_APPROVAL_LOADING_STARTS = "PAYSLIP_APPROVAL_LOADING_STARTS";
export const PAYSLIP_APPROVAL_LOADING_ENDS = "PAYSLIP_APPROVAL_LOADING_ENDS";
export const REJECT_PAYSLIP_APPROVAL = "REJECT_PAYSLIP_APPROVAL";
export const APPROVE_PAYSLIP_APPROVAL = "APPROVE_PAYSLIP_APPROVAL";
export const PAYSLIP_APPROVAL_CRUD_ENDS = "PAYSLIP_APPROVAL_CRUD_ENDS"
export const PAYSLIP_APPROVAL_CRUD_STARTS = "PAYSLIP_APPROVAL_CRUD_STARTS"

export const PAYSLIP_MONTHLY_APPROVAL_CRUD_ENDS = "PAYSLIP_MONTHLY_APPROVAL_CRUD_ENDS"
export const PAYSLIP_MONTHLY_APPROVAL_CRUD_STARTS = "PAYSLIP_MONTHLY_APPROVAL_CRUD_STARTS"

export const REJECT_MONTHLY_PAYSLIP_APPROVAL = "REJECT_MONTHLY_PAYSLIP_APPROVAL"
export const APPROVE_MONTHLY_PAYSLIP_APPROVAL = "APPROVE_MONTHLY_PAYSLIP_APPROVAL"

