import { trainingTypes } from "../../actions/types";

let initialState = {
  data: [],
  loading: true,
  currentItemId: "",
  opsInProgress: false,
  errors: {},
  statusCode: null,
  redirectTo: null,
};

let initialStateOptions = {
  department: [],
};

export const optionsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_DEPARTMENT_SUCCESS":
      return {
        ...state,
        department: action.payload.department,
      };
  }
};
export const trainingScoreCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case trainingTypes.GET_SINGLE_TRAINING_SCORECARD_BEGINS:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case trainingTypes.GET_SINGLE_TRAINING_SCORECARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case trainingTypes.GET_SINGLE_TRAINING_SCORECARD_FAILED:
      return {
        ...state,
        loading: false,
        errors: true,
      };

    case trainingTypes.GET_TRAINING_SCORECARD_BEGINS:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case trainingTypes.GET_TRAINING_SCORECARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case trainingTypes.GET_TRAINING_SCORECARD_FAILED:
      return {
        ...state,
        loading: false,
        errors: true,
      };

    case trainingTypes.SAVE_TRAINING_SCORECARD_BEGINS:
      return {
        ...state,
        opsInProgress: true,
      };
    case trainingTypes.SAVE_TRAINING_SCORECARD_SUCCESS:
      return {
        ...state,
        data: [],
        opsInProgress: false,
      };
    case trainingTypes.SAVE_TRAINING_SCORECARD_FAILED:
      return {
        ...state,
        errors: action.error,
        opsInProgress: false,
      };
    case trainingTypes.SAVE_TRAINING_SCORECARD_ENDED:

    case trainingTypes.DELETE_TRAINING_SCORECARD_BEGINS:
      return {
        ...state,
        opsInProgress: true,
      };
    case trainingTypes.SET_TRAINING_SCORE_CARD_ITEM_ID:
      return {
        ...state,
        currentItemId: action.payload.id,
      };

    case trainingTypes.DELETE_TRAINING_SCORECARD_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload.id),
        opsInProgress: false,
        currentItemId: "",
      };

    case trainingTypes.DELETE_TRAINING_SCORECARD_FAILED:
      return {
        ...state,
        error: "error occured",
        opsInProgress: false,
        currentItemId: "",
      };
    case trainingTypes.DELETE_TRAINING_SCORECARD_ENDED:
      return {
        ...state,
        opsInProgress: false,
        currentItemId: "",
      };
    // case trainingTypes.EDIT_TRAINING_REQUEST_ENDED:
    //   return {
    //     ...state,
    //     opsInProgress: false,
    //     data: [],
    //   };

    case trainingTypes.UPDATE_TRAINING_SCORECARD_BEGINS:
      return {
        ...state,
        opsInProgress: true,
        loading: false,
      };

    case trainingTypes.UPDATE_TRAINING_SCORECARD_SUCCESS:
      return {
        ...state,
        opsInProgress: false,
        data: [],
      };

    case trainingTypes.UPDATE_TRAINING_SCORECARD_FAILED:
      return {
        ...state,
        errors: action.payload.error,
        opsInProgress: false,
      };

    case trainingTypes.REDIRECT_TO_TRAINING_SCORE_CARD_VIEW:
      return {
        ...state,
        data: [],
        redirectTo: action.payload,
      };

      case trainingTypes.CLEAR_REDIRECT:
        return {
          ...state,
          redirectTo: null,
        };

    default:
      return state;
  }
};
