import { DELETE_DESIGNATION, DESIGNATION_LOADING_ENDS, DESIGNATION_LOADING_STARTS, GET_ALL_DESIGNATIONS, GET_DESIGNATION } from "../../actions/types"


const INITIAL_STATE = {
    designations: undefined,
    loading: false,
    designation:{}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DESIGNATION_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case DESIGNATION_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case GET_ALL_DESIGNATIONS:
            return {
                ...state,
                designations: action.payload
            }
        case GET_DESIGNATION:
            return {
                ...state,
                designation: action.payload
            }
        case DELETE_DESIGNATION:
            return {
                ...state,
                designations: state.designations.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}