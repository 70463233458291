import { DELETE_TAX, GET_ALL_TAXES } from "../../actions/types"

const INITIAL_STATE = {
    taxes: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TAXES:
            return {
                ...state,
                taxes: action.payload
            }
        case DELETE_TAX:
            return {
                ...state,
                taxes: state.taxes.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}