import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CONSTANTS from "../common/constants.json";

// const PrivateRoute = ({ component: Component, key, auth,  ...rest }) => (
//   <>
//   <Route
//   key ={key}
//     {...rest}
//     render={(props) =>
//       auth.isAuthenticated === true ? (
//         <Component {...props} dataFromPrivate={props} 
//         />
//       ) : (
//         <Redirect to={CONSTANTS.ROUTES.LOGIN} />
//       )
//     }
//   />

  

// const PrivateRoute = ({
//   component: Component, // use comp prop
//   key, auth,  ...rest
// }) => (
//   <Route
//     {...rest}
//     render={props =>
//       !auth.isAuthenticated? (
//         <Redirect to={CONSTANTS.ROUTES.LOGIN} />
//       ) : (
//         <Component {...props} />
//       )
//     }
//   />
// );
//   </>
// );


const PrivateRoute = ({
  component: Component, // use comp prop
  key, auth,  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      !auth.isAuthenticated? (
        <Redirect to={CONSTANTS.ROUTES.LOGIN} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(PrivateRoute);
