import { GET_DASHBOARD_DATA } from "../../actions/types"

const INITIAL_STATE = {
    total_employees: undefined,
    today_attendance: undefined,
    total_employees_on_leave: undefined,
    employee_birthdays: undefined,
    company_name: undefined,
    logged_in_username: undefined,
    clock_status: undefined,
    logged_in_user_attendance: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                total_employees: action.payload?.employee_count,
                today_attendance: action.payload?.attendance_today,
                total_employees_on_leave: action.payload?.employees_on_leave,
                employee_birthdays: action.payload?.employee_birthdays,
                company_name: action.payload?.company_name,
                logged_in_username: action.payload?.logged_in_username,
                employee: action.payload?.employee,
                sum_loan_request: action.payload?.sum_loan_request,
                paystack_balance: action.payload?.balance,
                clock_status: action.payload?.clock_status,
                logged_in_user_attendance: action.payload?.logged_in_user_attendance
            }
        default:
            return state
    }
}