import React from 'react'
import { useHistory } from "react-router-dom"
import "./errors.styles.css"





const Page404 = () => {
    const history = useHistory()

    const resetError = () => {
        history.goBack()
    }
    return (
        <div className="inner-page">
            <div className="my-card">
                <div className="d-flex err-height">
                    <div className="m-auto">
                        <h1 className="text-center">404 - Looks like  you're lost.</h1>
                        <p className="text-center">Maybe this page used to exist or you just spelled Something wrong.</p>
                        <p className="text-center">Chances are you spelled something wrong, So can you double check the URL?</p>

                        <div className="d-flex">
                            <button onClick={resetError} className="button primary m-auto">
                                Return Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;