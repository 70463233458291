import { GET_ALL_PAYSLIP_APPROVAL, PAYSLIP_APPROVAL_LOADING_STARTS, PAYSLIP_APPROVAL_CRUD_ENDS, PAYSLIP_APPROVAL_CRUD_STARTS,APPROVE_PAYSLIP_APPROVAL, REJECT_PAYSLIP_APPROVAL, PAYSLIP_APPROVAL_LOADING_ENDS} from "../../actions/types"



const INITIAL_STATE = {
    data: [],
    loading:false,
    payslipApproval:{},
    monthlyPayslip:{},
    crudOperation:false,
}

const changePayslipStatus = (arr, id, modifyStatus)=>{
    return arr.map((item)=> {
        const changes = item.id === id ? modifyStatus:item.approval_status
        return {
            ...item,
         approval_status:changes
    
        }
    })
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case GET_ALL_PAYSLIP_APPROVAL:
            return {
                ...state,
                payslipApproval: action.payload
            }
      
        case PAYSLIP_APPROVAL_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case PAYSLIP_APPROVAL_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }
            
        case PAYSLIP_APPROVAL_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true
            }
        case PAYSLIP_APPROVAL_CRUD_ENDS:
            return {
                ...state,
                crudOperation:false
            }
      
        case APPROVE_PAYSLIP_APPROVAL:
            const approve = (changePayslipStatus(state.payslipApproval, action.payload, 'approved'))
            console.log(approve, 'aaprved')
            return {
                ...state,
                payslipApproval: approve
            }
        case REJECT_PAYSLIP_APPROVAL:
            const reject = (changePayslipStatus(state.payslipApproval, action.payload, 'rejected')) 
            return {
                ...state,
                payslipApproval: reject
            }

        default:
            return state
    }
}

