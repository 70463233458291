import React,{useState} from "react";
import { Link, Redirect } from "react-router-dom";
import CONSTANTS from "../../common/constants.json";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { SpinnerGrow } from "../../Components/Reusables/loader/Loader";
import isEmpty from "../../utils/isEmpty";
import { CLEAR_ERRORS } from "../../actions/types";
import PasswordField from "../../Components/Reusables/PasswordField";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const [showCount, setShowCount]=useState(0)
  const { errors, networkResponse, auth } = useSelector((state) => state);

  React.useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_ERRORS });
    };
  }, []);

  const [userInput, setUserInput] = React.useState({
    email: "",
    password: "",
    _token: "{{ csrf_token() }}",
  });
  const pwdCount = auth.pwdCount;
  const handleChange = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    if(showCount <= 2){
      setShowCount(showCount+1)
    }
    e.preventDefault();
    dispatch(loginUser(userInput));
  };
  if (networkResponse.redirectTo !== null) {
    return <Redirect to={networkResponse.redirectTo} />;
  }
  return (
    <div className="col-lg-7 col-sm-7 col-xs-12 form-container">
      <ToastContainer />
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="mt-5 col-lg-8 form-child col-sm-12 col-xs-12"
      >
        <div className="mb-3">
          <h2>Sign in</h2>
          <p className="h6">
            New member?{" "}
            <Link to={CONSTANTS.ROUTES.REGISTER} className="">
              Create account
            </Link>
          </p>
        </div>
        <div className="my-3">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className={`${
              isEmpty(errors) ? "form-control" : "form-control is-invalid"
            }`}
            name="email"
            id="email"
            onChange={handleChange}
            value={userInput.email}
          />
          <div className="invalid-feedback">
            {isEmpty(errors) ? null : errors?.data?.errors?.email}
          </div>
        </div>
        <div className="my-3">
          <label htmlFor="password">Password</label>
          <PasswordField
            isSignUp={false}
            value={userInput?.password}
            name="password"
            setChange={handleChange}
            errors={isEmpty(errors) ? null : ""}
          />

          {/* {showCount === 3 && ( */}
            <Link
              to={CONSTANTS.ROUTES.FORGOT_PASSWORD}
              id="forgotpass"
              className="text-right"
            >
              <p>Forgot password?</p>
            </Link>
          {/* )} */}
        </div>
        {/* <div className="ml-3 form-group form-check">
                    <input type="checkbox" className="form-check-input" id="loggedin" />
                    <label className="form-check-label" htmlFor="loggedin"
                        >Keep me logged in</label>
                </div> */}
        <button disabled={auth.authLoading} className="button primary">
          {auth.authLoading ? <SpinnerGrow /> : "Login"}
        </button>
      </form>
      <div className="term_and_condition">
        <Link
              to={CONSTANTS.ROUTES.TERMS_AND_CONDITION}
              id="terms_and_condition"
              className="text-right"
            >
          <p>Terms and Condition</p>            
        </Link>
      </div>
    </div>
  );
};

export default Login;
