import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
// import { clearCurrentProfile } from "./actions/profileActions";
import { Provider } from "react-redux";
import store from "./store";
import CONSTANTS from "./common/constants.json";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { history } from "./utils/history";
import LogoutModal from "./Components/Reusables/LogoutModal";
import AuthPage from "./pages/AuthPage";
import TermsAndCondition from './pages/TermsAndCondition'
import DashboardPage from "./pages/DashboardPage";
import PrivateRoute from "./Components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./Components/LandingPage";

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

 
}

class App extends React.Component {
  render() {
    return (
      <>
        <ToastContainer />
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={CONSTANTS.ROUTES.LANDING_PAGE}
              component={LandingPage}
            />
            <Route path={CONSTANTS.ROUTES.LOGIN} component={AuthPage} />
            <Route path={CONSTANTS.ROUTES.TERMS_AND_CONDITION} component={TermsAndCondition} />
            <PrivateRoute
              path={CONSTANTS.ROUTES.DASHBOARD}
              component={DashboardPage}
            />
          </Switch>
        </Router>
        {this.props.logoutModal ? <LogoutModal /> : null}
        <Helmet>
          <script src="./assets/js/main.js"></script>
        </Helmet>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logoutModal: state.modals.logoutModal,
  };
};

export default connect(mapStateToProps)(App);
