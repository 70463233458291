import { GET_ALL_ASSETS, ASSETS_LOADING_STARTS, ASSETS_LOADING_ENDS, ASSETS_CRUD_STARTS, ASSETS_CRUD_ENDS, GET_ASSET, DELETE_ASSET } from "../../actions/types"

const INITIAL_STATE = {
    assets: [],
    assetCategories:[],
    isLoading:false,
    crudOperation:false,
    asset:{}
}

const assetReducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ASSETS:
        //    console.log("action eduer", action)
            return {
                ...state,
                assets: action.payload
            }      
        case GET_ASSET:
        //    console.log("action eduer", action)
            return {
                ...state,
                asset: action.payload
            }      
        case ASSETS_LOADING_STARTS:
            return {
                ...state,
                isLoading:true,
            }      
        case ASSETS_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true,
            }      
        case ASSETS_CRUD_ENDS:
            return {
                ...state,
                crudOperation:false,
            }      
        case ASSETS_LOADING_ENDS:
            return {
                ...state,
                isLoading:false,
            }  
        case DELETE_ASSET:
            return{
                ...state,
                assets: state.assets.filter(asset => asset.id !== action.payload)
            }    
        default:
            return state
    }
}



export default assetReducer;