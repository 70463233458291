import { IS_LOADING, NOT_LOADING } from "../actions/types"

const initialState = {
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                loading: true
            }
        case NOT_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}