import {
    GET_ALL_PROCESS_FLOW,
    VIEW_PROCESS_FLOW,
    EDIT_PROCESS_FLOW,
    UPDATE_PROCESS_FLOW,
    DELETE_PROCESS_FLOW,
    CREATE_PROCESS_FLOW,
  } from "../../actions/types";
  
  const iniState = {
    data: [],
    singleProcess: {},
  };
  
  export default (state = iniState, action) => {
    switch (action.type) {
      case GET_ALL_PROCESS_FLOW:
        return {
          ...state,
          data: action.payload,
        };
      case VIEW_PROCESS_FLOW:
        return {
          ...state,
          singleProcessFlow: action.payload,
        };
      case DELETE_PROCESS_FLOW:
        return {
          ...state,
          data: state.data.filter(
            (item) => item.id !== action.payload
          ),
        };
      default:
        return state;
    }
  };
  