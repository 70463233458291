import { DELETE_TRAINING, GET_ALL_TRAINING } from "../../actions/types"

const INITIAL_STATE = {
    trainings: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TRAINING:
            return {
                ...state,
                trainings: action.payload
            }
        case DELETE_TRAINING:
            return {
                ...state,
                trainings: state.trainings.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}