import { DELETE_ACHIEVEMENT, GET_ALL_KPI, GET_ACHIEVEMENTS, GET_SINGLE_KPI } from "../../actions/types"

const INITIAL_STATE = {
    kpis: undefined,
    acheivements: undefined,
    kpi: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_KPI:
            return {
                ...state,
                kpis: action.payload
            }
        case GET_ACHIEVEMENTS:
            return {
                ...state,
                acheivements: action.payload
            }
        case DELETE_ACHIEVEMENT:
            // console.log(state, "CHOP")
            // console.log("REDUCER", state.kpi.achievements)
            // console.log(state.kpi.achievements.filter(item => item.id !== action.payload))
            state.kpi.filter(item => {
                    if (item.achievements.id === action.payload) {
                        alert("found")
                    }
                })
                // return {
                //     ...state,
                //     kpi: state.kpi.filter(item => item.achievements.id !== action.payload)
                // }
            break
        case GET_SINGLE_KPI:
            return {
                ...state,
                kpi: action.payload
            }
        default:
            return state
    }
}