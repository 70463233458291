import {
  GET_ALL_APPROVAL_WORKFLOW,
  VIEW_APPROVAL_WORKFLOW,
  EDIT_APPROVAL_WORKFLOW,
  DELETE_APPROVAL_WORKFLOW,
  CREATE_APPROVAL_WORKFLOWS,
  APPROVAL_WORKFLOW_LOADING_STARTS,
  APPROVAL_WORKFLOW_LOADING_ENDS,
  APPROVAL_WORKFLOW_CRUD_STARTS,
  APPROVAL_WORKFLOW_CRUD_ENDS
} from "../../actions/types";

const iniState = {
  approvalWorkflows: [],
  singleApprovalWorkflow: {},
  isLoading:false,
  crudOperation :false,
};

export default (state = iniState, action) => {
  switch (action.type) {
    case APPROVAL_WORKFLOW_CRUD_STARTS:
      return {
        ...state,
        crudOperation: true,
      };
    case APPROVAL_WORKFLOW_CRUD_ENDS:
      return {
        ...state,
        crudOperation: false,
      };
    case APPROVAL_WORKFLOW_LOADING_STARTS:
      return {
        ...state,
        isLoading: true,
      };
    case APPROVAL_WORKFLOW_LOADING_ENDS:
      return {
        ...state,
        isLoading:false,
      };
    case GET_ALL_APPROVAL_WORKFLOW:
      return {
        ...state,
        approvalWorkflows: action.payload,
      };
    case VIEW_APPROVAL_WORKFLOW:
      return {
        ...state,
        singleApprovalWorkflow: action.payload,
      };
    case DELETE_APPROVAL_WORKFLOW:
      return {
        ...state,
        approvalWorkflows: state.approvalTypes.filter(
          (item) => item.id !== action.payload
        ),
      };
    case EDIT_APPROVAL_WORKFLOW:
      return {
        ...state,
        approvalWorkflows: action.payload,
      };
    default:
      return state;
  }
};
