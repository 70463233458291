import { GET_ALL_LOANS, GET_ALL_LOANS_STARTS, GET_ALL_LOANS_ENDS, GET_LOAN,LOAN_CRUD_ENDS, LOAN_CRUD_STARTS, DELETE_LOAN,APPROVE_LOAN, REJECT_LOAN} from "../../actions/types"

const INITIAL_STATE = {
    loans: [],
    loading:false,
    loan:{},
    crudOperation:false,
}

const changeLoanStatus = (arr, id, modifyStatus)=>{
    return arr.map((item)=> {
        const changes = item.id === id ? modifyStatus:item.status
        return {
            ...item,
         status:changes
    
        }
    })
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LOANS:
            return {
                ...state,
                loans: action.payload
            }
        case GET_LOAN:
            return {
                ...state,
                loan: action.payload
            }
        case GET_ALL_LOANS_STARTS:
            return {
                ...state,
                loading:true
            }
        case GET_ALL_LOANS_ENDS:
            return {
                ...state,
                loading:false
            }
        case LOAN_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true
            }
        case LOAN_CRUD_ENDS:
            return {
                ...state,
                crudOperation:false
            }
        case DELETE_LOAN:
            return {
                ...state,
                loans: state.loans.filter(item => item.id !== action.payload)
            }
        case APPROVE_LOAN:
            const approve = (changeLoanStatus(state.loans, action.payload, 'approved'))
            return {
                ...state,
                loans: approve
            }
        case REJECT_LOAN:
            const reject = (changeLoanStatus(state.loans, action.payload, 'rejected')) 
            return {
                ...state,
                loans: reject
            }

        default:
            return state
    }
}