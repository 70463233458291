import { GET_ALL_LEAVE_APPROVAL, LEAVE_APPROVAL_LOADING_STARTS, LEAVE_APPROVAL_CRUD_ENDS, LEAVE_APPROVAL_CRUD_STARTS,APPROVE_LEAVE_APPROVAL, REJECT_LEAVE_APPROVAL, LEAVE_APPROVAL_LOADING_ENDS} from "../../actions/types"



const INITIAL_STATE = {
    data: [],
    loading:false,
    leave:{},
    crudOperation:false,
}

const changeLeaveStatus = (arr, id, modifyStatus)=>{
    return arr.map((item)=> {
        const changes = item.id === id ? modifyStatus:item.status
        return {
            ...item,
         status:changes
    
        }
    })
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LEAVE_APPROVAL:
            return {
                ...state,
                leave: action.payload
            }
      
        case LEAVE_APPROVAL_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case LEAVE_APPROVAL_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }
            
        case LEAVE_APPROVAL_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true
            }
        case LEAVE_APPROVAL_CRUD_ENDS:
            return {
                ...state,
                crudOperation:false
            }
      
        case APPROVE_LEAVE_APPROVAL:
            const approve = (changeLeaveStatus(state.leave, action.payload, 1))
            return {
                ...state,
                leave: approve
            }
        case REJECT_LEAVE_APPROVAL:
            const reject = (changeLeaveStatus(state.leave, action.payload, 2)) 
            return {
                ...state,
                leave: reject
            }

        default:
            return state
    }
}

