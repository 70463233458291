import React from 'react'
import { useLocation } from 'react-router';
import "./NotAuthorized.css"
import Page403 from './Page403';



const NotAuthorized = () => {
    const location = useLocation()
    const { pathname } = location
    if(pathname.includes("/employee")) {
        return <Page403/>
    }
    if(pathname.includes("/performance")) {
        return <Page403/>
    }
    if(pathname.includes("/recruitment")) {
        return <Page403/>
    }
    if(pathname.includes("/payroll")) {
        return <Page403/>
    }
    if(pathname.includes("/settings")) {
        return <Page403/>
    }
    if(pathname.includes("/reports")) {
        return <Page403/>
    } else {
        return null
    }
}

export default NotAuthorized;