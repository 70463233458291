import React from "react";
import { useState, useEffect } from "react";
import process from "../../env";
import axios from "axios";
import { notify } from "../../utils/notification";
import CONSTANTS from "../../common/constants.json";
import { Loader, RollerLoader } from "../../Components/Reusables/loader/Loader";
import { ToastContainer } from "react-toastify";

const service_url = process.env.SERVICE_URL;
const VerifyAccount = (props) => {
  const { match, history } = props;
  const { token, user_id } = match.params;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const url = `${service_url}verify-account`;
  const handleReload = () => {
    verifyUser();
  };
  const verifyUser = async () => {
    setError(false);
    setLoading(true);
    const data = {
      user_id,
      token,
    };

    try {
      let res = await axios.post(url, data);
      if (res.status === 200) {
        history.push(CONSTANTS.ROUTES.LOGIN);
        setLoading(false);
        return notify("Your account has been verified", "success");
      }
    } catch (error) {
      setLoading(false);

      setError(true);
      if (error.response.status === 404) {
        return setErrorText("Token not found, request a new one");
      }

      if (error.response.status === 422) {
        history.push(CONSTANTS.ROUTES.LOGIN);
        return notify("Your account has already been verified");
      }
      if (error.response.status === 500) {
        return setErrorText(
          "ooops!, An error occurred, our Engineers will be notified right away"
        );
      }
      setLoading(false);
      return setErrorText("An Error Occured, pls try again!");
    }
  };
  useEffect(() => {
    verifyUser();
  }, []);

  return (
    <div className="d-flex  text-center align-items-center p-5">
      <ToastContainer />
      {error && (
        <div>
          <p>{errorText}</p>
          <button onClick={handleReload} className="btn btn-danger">
            Try again
          </button>
        </div>
      )}

      {loading && (
        <div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default VerifyAccount;
