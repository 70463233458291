import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CONSTANTS from "../../common/constants.json";
import 'react-toastify/dist/ReactToastify.css'
import { RectSpinner } from "../Reusables/loader/Loader";
import { getAllJobApplicationsLoggedInEmployee } from "../../actions/Module-Recruitment/jobApplicationActions";
import { clearNetworkResponse } from "../../actions/Network-Actions/networkActions";

export class EmployeeApplicationListView extends Component {
    componentDidMount() {
        this.props.getAllJobApplicationsLoggedInEmployee();
    }

    recallApi = () => {
        this.props.clearNetworkResponse()
        this.props.getAllJobApplicationsLoggedInEmployee()
    }
    render() {
        return (
        <div className="inner-page">
            <div className="my-card">
            <div className="my-card-header">My Applicantions</div>
            <div className="my-card-body">
                { this.props.networkResponse.fetchError ? (<button onClick={this.recallApi}>Something went wrong, click to try again</button>) : (
                <div className="applicant-list">
                {!this.props.jobApplications ?
                (
                    <RectSpinner />
                    )
                : this.props.jobApplications.length ? (
                    this.props.jobApplications.map(application => (
                    (
                        <Link key={application.id} to={`${CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS_INFO}/${application.id}`} className="applicant">
                        <div className="nme">{application.applicants ? `${application?.applicants.first_name} ${application?.applicants.surname}`: `${application?.first_name} ${application?.last_name}`}</div>
                        <div className="pst">{application.job_title?.job_title}</div>
                        <div className="bullet">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-right"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            strokeWidth="2.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                        </div>
                    </Link>
        
                    )
                    ))
                ) : (
                        <div>No Application is ongoing!</div>
                    )}

            </div>
                )}
            </div>
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        networkResponse: state.networkResponse,
        jobApplications: state.jobApplications.allJobApplications,
    };
};

const mapDispatchToProps = dispatch => ({
    getAllJobApplicationsLoggedInEmployee: () => dispatch(getAllJobApplicationsLoggedInEmployee()),
    clearNetworkResponse: () => dispatch(clearNetworkResponse())
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeApplicationListView);
