import React from "react";
import { RectSpinner } from "./loader/Loader";

const LogoutModal = () => {
    return (
        <>
            <div
                className={`modal fade show`}
                id="logoutmodal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h4>Logging out</h4>
                            <div className="d-flex">
                                <RectSpinner/>
                            </div>
                            <div className="modal-buttons">
                                {/* <button onClick type="button" className="button secondary" data-dismiss="modal">
                                    No </button>
                                <button onClick type="button" className="button primary" data-dismiss="modal">
                                    Yes </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogoutModal;
