import React from 'react'
import ReactJson from "react-json-view"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import "./errors.styles.css"




const Page500 = () => {
    const apiStatusCode = useSelector(state => state.apiStatusCode)
    const location = useLocation()
    const history = useHistory()

    const resetError = () => {
        history.push(location.pathname)
    }


    return (
        <div className="inner-page">
            <div className="my-card">
                <div className="my-card-body">
                    <div className="d-flex" style={{justifyContent: "space-between", alignItems: "center"}}>
                        <h3>Internal Server Error</h3>
                        <button onClick={resetError} className="btn btn-danger">
                            Reset
                        </button>
                    </div>
                    <ReactJson src={apiStatusCode.errorPayload} />
                </div>
            </div>
        </div>
    );
}

export default Page500;