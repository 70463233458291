const process = {
  env: {
    NODE_ENV: "development",
    SERVICE_URL: "https://back.hrms.bmactest.com/api/",
    BASE_URL: "https://back.hrms.bmactest.com/",
    // SERVICE_URL: "https://back.test.hrms.onebmac.com/api/",
    // BASE_URL: "https://back.test.hrms.onebmac.com/",
   // SERVICE_URL: "http://localhost:8000/api/",
   // BASE_URL: "http://localhost:8000/",
    //SERVICE_URL: "https://orion.onebmac.com/api/",
    // RECAPTCHA_SITE:"6LcfI7geAAAAAJy4_wDzd33Pp6S99nQU-XVlXhJi"
    RECAPTCHA_SITE: "6LcU5ccfAAAAAKJymMMuxohKgHmm0bT0Uc__n_BB"
  },
};

export default process;
