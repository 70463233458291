import { DELETE_MEMO, GET_ALL_MEMO, GET_ALL_NOTICE } from "../../actions/types"

const INITIAL_STATE = {
    memos: undefined,
    notices: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_MEMO:
            return {
                ...state,
                memos: action.payload
            }
        case DELETE_MEMO:
            return {
                ...state,
                memos: state.memos.filter(item => item.id !== action.payload)
            }
        case GET_ALL_NOTICE:
            return {
                ...state,
                notices: action.payload
            }
        default:
            return state
    }
}