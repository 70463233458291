import {
    GET_ALL_SINGLE_EMPLOYEE_PAYROLL,
    SINGLE_EMPLOYEE_PAYROLL_LOADING_ENDS,
    SINGLE_EMPLOYEE_PAYROLL_LOADING_STARTS

} from "../../actions/types"

const INITIAL_STATE = {
    singleEmployeePayroll: undefined,
    loading: false,
    crudOperation: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SINGLE_EMPLOYEE_PAYROLL:
            return {
                ...state,
                singleEmployeePayroll: action.payload
            }
        case SINGLE_EMPLOYEE_PAYROLL_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case SINGLE_EMPLOYEE_PAYROLL_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};
