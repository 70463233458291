import {
  FETCH_ATTENDANCE_LIST_BEGINS,
  FETCH_ATTENDANCE_LIST_SUCCESS
} from "../../actions/types";

let initialState = {
  data: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_LIST_BEGINS:
      return {
        ...state,
        loading: true
      }
    case FETCH_ATTENDANCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    default:
      return state
  }
};
