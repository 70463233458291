import { GET_ALL_LOAN_APPROVAL, LOAN_APPROVAL_LOADING_STARTS, LOAN_APPROVAL_CRUD_ENDS, LOAN_APPROVAL_CRUD_STARTS,APPROVE_LOAN_APPROVAL, REJECT_LOAN_APPROVAL, LOAN_APPROVAL_LOADING_ENDS} from "../../actions/types"

const INITIAL_STATE = {
    data: [],
    loading:false,
    loan:{},
    crudOperation:false,
}

const changeLoanStatus = (arr, id, modifyStatus)=>{
    return arr.map((item)=> {
        const changes = item.id === id ? modifyStatus:item.status
        return {
            ...item,
         status:changes
    
        }
    })
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LOAN_APPROVAL:
            return {
                ...state,
                loans: action.payload
            }
        case GET_ALL_LOAN_APPROVAL:
            return {
                ...state,
                loan: action.payload
            }
        case LOAN_APPROVAL_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case LOAN_APPROVAL_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }

        case LOAN_APPROVAL_CRUD_STARTS:
            return {
                ...state,
                crudOperation:true
            }
        case LOAN_APPROVAL_CRUD_ENDS:
            return {
                ...state,
                crudOperation:false
            }

        case APPROVE_LOAN_APPROVAL:
            const approve = (changeLoanStatus(state.loans, action.payload, 1))
            return {
                ...state,
                loans: approve
            }
        case REJECT_LOAN_APPROVAL:
            const reject = (changeLoanStatus(state.loans, action.payload, 2))
            return {
                ...state,
                loans: reject
            }

        default:
            return state
    }
}