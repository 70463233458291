import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CONSTANTS from "../../common/constants.json";
import { notify } from "../../utils/notification";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { RectSpinner } from "../Reusables/loader/Loader";
import Modal from "../Reusables/Modal";
import { deleteInternalJobApplication, getAllJobApplicationsLoggedInEmployee, searchJobApplication } from "../../actions/Module-Recruitment/jobApplicationActions";
import { clearNetworkResponse } from "../../actions/Network-Actions/networkActions";
import SearchResources from "../Reusables/SearchResources";

class EmployeeJobApplicationView extends Component {

  state = {
    isVisible: false,
    deleteID: null,
  }

  componentDidMount() {
    this.props.getAllJobApplicationsLoggedInEmployee();
  }

  recallApi = () => {
    this.props.clearNetworkResponse()
    this.props.getAllJobApplicationsLoggedInEmployee()
  }

  showModal = (e, id) => {
    this.setDeleteID(id);
    e.preventDefault();
    let newState = { ...this.state };
    newState.isVisible = true;
    this.setState(newState);
  };


  editApplication = (e, id) => {
    e.preventDefault();
    this.props.history.push(`${CONSTANTS.ROUTES.RECRUITMENT_JOB_APPLICATIONS_UPDATE}/${id}`);
  };

  setDeleteID = (e, id) => {
    let newState = { ...this.state };
    newState.deleteID = id;
    this.setState(newState);
  };

  deleteApplication = (e) => {
    e.preventDefault();
    this.props.deleteInternalJobApplication(this.state.deleteID);
  };


  render() {

    return (
      <div className="inner-page">
        <ToastContainer />
        <div className="my-card">
            <Modal
              msg="Are you sure you want to delete this Application?"
              imgSrc="../../assets/images/deleteModal.svg"
              yes="Yes, Delete"
              no="No, Cancel"
              showModal="show"
              eventTrigger={this.deleteApplication}
            />
          <div className="my-card-header">
            </div>
          <div className="my-card-body">
          
          <div className="d-flex justify-content-between mt-3">
            <SearchResources actionToDispatch={this.props.searchJobApplication}/>
            <div className=" form-button">
              <Link
                to={CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS_APPLY}
                className="button secondary form-aux"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-plus"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#5f6368"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
                  Apply for Job
                </Link>
            </div>
            </div>
            { this.props.networkResponse.fetchError ? (<button onClick={this.recallApi}>Something went wrong, click to try again</button>) : (
            <div className="table-div">
              <table className="my-table employee-exit">
                <thead>
                  <tr>
                    <th> Application Type</th>
                    <th> Employee Name</th>
                    <th> Job Title</th>
                    <th> Email</th>
                    <th> Years of Experience</th>
                    <th> Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {!this.props.jobApplications ?
                    (
                      <tr>
                        <td colSpan="7">
                          <RectSpinner />
                        </td>
                      </tr>
                    )
                    : this.props.jobApplications.length ? (
                      this.props.jobApplications.map(application => (
                        <tr key={application.id}>
                        <td>{application?.application_type}</td>
                          <td>{`${application.applicants.first_name} ${application.applicants.surname}`}</td>
                          <td>{application.job_title?.job_title} </td>
                          <td>{application.applicants?.email}</td>
                          <td>{application.years_in_role}</td>
                          <td>{application.status}</td>
                          <td>
                            <button onClick={(e) => this.editApplication(e, application.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-edit"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#5f6368"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                <line x1="16" y1="5" x2="19" y2="8" />
                              </svg>
                            </button>
                            <button
                              data-toggle="modal"
                              data-target="#deleteConfirmationModal"
                              onClick={(e) => this.setDeleteID(e, application.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-trash"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#5f6368"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="4" y1="7" x2="20" y2="7" />
                                <line x1="10" y1="11" x2="10" y2="17" />
                                <line x1="14" y1="11" x2="14" y2="17" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                              </svg>
                            </button>
                          </td>
                        </tr>

                      ))
                    ) : (
                        <tr>
                          <td colSpan="7">
                            <div>No Job Application Yet</div>
                          </td>
                        </tr>
                      )}
                </tbody>
              </table>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    deleteInternalJobApplication: id => dispatch(deleteInternalJobApplication(id)),
    getAllJobApplicationsLoggedInEmployee: () => dispatch(getAllJobApplicationsLoggedInEmployee()),
    clearNetworkResponse: () => dispatch(clearNetworkResponse()),
    searchJobApplication:(query)=>dispatch(searchJobApplication(query))
  };
};
const mapStateToProps = state => {
  return {
    networkResponse: state.networkResponse,
    jobApplications: state.jobApplications.allJobApplications,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeJobApplicationView);
