import { GET_ALL_AUDIT_TRAILS, AUDIT_LOADING_STARTS, AUDIT_LOADING_ENDS, CLEAR_DATA_LOADED, DATA_LOADED} from "../../actions/types";

const initialState = {
    loading: false,
    data: undefined,
    dataLoaded: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_AUDIT_TRAILS:
            return {
                ...state,
                data: action.payload
            }
        case AUDIT_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case AUDIT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case DATA_LOADED:
            return {
                ...state,
                dataLoaded: true
            }
        case CLEAR_DATA_LOADED:
            return {
                ...state,
                dataLoaded: false
            }
        default:
            return state
    }
};
