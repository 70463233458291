import { DELETE_PAYSTACK_DEDICATED_ACCOUNT, GET_PAYSTACK_DEDICATED_ACCOUNT, PAYSTACK_DEDICATED_ACCOUNT_LOADING_STARTS, PAYSTACK_DEDICATED_ACCOUNT_LOADING_ENDS,PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_ENDS,PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_STARTS } from "../../actions/types"

const INITIAL_STATE = {
    dedicated: undefined,
    isLoading: false,
    crud:false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PAYSTACK_DEDICATED_ACCOUNT:
            return {
                ...state,
                dedicated: action.payload
            }
        case DELETE_PAYSTACK_DEDICATED_ACCOUNT:
            return {
                ...state,
                dedicated: state.dedicated.filter(item => item.account_id !== action.payload)
            }
        case PAYSTACK_DEDICATED_ACCOUNT_LOADING_STARTS:
            return {
                ...state,
                isLoading: true
            }
        case PAYSTACK_DEDICATED_ACCOUNT_LOADING_ENDS:
            return {
                ...state,
                isLoading: false
            }
            case PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_STARTS:
            return {
                ...state,
                crud: true
            }
        case PAYSTACK_DEDICATED_ACCOUNT_CRUD_LOADING_ENDS:
            return {
                ...state,
                crud:false
            }
        default:
            return state
    }
}