import React, {useState} from "react";

const DynamicOptions = ({
  otherTexts,
  toggleShow,
  showText = false,
  shrink,
  invalidFeedback,
  inputTitle,
  optionsTitle,
  optionsValue = [],
  storeInputs,
  optValue,
  all,
  name,
  disableOption,
  ...otherProps
}) => {
 // console.log("OPT", optionsValue);
  return (
    <>
      <div className={shrink ? "form-group col-md-6" : "form-group col-md-12"}>
        <label htmlFor="inputState">{inputTitle}</label>
        <select
          name={name ? name.toLowerCase() : null}
          onChange={storeInputs}
          id="inputState"
          value={optValue}
          className={`form-control ${invalidFeedback ? "is-invalid" : null}`}
          {...otherProps}
        >
          <option defaultValue={'DEFAULT'}>{optionsTitle}</option>
          {all ? <option value="all">All</option> : null}
          {optionsValue?.map((item) => (
            <option disabled ={disableOption ===item.id}  key={item.id} value={item.id || item.training_setting_id}>
              {item.name ||
                item.department_name ||
                item.grade_name ||
                item.name_of_additional_payment ||
                item.job_title ||
                item.name_of_deduction ||
                item.unit_name ||
                item.training_name ||
                item?.deduction?.name_of_deduction ||
                item?.award_type ||
                item?.full_name ||
                item?.asset_name ||
                item?.employee?.full_name ||
                item?.description ||
                item?.name
                }
            
                   
            </option>
          ))}
        </select>
        <p
          onClick={toggleShow}
          style={{
            color: "green",
            fontSize: "0.84rem",
            cursor: "pointer",
            display: showText ? "inline-block" : "none",
          }}
        >
          {otherTexts}
        </p>
        <div className="invalid-feedback">{invalidFeedback}</div>
      </div>
    </>
  );
};

export default React.memo(DynamicOptions);
