import {
    dashboardSVG,
    timeAndAttendanceSVG,
    absenceAndLeaveSVG,
    trainingAndDevelopmentSVG,
    messagesSVG,
    recruitmentSVG
} from "../svgs";

import CONSTANTS from "../../../../common/constants.json";
const employeeNavItems = [{
        svg: dashboardSVG,
        name: "Dashboard",
        path: CONSTANTS.ROUTES.DASHBOARD,
        id:1,
    },
    {
        svg: timeAndAttendanceSVG,
        name: "Time and Attendance",
        path: CONSTANTS.ROUTES.TIME_AND_ATTENDANCE,
        id:5,
    },
    {
        svg: absenceAndLeaveSVG,
        name: "Absence and Leave",
        path: CONSTANTS.ROUTES.ABSENCE_AND_LEAVE,
        id:6,
    },
    {
        svg: trainingAndDevelopmentSVG,
        name: "Training and Development",
        path: CONSTANTS.ROUTES.TRAINING_AND_DEVELOPMENT,
        id:7,
    },
    // {
    //     svg: messagesSVG,
    //     name: "Message",
    //     path: CONSTANTS.ROUTES.MESSAGE,
    //     id:9,
    // },
    {
        svg: recruitmentSVG,
        name: "My Applications",
        path: CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS,
        id:10,
    },
    {
        svg: recruitmentSVG,
        name: "My Loans",
        path: CONSTANTS.ROUTES.EMPLOYEE_LOAN,
        id:10,
    },
    {
        svg: trainingAndDevelopmentSVG,
        name: "My Profile",
        path: '/my-profile',
        id:11,
    },
    {
        svg: trainingAndDevelopmentSVG,
        name: "My Approvals",
        path: '/my-approvals',
        id:11,
    }
];


export default employeeNavItems;