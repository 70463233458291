import { useState, useEffect, lazy } from 'react'
import { useSelector } from 'react-redux'
import CONSTANTS from '../../common/constants.json'
// import Dashboard from '../../Components/dashboard/Dashboard'


const preLoadDashboard = import('../../Components/dashboard/Dashboard')
const Dashboard = lazy(() => preLoadDashboard);

const preLoadEmployee = import("../../Components/Employee")
const Employee = lazy(() => preLoadEmployee);

const preLoadTimeModule = import('../../Components/TimeModule')
const TimeModule = lazy(() => preLoadTimeModule)


const preLoadPerformance = import('../../Components/Performance')
const Performance = lazy(() => preLoadPerformance)

const preLoadLeave = import('../../Components/LeaveModule/Leave')
const Leave = lazy(() => preLoadLeave)

const preLoadTraining = import('../../Components/TrainingModule')
const Training = lazy(() => preLoadTraining)

const preLoadPayroll = import('../../Components/PayrollModule/Payroll')
const Payroll = lazy(() => preLoadPayroll)

const preLoadApproval = import('../../Components/Approval/Approval')
const Approval = lazy(() => preLoadApproval)

const preLoadMessage = import('../../Components/Message')
const Message = lazy(() => preLoadMessage)

const preLoadReports = import('../../Components/ReportsModule/Reports')
const Reports = lazy(() => preLoadReports)

const preLoadSettings = import('../../Components/SettingsModule')
const Settings = lazy(() => preLoadSettings)

const preLoadRecruitment = import('../../Components/Recruitment')
const Recruitment = lazy(() => preLoadRecruitment)

const preLoadEmployeeLoanView = import('../../Components/Loan')
const EmployeeLoanView = lazy(() => preLoadEmployeeLoanView)

const preLoadEmployeeViewSalary = import('../../Components/PayrollModule/Payroll')
const EmployeeViewSalary = lazy(() => preLoadEmployeeViewSalary)


const usePermittedRoutes = () => {
  const [routes, setRoutes] = useState([])
  const permissions = useSelector(state => state?.auth?.userPermissions)
  const {
    view_performance_mgt_module,
    view_employee_mgt_module,
    view_time_and_attendance_module,
    view_absence_and_leave_module,
    view_training_and_development_module,
    view_payroll_mgt_module,
    view_message_module,
    view_reports_module,
    view_appprovals_module,
    view_my_loans_module,
    view_settings_module,
    view_dashboard_module,
    view_recruitment_module,
    employee_view_salary_module
  } = {} = permissions

  const { DASHBOARD, PERFORMANCE, APPROVAL_LIST, TIME_AND_ATTENDANCE, TRAINING_AND_DEVELOPMENT, SETTINGS, REPORTS, EMPLOYEE, ABSENCE_AND_LEAVE,
    MESSAGE_CHAT_LIST, PAYROLL_MANAGEMENT,  RECRUITMENT, EMPLOYEE_LOAN, EMPLOYEE_VIEW_SALARY } = CONSTANTS.ROUTES

  const routeFunc = () => {
    const setRoute = [
      {
        path: DASHBOARD,
        permitted: view_dashboard_module,
        component: Dashboard,
        exact: true
      },
      {
        path: EMPLOYEE,
        permitted: view_employee_mgt_module,
        component: Employee
      },
      {

        path: EMPLOYEE_LOAN,
        permitted: view_my_loans_module,
        component: EmployeeLoanView
      },
      {
        path: RECRUITMENT,
        permitted: view_recruitment_module,
        component: Recruitment
      },

      {
        path: PERFORMANCE,
        permitted: view_performance_mgt_module,
        component: Performance
      },
 
      {
        path: TIME_AND_ATTENDANCE,
        permitted: view_time_and_attendance_module,
        component: TimeModule
      },
      {
        path: ABSENCE_AND_LEAVE,
        permitted: view_absence_and_leave_module,
        component: Leave
      },
      {
        path: TRAINING_AND_DEVELOPMENT,
        permitted: view_training_and_development_module,
        component: Training
      },
      {
        path: PAYROLL_MANAGEMENT,
        permitted: view_payroll_mgt_module,
        component: Payroll
      },
      {
        path: APPROVAL_LIST,
        permitted: view_appprovals_module,
        component: Approval
      },
      {
        path: MESSAGE_CHAT_LIST,
        permitted: 0,
        component: Message
      },

      {
        path: REPORTS,
        permitted: view_reports_module,
        component: Reports
      },
      {
        path: SETTINGS,
        permitted: view_settings_module,
        component: Settings
      },
      // Employee view salary alone 11052022
      {
        path: EMPLOYEE_VIEW_SALARY,
        permitted: employee_view_salary_module,
        component: EmployeeViewSalary
      },


    ]
    setRoutes(setRoute)
  }

  useEffect(() => {
    let cancel = false
    if (cancel) return;
    routeFunc();
    return () => {
      cancel = true
    }
  }, [permissions])

  const permittedRoutes = routes?.filter(route => route.permitted)
  return permittedRoutes

}

export default usePermittedRoutes;