import axios from "axios"
import process from "../../env"
import { GET_DASHBOARD_DATA, GET_STATUS_CODE } from "../types"
// import { notify } from "../../utils/notification"



const service_url = process.env.SERVICE_URL



export const getDashboardData = () => {
    return async(dispatch) => {
        try {
            const res = await axios.get(`${service_url}get-dashboard-data`)
            dispatch({
                type: GET_DASHBOARD_DATA,
                payload: res.data.returnData
            })
        } catch (error) {
            if(error?.response?.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error?.response
                })
            }
        }
    }
}