import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import process from "../env";
import { GET_ERRORS, SET_CURRENT_USER, LOG_OUT_USER, VERIFY_USER_ROLE, REDIRECT_TO, LOGGING_OUT_STARTS, LOGGING_OUT_ENDS, GET_STATUS_CODE, AUTH_LOADING_STARTS, AUTH_LOADING_ENDS, INCREMENT_PASSWORD_COUNT, CLEAR_PASSWORD_COUNT } from "./types";
import { notify } from "../utils/notification";
import CONSTANTS from "../common/constants.json"
import { clearNetworkResponse } from "./Network-Actions/networkActions";



const service_url = process.env.SERVICE_URL;


// Register User
export const registerUser = (userData) => (dispatch) => {
    dispatch({
        type: AUTH_LOADING_STARTS,
    });
    axios
        .post(
            `${service_url}register`,
            userData
        )
        .then((res) => {
            dispatch({
                type: AUTH_LOADING_ENDS,
            });
            dispatch({
                type: REDIRECT_TO,
                payload: CONSTANTS.ROUTES.LOGIN
            })
            dispatch(clearNetworkResponse())
            return notify("Account created successfully, Check email to verify account", "success")
        })
        .catch((err) => {
            dispatch({
                type: AUTH_LOADING_ENDS,
            });
            if (err.response) {
                if (err.response.status === 422) {
                    console.log(err.response, 'ERROR')
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else if (err.response.status === 500) {
                    dispatch({
                        type: GET_STATUS_CODE,
                        payload: err.response
                    })
                    return notify('Sorry, something went wrong!', 'error')
                } else {
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        });
};

// Login - Get User Token
export const loginUser = (userData) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: AUTH_LOADING_STARTS,
            });

            const request = await axios.post(`${service_url}login`, userData)
            // Save to localStorage
            const { access_token } = request.data;
            // Set token to ls
            localStorage.setItem("jwtToken", access_token);
            // Set token to Auth header
            setAuthToken(access_token);
            // Decode token to get user data
            const decoded = jwt_decode(access_token);
            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch({ type: VERIFY_USER_ROLE })
            dispatch({ type: AUTH_LOADING_ENDS });
            dispatch({ type: CLEAR_PASSWORD_COUNT })
            dispatch({
                type: REDIRECT_TO,
                payload: CONSTANTS.ROUTES.DASHBOARD
            })
            dispatch(clearNetworkResponse())
            // window.location.href = CONSTANTS.ROUTES.DASHBOARD
        } catch (err) {
            dispatch({
                type: AUTH_LOADING_ENDS,
            });
            if (err.response) {
                if (err.response.status === 422) {
                    if (err.response.data.returnMessage == "Incorrect passsword") {

                        dispatch({ type: INCREMENT_PASSWORD_COUNT })
                    }
                    return notify(err.response.data.returnMessage, 'error')
                } else if (err.response.status === 404) {
                    return notify(err.response.data.returnMessage, 'error')
                } else if (err.response.status === 400) {
                    return notify(err.response.data.returnMessage, 'error')
                } else if (err.response.status === 401) {
                    return notify(err.response.data.message, 'error')
                } else {
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const logoutUser = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGGING_OUT_STARTS })
            const req = await axios.post(`${service_url}logout`)
            if (req.status === 200) {
                dispatch({ type: LOGGING_OUT_ENDS })
                dispatch({
                    type: LOG_OUT_USER
                })
                // Remove token from localStorage
                localStorage.removeItem("jwtToken");
                localStorage.clear()
                // Remove auth header for future requests
                setAuthToken(false);
                // Set current user to {} which will set isAuthenticated to false
                dispatch(setCurrentUser({}));
                window.location.href = CONSTANTS.ROUTES.LOGIN
            }
        } catch (err) {
            console.log(err)
            dispatch({ type: LOGGING_OUT_ENDS })
            return notify("Unable to logout. Try again", "error")
        }
    }
}


// Log user out
// export const logoutUser = () => (dispatch) => {
//     dispatch({
//             type: LOG_OUT_USER
//         })
//         // Remove token from localStorage
//     localStorage.removeItem("jwtToken");
//     localStorage.clear()
//         // Remove auth header for future requests
//     setAuthToken(false);
//     // Set current user to {} which will set isAuthenticated to false
//     dispatch(setCurrentUser({}));
//     window.location.href = CONSTANTS.ROUTES.LOGIN
// };


