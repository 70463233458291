import {
    GET_MANAGE_SALARY,
    UPDATE_MANAGE_SALARY,
    CREATE_ADDITIONAL_PAYMENT,
    DELETE_ADDITIONAL_PAYMENT,
    GET_ADDITIONAL_PAYMENT,
    DELETE_DEDUCTION_PROCESSING,
    GET_DEDUCTION_PROCESSING,
    DELETE_MANAGE_SALARY,
    GET_APPROVED_SALARIES,
    DELETE_APPROVED_SALARY,
    PAYSLIP_SEARCH_STARTS,
    PAYSLIP_SEARCH_ENDS,
    PAYSLIP_SEARCH_COMPLETED,
    PAYSLIP_RESET,
    GET_ALL_APPROVED_SALARY,
    PAYROLL_LOADING_STARTS,
    PAYROLL_LOADING_ENDS
} from "../../actions/types"

const INITIAL_STATE = {
    payroll: undefined,
    // payroll: undefined,
    additional_payment: undefined,
    deleted_additional_payment: undefined,
    edited_additional_payment: undefined,
    get_deduction_processing:undefined,
    approvedSalaries: undefined,
    payslipSearch: {
        loading: false,
        data: {}
    },
    approvedSalaries: undefined,
    loading:false,
    crudOperation:false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAYROLL_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case PAYROLL_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        
        case GET_MANAGE_SALARY:
            return {
                ...state,
                payroll: action.payload
            }
        case DELETE_MANAGE_SALARY:
            return {
                ...state,
                payroll: state.payroll.filter(item => item.id !== action.payload)
            }
        case UPDATE_MANAGE_SALARY:
            return {
                ...state,
                edit_salary: action.payload
            }
        case GET_APPROVED_SALARIES:
            return {
                ...state,
                approvedSalaries: action.payload
            }
        case DELETE_APPROVED_SALARY:
            return {
                ...state,
                approvedSalaries: state.approvedSalaries.filter(item => item.id !== action.payload)
            }
        case CREATE_ADDITIONAL_PAYMENT:
            return {
                ...state,
                edit_salary: action.payload
            }
        case GET_ADDITIONAL_PAYMENT:
            return {
                ...state,
                additional_payment: action.payload
            }
        case DELETE_ADDITIONAL_PAYMENT:
            return {
                ...state,
                deleted_additional_payment: action.payload
            }

        case GET_DEDUCTION_PROCESSING:
            return {
                ...state,
                get_deduction_processing: action.payload
            }
        case DELETE_DEDUCTION_PROCESSING:
            return {
                ...state,
                get_deduction_processing: state.get_deduction_processing.filter(item => item.id !== action.payload)
            }
        case PAYSLIP_SEARCH_STARTS:
            return {
                ...state,
                payslipSearch: {
                    ...state.payslipSearch,
                    loading: true
                }
            }
        case PAYSLIP_SEARCH_ENDS:
            return {
                ...state,
                payslipSearch: {
                    ...state.payslipSearch,
                    loading: false
                }
            }
        case PAYSLIP_SEARCH_COMPLETED:
            return {
                ...state,
                payslipSearch: {
                    ...state.payslipSearch,
                    data: action.payload
                }
            }
        case PAYSLIP_RESET:
            return {
                ...state,
                payslipSearch: {
                    loading: false,
                    data: {}
                }
            }
        case GET_ALL_APPROVED_SALARY:
            return {
                ...state,
                approvedSalaries: action.payload
            }
        default:
            return state
    }
}