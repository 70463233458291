// import isEmpty from "../utils/isEmpty";

import { GET_ALL_CO_WORKERS } from "../../actions/types";

const initialState = {
    coWorkers: [],
    currentConversations: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CO_WORKERS:
            return {
                ...state,
                coWorkers: action?.payload ?? []
            };
        default:
            return state;
    }
}