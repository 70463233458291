import { CLEAR_ERRORS, CLEAR_NETWORK_RESPONSE, FETCH_ERROR } from "../types"

export const clearNetworkResponse = () => {
    return {
        type: CLEAR_NETWORK_RESPONSE
    }
}

export const networkResponseError = () => {
    return {
        type: FETCH_ERROR
    }
}


export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
}