import { GET_ERRORS, CLEAR_ERRORS } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            console.log("GET_ERROR", action.payload)
            const  data  = action?.payload?.response?.data
            return {
                ...state,
                data
            }
        case CLEAR_ERRORS:
            return {};
        default:
            return state;
    }

}