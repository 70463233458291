import React, { Component } from "react";
import { connect } from "react-redux";
import InputData from "../../Reusables/InputData";
import isEmpty from "./../../../utils/isEmpty";
import { SpinnerGrow } from "../../Reusables/loader/Loader";
import { addJobInvitation } from "../../../actions/Module-Recruitment/jobApplicationActions";



class InviteForJob extends Component {

    constructor() {
        super();
        this.state = {
            job_application_id: '',
            type_of_invitation: '',
            message: '',
            date_of_interview: '',
            time_of_interview: '',
            file_attachment_path: '',
            location: '',
        };
    };

    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        const invitation = {
            job_application_id: this.props.invitee.job_application_id,
            type_of_invitation: this.state.type_of_invitation,
            message: this.state.message,
            date_of_interview: this.state.date_of_interview,
            time_of_interview: this.state.time_of_interview,
            file_attachment_path: this.state.file_attachment_path,
            location: this.state.location
        }
        this.props.dispatch(addJobInvitation(invitation))
    }


    render() {
        const { invitee, errors, isLoading } = this.props

        return (
            <form onSubmit={this.handleSubmit}>

                <div className="modal job_offer" id="inviteForJob" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className=" _myModal job_offer" role="document">
                        <div className="_modal_header">
                            <div className="title">Invite For a Job</div>
                            <button type="button" data-dismiss="modal" aria-label="Close">
                                <svg className="Icon--small Icon XIcon" focusable="false" viewBox="0 0 32 32">
                                    <path
                                        d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                        <div className="_modal-content">

                            <div className="title">
                                {invitee.job_title}
                            </div>
                            <div className="name">
                                {invitee.name}
                            </div>
                            <div className="mail">
                                {invitee.email}
                            </div>

                            <div className="_add">
                                {invitee.location}
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <InputData
                                        inputType="text"
                                        inputTitle="Type of Invitation"
                                        required
                                        value={this.state.type_of_invitation}
                                        onChange={this.handleChange}
                                        name="type_of_invitation"
                                        inputError={
                                            isEmpty(errors)
                                                ? null
                                                : errors.data.errors.type_of_invitation
                                        }
                                    />

                                </div>
                                <div className="form-group col-12">
                                    <label >Message</label>
                                    <textarea
                                        name="message"
                                        required
                                        className={ `form-control ${errors?.data?.errors?.message ? 'is-invalid' : '' }`}
                                        name="message"
                                        onChange={this.handleChange}
                                    >{this.state.message}</textarea>
                                    {isEmpty(errors)
                                        ? null
                                        : (
                                            <div className="invalid-feedback">
                                                {errors.data.errors.message}
                                            </div>
                                        )}


                                </div>
                                <div className="form-group col-md-6">
                                    <InputData
                                        inputType="text"
                                        inputTitle="Location"
                                        required
                                        value={this.state.location}
                                        onChange={this.handleChange}
                                        name="location"
                                        inputError={
                                            isEmpty(errors)
                                                ? null
                                                : errors.data.errors.location
                                        }
                                    />

                                </div>
                                <div className="form-group col-md-6">
                                    <InputData
                                        inputType="date"
                                        inputTitle="Date of interview"
                                        required
                                        value={this.state.date_of_interview}
                                        onChange={this.handleChange}
                                        name="date_of_interview"
                                        inputError={
                                            isEmpty(errors)
                                                ? null
                                                : errors.data.errors.date_of_interview
                                        }
                                    />

                                </div>
                                <div className="form-group col-md-6">
                                    <InputData
                                        inputType="time"
                                        inputTitle="Time"
                                        required
                                        value={this.state.time_of_interview}
                                        onChange={this.handleChange}
                                        name="time_of_interview"
                                        inputError={
                                            isEmpty(errors)
                                                ? null
                                                : errors.data.errors.time_of_interview
                                        }
                                    />

                                </div>
                                <div className="form-group col-md-6">
                                    <InputData
                                        inputType="text"
                                        inputTitle="Attachment"
                                        value={this.state.file_attachment_path}
                                        onChange={this.handleChange}
                                        name="file_attachment_path"
                                        inputError={
                                            isEmpty(errors)
                                                ? null
                                                : errors.data.errors.file_attachment_path
                                        }
                                    />

                                </div>
                            </div>
                            <div className="text-right">
                                {/* <button type="button"
                                    className="button secondary mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye"
                                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="12" cy="12" r="2" />
                                        <path
                                            d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                    </svg>
                                Preview
                            </button> */}
                                <button disabled={isLoading} type="submit" className="button primary mr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send"
                                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor"
                                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                        <path
                                            d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5">
                                        </path>
                                    </svg>
                                { isLoading ? (<SpinnerGrow/>) : "Send"}
                            </button>
                                <button disabled={isLoading} type="button" className="button secondary" data-dismiss="modal" aria-label="Close">
                                    Cancel
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = state => {
    return {
        errors: state.errors,
        isLoading: state.isLoading.loading,
        networkResponse: state.networkResponse,
    };
};

export default connect(mapStateToProps)(InviteForJob);
