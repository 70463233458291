import React from "react";
import CONSTANTS from "../common/constants.json";
import { Switch, Route } from "react-router-dom";
import Login from "./auth-components/Login";
import Register from "./auth-components/Register";
import ForgotPassword from "./auth-components/ForgotPassword";
import Logo from "../assets/images/logo.png";
import "./auth.styles.css";
import VerifyAccount from "./auth-components/VerifiyAccount";
import ResetForgotPassword from "./auth-components/ResetForgotPassword";
import LandingPage from "../Components/LandingPage";

const AuthPage = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-5 col-lg-4 side-img d-none d-sm-flex">
          <div className="side-img__text">
            <img src={Logo} alt="" />
            <p className="h1 font-weight-bold text-white w-75 text-wrap">
              Collaborative Software for Business
            </p>
          </div>
        </div>
        <Switch>
          <Route exact path={CONSTANTS.ROUTES.LOGIN} component={Login} />
          <Route path={CONSTANTS.ROUTES.REGISTER} component={Register} />
          <Route
            exact
            path={CONSTANTS.ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route
            path={`${CONSTANTS.ROUTES.VERIFY_ACCOUNT}/:token/:user_id`}
            component={VerifyAccount}
          />
          <Route
            path={`${CONSTANTS.ROUTES.FORGOT_PASSWORD}/:token/:user_id`}
            component={ResetForgotPassword}
          />
        </Switch>
      </div>
    </div>
  );
};

export default AuthPage;
