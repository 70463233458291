import CONSTANTS from "../../common/constants.json";


const employeeRecruitmentNav = [
    {
        name: "View All Applications",
        details: "Summary of your applications",
        path: CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS,
        id: 0,
    },
    {
        name: "Applications Details",
        details: "Details about your applications",
        path: CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS_DETAILS_LIST,
        id: 1,
    }
];

export default employeeRecruitmentNav;
