import React from "react";
export function dashboardSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-dashboard"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="12" cy="13" r="2" />
      <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
      <path d="M6.4 20a9 9 0 1 1 11.2 0Z" />
    </svg>
  );
}
export function recruitmentSVG() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 20.1667C18.0501 20.1667 21.3334 16.8834 21.3334 12.8333C21.3334 8.78325 18.0501 5.5 14 5.5C9.94993 5.5 6.66669 8.78325 6.66669 12.8333C6.66669 16.8834 9.94993 20.1667 14 20.1667Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 38.5V34.8333C3 32.8884 3.77262 31.0232 5.14788 29.6479C6.52315 28.2726 8.38841 27.5 10.3333 27.5H17.6667C19.6116 27.5 21.4768 28.2726 22.8521 29.6479C24.2274 31.0232 25 32.8884 25 34.8333V38.5"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 25C31.2091 25 33 23.2091 33 21C33 18.7909 31.2091 17 29 17C26.7909 17 25 18.7909 25 21C25 23.2091 26.7909 25 29 25Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 28L32 24"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function employeeManagementSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-id"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect x="3" y="4" width="18" height="16" rx="3" />
      <circle cx="9" cy="10" r="2" />
      <line x1="15" y1="8" x2="17" y2="8" />
      <line x1="15" y1="12" x2="17" y2="12" />
      <line x1="7" y1="16" x2="17" y2="16" />
    </svg>
  );
}
export function performanceManagementSVG() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 20.1667C18.0501 20.1667 21.3334 16.8834 21.3334 12.8333C21.3334 8.78325 18.0501 5.5 14 5.5C9.94993 5.5 6.66669 8.78325 6.66669 12.8333C6.66669 16.8834 9.94993 20.1667 14 20.1667Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 38.5V34.8333C3 32.8884 3.77262 31.0232 5.14788 29.6479C6.52315 28.2726 8.38841 27.5 10.3333 27.5H17.6667C19.6116 27.5 21.4768 28.2726 22.8521 29.6479C24.2274 31.0232 25 32.8884 25 34.8333V38.5"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3333 20H25.6667C25.2985 20 25 20.3358 25 20.75V25.25C25 25.6642 25.2985 26 25.6667 26H28.3333C28.7015 26 29 25.6642 29 25.25V20.75C29 20.3358 28.7015 20 28.3333 20Z"
        stroke="#2C3E50"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1667 17H29.8333C29.3731 17 29 17.3358 29 17.75V25.25C29 25.6642 29.3731 26 29.8333 26H33.1667C33.6269 26 34 25.6642 34 25.25V17.75C34 17.3358 33.6269 17 33.1667 17Z"
        stroke="#2C3E50"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3333 14H34.6667C34.2985 14 34 14.3358 34 14.75V25.25C34 25.6642 34.2985 26 34.6667 26H37.3333C37.7015 26 38 25.6642 38 25.25V14.75C38 14.3358 37.7015 14 37.3333 14Z"
        stroke="#2C3E50"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function timeAndAttendanceSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-clock"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="9" />
      <polyline points="12 7 12 12 15 15" />
    </svg>
  );
}
export function absenceAndLeaveSVG() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 20.1667C18.0501 20.1667 21.3334 16.8834 21.3334 12.8333C21.3334 8.78325 18.0501 5.5 14 5.5C9.94993 5.5 6.66669 8.78325 6.66669 12.8333C6.66669 16.8834 9.94993 20.1667 14 20.1667Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 38.5V34.8333C3 32.8884 3.77262 31.0232 5.14788 29.6479C6.52315 28.2726 8.38841 27.5 10.3333 27.5H17.6667C19.6116 27.5 21.4768 28.2726 22.8521 29.6479C24.2274 31.0232 25 32.8884 25 34.8333V38.5"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5 14H27.5C26.6716 14 26 14.6716 26 15.5V24.5C26 25.3284 26.6716 26 27.5 26H36.5C37.3284 26 38 25.3284 38 24.5V15.5C38 14.6716 37.3284 14 36.5 14Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 19L31 22M31 19L34 22L31 19Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function trainingAndDevelopmentSVG() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 20.1667C18.0501 20.1667 21.3334 16.8834 21.3334 12.8333C21.3334 8.78325 18.0501 5.5 14 5.5C9.94993 5.5 6.66669 8.78325 6.66669 12.8333C6.66669 16.8834 9.94993 20.1667 14 20.1667Z"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 38.5V34.8333C3 32.8884 3.77262 31.0232 5.14788 29.6479C6.52315 28.2726 8.38841 27.5 10.3333 27.5H17.6667C19.6116 27.5 21.4768 28.2726 22.8521 29.6479C24.2274 31.0232 25 32.8884 25 34.8333V38.5"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.625 15H26.375C25.6156 15 25 15.3918 25 15.875V21.125C25 21.6082 25.6156 22 26.375 22H34.625C35.3844 22 36 21.6082 36 21.125V15.875C36 15.3918 35.3844 15 34.625 15Z"
        stroke="#2C3E50"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.338 21.8095L28.7143 28"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5714 21.8095L32.6775 27.933"
        stroke="#2C3E50"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function payrollManagementSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-credit-card"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect x="3" y="5" width="18" height="14" rx="3" />
      <line x1="3" y1="10" x2="21" y2="10" />
      <line x1="7" y1="15" x2="7.01" y2="15" />
      <line x1="11" y1="15" x2="13" y2="15" />
    </svg>
  );
}
export function messagesSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-message-2"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M12 20l-3 -3h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-2l-3 3" />
      <line x1="8" y1="9" x2="16" y2="9" />
      <line x1="8" y1="13" x2="14" y2="13" />
    </svg>
  );
}
export function settingsSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-settings"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
      <circle cx="12" cy="12" r="3" />
    </svg>
  );
}
export function reportsSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-tax"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="9" y1="14" x2="15" y2="8" />
      <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />
      <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />
      <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
    </svg>
  );
}
export function toggleSVG() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.16116"
        y="0.539848"
        width="28"
        height="3"
        rx="1.5"
        transform="rotate(45 2.16116 0.539848)"
        fill="#047B40"
      ></rect>
      <rect
        x="0.039856"
        y="20.3388"
        width="28"
        height="3"
        rx="1.5"
        transform="rotate(-45 0.039856 20.3388)"
        fill="#047B40"
      ></rect>
    </svg>
  );
}

export function notificationSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-bell"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
      <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
    </svg>
  );
}
export function downloadSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-file-download"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#5f6368"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
      <line x1="12" y1="11" x2="12" y2="17" />
      <polyline points="9 14 12 17 15 14" />
    </svg>
  );
}
export function shareSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-share"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#5f6368"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="6" cy="12" r="3" />
      <circle cx="18" cy="6" r="3" />
      <circle cx="18" cy="18" r="3" />
      <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />
      <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
    </svg>
  );
}

export function gridSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-grid"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect x="4" y="4" width="6" height="6" rx="1" />
      <rect x="14" y="4" width="6" height="6" rx="1" />
      <rect x="4" y="14" width="6" height="6" rx="1" />
      <rect x="14" y="14" width="6" height="6" rx="1" />
    </svg>
  );
}

export function employeeSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-friends"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="7" cy="5" r="2" />
      <path d="M5 22v-5l-1-1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
      <circle cx="17" cy="5" r="2" />
      <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
    </svg>
  );
}

export function attendanceSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-id"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect x="3" y="4" width="18" height="16" rx="3" />
      <circle cx="9" cy="10" r="2" />
      <line x1="15" y1="8" x2="17" y2="8" />
      <line x1="15" y1="12" x2="17" y2="12" />
      <line x1="7" y1="16" x2="17" y2="16" />
    </svg>
  );
}

export function expensesSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-cash"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect x="7" y="9" width="14" height="10" rx="2" />
      <circle cx="14" cy="14" r="2" />
      <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
    </svg>
  );
}

export function arrowsSVG(clickable=false, eventObj) {
  return (
    <div className="nxt">
      <svg onClick={eventObj ? () => eventObj.nextClick("prev") : null}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-chevron-left"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        strokeWidth="3"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <polyline points="15 6 9 12 15 18" />
      </svg>

      <svg onClick={eventObj ? () => eventObj.nextClick("next") : null}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-chevron-right"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        strokeWidth="3"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <polyline points="9 6 15 12 9 18" />
      </svg>
    </div>
  );
}

export const refreshSvg=()=>{
  return (
    <>
    <svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="512" viewBox="0 0 515.556 515.556" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m418.889 290c0 88.832-72.28 161.111-161.111 161.111s-161.111-72.279-161.111-161.111c0-77.798 55.445-142.882 128.889-157.855v61.188l96.667-96.667-96.667-96.666v67.017c-109.124 15.718-193.334 109.578-193.334 222.983 0 124.373 101.182 225.556 225.556 225.556s225.556-101.182 225.556-225.556z"/></svg>
  </>
  )
}
