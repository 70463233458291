import { GET_ALL_COUNTRIES, GET_ALL_STATES, GET_ERRORS } from "./types"
import axios from "axios"
import process from "../env"

const service_url = process.env.SERVICE_URL


export const getAllCountries = () => {
    return async(dispatch) => {
        try {
            const res = await axios.get(`${service_url}get-countries`)
            dispatch({
                type: GET_ALL_COUNTRIES,
                payload: res.data.returnData
            })
        } catch (error) {
            dispatch({
                type: GET_ERRORS
            })
        }
    }
}


export const getAllStatesInCountry = (id) => {
    return async(dispatch) => {
        try {
            const res = await axios.get(`${service_url}get-states/${id}`)
            dispatch({
                type: GET_ALL_STATES,
                payload: res.data.returnData
            })
        } catch (error) {
            dispatch({
                type: GET_ERRORS
            })
        }
    }
}