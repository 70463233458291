import { DELETE_PAYSTACK_ACCOUNT, GET_PAYSTACK_ACCOUNT, PAYSTACK_ACCOUNT_LOADING_STARTS, PAYSTACK_ACCOUNT_LOADING_ENDS } from "../../actions/types"

const INITIAL_STATE = {
    paystack: undefined,
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PAYSTACK_ACCOUNT:
            return {
                ...state,
                paystack: action.payload
            }
        case DELETE_PAYSTACK_ACCOUNT:
            return {
                ...state,
                paystack: state.paystack.filter(item => item.id !== action.payload)
            }
        case PAYSTACK_ACCOUNT_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case PAYSTACK_ACCOUNT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}