import { DELETE_LEAVE_REQUEST, GET_ALL_LEAVE_REQUESTS } from "../../actions/types"

const INITIAL_STATE = {
    leaveRequests: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LEAVE_REQUESTS:
            return {
                ...state,
                leaveRequests: action.payload
            }
        case DELETE_LEAVE_REQUEST:
            return {
                ...state,
                leaveRequests: state.leaveRequests.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}