import { trainingTypes } from "../../actions/types";

let initialState = {
  data: [],
  loading: false,
  errors: false,
};

export const trainingDevelopmentEligible = (state = initialState, action) => {
  switch (action.type) {
    case trainingTypes.GET_TRAINING_DEVELOPMENT_ELIGIBLE_BEGINS:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case trainingTypes.GET_TRAINING_DEVELOPMENT_ELIGIBLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case trainingTypes.GET_TRAINING_DEVELOPMENT_ELIGIBLE_FAILED:
      return {
        ...state,
        loading: false,
        errors: true,
      };

    default:
      return state;
  }
};
