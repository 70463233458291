import React, { useEffect, useState } from "react";
import navitems from "./Reusables/NonComponent/navitems";
import NavItem from "./Reusables/NavItem";
import EmployeeNav from "./Reusables/NonComponent/Routes/employeeRoutes";
import { toggleSVG } from "./Reusables/NonComponent/svgs";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { VERIFY_USER_ROLE } from "../actions/types";
import { SidebarLazyLoads } from "./Reusables/LazyLoads";
import { $ } from "react-jquery-plugin"
import usePermittedRoutes from "../pages/hooks/usePermittedRoutes";

const Sidebar = (props) => {
  const { isAuthenticated, loading } = props.auth;
  const [activeLink, setActiveLink] = useState(null);
  const permittedRoutes = usePermittedRoutes()
console.log(permittedRoutes, 'LOGS')
  const mappedRoutes = navitems.map((item)=>{
    return (
        {
            ...item,
            permitted: permittedRoutes.find(( i => i.path == item.path))?.permitted
           
 
        }
    )
 })



  const handleActiveLink = (id) => {
    setActiveLink(id);
  };

  const toggleSideBar = () => {
    if ($("body").hasClass("sidebar-folded")) {
      $("body").removeClass("sidebar-folded");
      $(".sidebar-toggler").removeClass('is-active')
    } else if(!$("body").hasClass("sidebar-folded")) {
      $("body").addClass("sidebar-folded");
      $(".sidebar-toggler").addClass('is-active')
    }
  }

  const toggleSideBarMobile = () => {
    if ($("body").hasClass("sidebar-open")) {
      $("body").removeClass("sidebar-open");
      $(".mobile").removeClass('is-active')
    } else if(!$("body").hasClass("sidebar-open")) {
      $("body").addClass("sidebar-open");
      $(".mobile").addClass('is-active')
    }
  }

  useEffect(() => {
    props.dispatch({ type: VERIFY_USER_ROLE });
  }, []);

  /**
   *
   * @returns the routes links to be displayed on the sidebar
   * based on the logged in user role
   */
  const verifyUserRole = () => {
    return mappedRoutes.filter((item)=> item.permitted).map((item, index) => (
      <NavItem
        key={index}
        Svg={item?.svg}
        name={item.name}
        path={item.path}
        handleClick={() => handleActiveLink(item.id)}
        active={activeLink === item.id}
      />
    ));
  };

    let renderable;
    if (isAuthenticated) {
      renderable = (
        <nav className="sidebar">
          <div className="sidebar-header">
            <div onClick={toggleSideBar} className="sidebar-toggler">
              <div className="sidebar-toggler-box">
                <div className="sidebar-toggler-icon"></div>
              </div>
            </div>
            <div className="sidebar-logo">
              <Link to="/" className="sidebar-brand">
                <img src={Logo} alt="BMAC" />
              </Link>
            </div>
            <button onClick={toggleSideBarMobile} id="nav-toggler-close" className="nav-toggler-close">
              {toggleSVG()}
            </button>
          </div>
          <button id="nav-toggler-close" className="nav-toggler-close">
            {toggleSVG()}
          </button>
        <div className="sidebar-body">
          <ul className="nav">
            {loading ? <SidebarLazyLoads /> : verifyUserRole()}
            {/* {_NavItem} */}
            {/* {verifyUserRole()} */}
          </ul>
        </div>
      </nav>
    );
  } else {
    renderable = (
      <nav className="sidebar">
        <div className="sidebar-header">
          <div className="sidebar-toggler">
            <div className="sidebar-toggler-box">
              <div className="sidebar-toggler-icon"></div>
            </div>
            <div className="sidebar-logo">
              <Link to="index.html" className="sidebar-brand">
                <img src={Logo} alt="BMAC" />
              </Link>
            </div>
            <button onClick={toggleSideBarMobile} id="nav-toggler-close" className="nav-toggler-close">
              {toggleSVG()}
            </button>
          </div>
          <div className="sidebar-logo">
            <Link to="index.html" className="sidebar-brand">
              <img src={Logo} alt="BMAC" />
            </Link>
          </div>
          <button id="nav-toggler-close" className="nav-toggler-close">
            {toggleSVG()}
          </button>
        </div>
      </nav>
    );
  }

  return <div>{renderable}</div>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Sidebar);
