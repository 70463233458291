import { DELETE_ACCESS_CONTROL, GET_ALL_ACCESS_CONTROL } from "../../actions/types"

const INITIAL_STATE = {
    accessControls: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ACCESS_CONTROL:
            return {
                ...state,
                accessControls: action.payload
            }
        case DELETE_ACCESS_CONTROL:
            return {
                ...state,
                accessControls: state.accessControls.filter(item => item.id !== action.payload)
            }
        default:
            return state
    }
}