import { GET_ALL_COUNTRIES, GET_ALL_STATES } from "../actions/types"

const initialState = {
    countries: [],
    states: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }
        case GET_ALL_STATES:
            return {
                ...state,
                states: action.payload
            }
        default:
            return state
    }
}