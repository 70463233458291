import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import isLoadingReducer from "./isLoadingReducer";
import profileReducer from "./profileReducer";
import companyReducer from "./companyReducer";
import netWorkResponse from "./networkResponse";
import employeesReducer from "./Module-Employee/employeesReducer";
import departmentReducer from "./Module-Settings/departmentReducers";
import gradeReducer from "./Module-Settings/gradeReducer";
import unitReducer from "./Module-Settings/unitReducer";
import accessControlReducer from "./Module-Settings/accessControlReducer";
import leaveCategoryReducer from "./Module-Settings/leaveCategoryReducer";
import trainingReducer from "./Module-Settings/trainingReducer";
import taxReducer from "./Module-Settings/taxReducer";
import otherDeductionsReducer from "./Module-Settings/otherDeductionsReducer";

import attendanceReducer from "./Module-Settings/attendanceReducer";
import notificationReducer from "./Module-Settings/notificationReducer";
import absenceAndLeaveReducer from "./Module-Settings/absenceAndLeaveReducer";
import staffRequisitionReducer from "./Module-Recruitment/staffRequisitionReducer";
import jobApplicationReducer from "./Module-Recruitment/jobApplicationReducer";
import deploymentReducer from "./Module-Recruitment/deploymentReducer";
import performanceManagementReducer from "./Module-PerformanceManagement/performanceManagementReducer";
import dashboardReducer from "./Dashboard/dashboardReducer";
import payrollReducer from "./Module-PayRoll/payrollReducer";
import { overTimeReducer } from "./timeAndAttendance/overTime";
import { timeSheetReducer } from "./timeAndAttendance/timeSheet";
import { trainingRequestReducer } from "./Module-Training/trainingRequest";
import { trainingScoreCardReducer } from "./Module-Training/trainingScoreCard";
import { trainingDevelopmentEligible } from "./Module-Training/trainingEligibility";
import { getTrainingsReducer } from "./Module-Training/getAllTrainings";
import messageReducer from "./Module-Message/messageReducer";
import chatReducer from "./Chat/chatReducer";
import countriesReducers from "./countriesReducers";
import commentReducer from "./Module-Recruitment/commentReducer";
import modalReducer from "./modalReducer";
import generalReportsReducer from "./Module-Reports/generalReportsReducer";
import managementReportReducer from "./Module-Reports/managementReportReducer";
import leaveAndAttendanceReducer from "./Module-Reports/leaveAndAttendanceReducer";
import awardReportReducer from "./Module-Reports/awardReportReducer";
import assetReducer from "./Module-Settings/assetReducer";
import assetCategoriesReducer from "./Module-Settings/assetCategoriesReducer";
import awardsReducer from "./Module-Settings/awardsReducer";
import designationReducer from "./Module-Settings/designationReducer";
import payrollReportReducer from "./Module-Reports/payrollReportReducer";
import attendanceReportReducer from "./Module-Reports/attendanceReportReducer";
import attendanceList from "./timeAndAttendance/attendanceList";
import apiStatusCodeReducer from "./apiStatusCodeReducer";
import recruitmentReportsReducer from "./Module-Reports/recruitmentReportsReducer";
import trainingReportReducer from "./Module-Reports/trainingReportReducer";
import auditTrailReportReducer from "./Module-Reports/auditTrailReportReducer";
import dataFromDepartments from "./dataFromDepartments";
import employeeDocumentsReducer from "./Module-Employee/employeeDocumentsReducer";
import loanReducer from "./Module-Loan/loanReducer";
import approvalTypes from "./Module-Settings/approvalTypes";
import officeReducer from "./Module-Settings/officeReducer";
import processReducer from "./Module-Settings/processReducer";
import approvalWorkflow from "./Module-Settings/approvalWorkflow";
import processAuth from "./Module-Settings/processAuth";
import processFlowAuth from './Module-Settings/processFlow'
import loanApprovalReducer from './Module-LoanApproval'
import leaveApprovalReducer from './Module-leaveApproval'
import payslipApprovalReducer from './Module-PayslipApproval'
import paystackAccountReducer from "./Module-Settings/paystackAccountReducer";
import paystackDedicatedAccountReducer from "./Module-Settings/paystackDedicatedAccountReducer";
import monthlyPayrollReducer from "./Module-PayRoll/monthlyPayrollReducer";
import singleEmployeePayrollReducer from "./Module-PayRoll/singleEmployeePayrollReducer";

export default combineReducers({
  apiStatusCode: apiStatusCodeReducer,
  auth: authReducer,
  errors: errorReducer,
  isLoading: isLoadingReducer,
  currentUserProfile: profileReducer,
  currentUserCompany: companyReducer,
  networkResponse: netWorkResponse,
  employees: employeesReducer,
  departments: departmentReducer,
  grades: gradeReducer,
  paystack: paystackAccountReducer,
  dedicated: paystackDedicatedAccountReducer,

  units: unitReducer,
  accessControls: accessControlReducer,
  leaveCategories: leaveCategoryReducer,
  settings_trainings: trainingReducer,
  taxes: taxReducer,
  deductions:otherDeductionsReducer,
  workdays: attendanceReducer,
  notification: notificationReducer,
  leaveRequests: absenceAndLeaveReducer,
  overTime: overTimeReducer,
  timeSheetAndAttendance: timeSheetReducer,
  staffRequisitions: staffRequisitionReducer,
  jobApplications: jobApplicationReducer,
  deployments: deploymentReducer,
  kpis: performanceManagementReducer,
  dashboard: dashboardReducer,
  payroll: payrollReducer,
  monthlypayroll: monthlyPayrollReducer,
  singleEmployeePayroll:singleEmployeePayrollReducer,
  trainings: getTrainingsReducer,
  departments: departmentReducer,
  trainingRequest: trainingRequestReducer,
  trainingScoreCard: trainingScoreCardReducer,
  trainingEligible: trainingDevelopmentEligible,
  messages: messageReducer,
  chat: chatReducer,
  location: countriesReducers,
  jobApplicationComments: commentReducer,
  modals: modalReducer,
  generalReports: generalReportsReducer,
  managementReports: managementReportReducer,
  leaveAndAttendanceReports: leaveAndAttendanceReducer,
  awardReports: awardReportReducer,
  awards: awardsReducer,
  assets: assetReducer,
  assetsCategories: assetCategoriesReducer,
  designations: designationReducer,
  payrollReport: payrollReportReducer,
  recruitmentReport: recruitmentReportsReducer,
  attendanceReport: attendanceReportReducer,
  employeeAttendance: attendanceList,
  trainingReport: trainingReportReducer,
  auditTrailReport: auditTrailReportReducer,
  dataInDepartments: dataFromDepartments,
  employeeDocuments: employeeDocumentsReducer,
  loans: loanReducer,
  approval: approvalTypes,
  offices: officeReducer,
  process: processReducer,
  approvalWorkflow: approvalWorkflow,
  processAuth: processAuth,
  processFlow:processFlowAuth,
  loanApproval:loanApprovalReducer,
  leaveApproval:leaveApprovalReducer,
  payslipApproval:payslipApprovalReducer

});
