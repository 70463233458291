import { GET_PAYROLL_REPORT, PAYROLL_REPORT_LOADING_STARTS, PAYROLL_REPORT_LOADING_ENDS } from "../../actions/types"

const INITIAL_STATE = {
    payrollReports: undefined,
    loading: false 
}


export default (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PAYROLL_REPORT:
            return {
                ...state,
                payrollReports: action.payload
            }
        case PAYROLL_REPORT_LOADING_STARTS: 
            return {
                ...state,
                loading: true
            }
        case PAYROLL_REPORT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}