import {
  dashboardSVG,
  recruitmentSVG,
  employeeManagementSVG,
  performanceManagementSVG,
  timeAndAttendanceSVG,
  absenceAndLeaveSVG,
  trainingAndDevelopmentSVG,
  payrollManagementSVG,
  messagesSVG,
  settingsSVG,
  reportsSVG,
} from "./svgs";
import CONSTANTS from "../../../common/constants.json";
const navitems = [
  {
    svg: dashboardSVG,
    name: "Dashboard",
    path: CONSTANTS.ROUTES.DASHBOARD,
    id: 1,
  },
  {
    svg: recruitmentSVG,
    name: "Recruitment",
    path: CONSTANTS.ROUTES.RECRUITMENT,
    id: 2,
  },
  {
    svg: employeeManagementSVG,
    name: "Employee Mangement",
    path: CONSTANTS.ROUTES.EMPLOYEE,
    id: 3,
  },
  {
    svg: performanceManagementSVG,
    name: "Performance Management",
    path: CONSTANTS.ROUTES.PERFORMANCE,
    id: 4,
  },
  {
    svg: timeAndAttendanceSVG,
    name: "Time and Attendance",
    path: CONSTANTS.ROUTES.TIME_AND_ATTENDANCE,
    id: 5,
  },
  {
    svg: absenceAndLeaveSVG,
    name: "Absence and Leave",
    path: CONSTANTS.ROUTES.ABSENCE_AND_LEAVE,
    id: 6,
  },
  {
    svg: trainingAndDevelopmentSVG,
    name: "Training and Development",
    path: CONSTANTS.ROUTES.TRAINING_AND_DEVELOPMENT,
    id: 7,
  },
  {
    svg: payrollManagementSVG,
    name: "Payroll Management",
    path: CONSTANTS.ROUTES.PAYROLL_MANAGEMENT,
    id: 8,
  },
  {
    svg: messagesSVG,
    name: "Message",
    path: CONSTANTS.ROUTES.MESSAGE,
    id: 9,
  },
  {
    svg: reportsSVG,
    name: "Reports",
    path: CONSTANTS.ROUTES.REPORTS,
    id: 11,
  },

  // Employee view salary 11052022
  {
    svg: reportsSVG,
    name: "Approvals",
    path: CONSTANTS.ROUTES.APPROVAL_LIST,
    id: 12,
  },

  {
    svg: reportsSVG,
    name: "My loans",
    path: CONSTANTS.ROUTES.EMPLOYEE_LOAN,
    id: 14,
  },
  

  {
    svg: settingsSVG,
    name: "Settings",
    path: CONSTANTS.ROUTES.SETTINGS,
    id: 13,
  },
  



];
export default navitems;
