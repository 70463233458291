import axios from "axios"
import jwt_decode from "jwt-decode";
import CONSTANTS from "../../common/constants.json"
import process from "../../env"
import { notify } from "../../utils/notification"
import { clearNetworkResponse } from "../Network-Actions/networkActions"
import {
    GET_ALL_JOB_APPLICATIONS,
    GET_JOB_APPLICATION,
    GET_STATUS_CODE,
    GET_JOB_APPLICATIONS_BY_TYPE,
    DELETE_JOB_APPLICATION,
    GET_ERRORS,
    IS_LOADING,
    NOT_LOADING,
    REDIRECT_TO,
    SHOW_NETWORK_RESPONSE,
    FETCH_ERROR,
    GET_ALL_JOB_APPLICATIONS_COMMENTS,
    JOB_APPLICATION_LOADER_STARTS,
    JOB_APPLICATION_LOADER_ENDS,
    COMMENT_LOADING_STARTS,
    COMMENT_LOADING_ENDS,
    JOB_APPLICATION_CRUD_STARTS,
    JOB_APPLICATION_CRUD_ENDS
} from "../types"

const service_url = process.env.SERVICE_URL

export const getAllJobApplications = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: JOB_APPLICATION_LOADER_STARTS })
            const res = await axios.get(`${service_url}get-all-job-applications`);
            dispatch({
                type: GET_ALL_JOB_APPLICATIONS,
                payload: res.data.returnData
            });
            dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
        } catch (error) {
            dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
            dispatch({
                type: FETCH_ERROR
            })
            if(error.response.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            }
        }
    }
}


export const getAllJobApplicationsLoggedInEmployee = () => {
    return async (dispatch) => {
        const { sub } = jwt_decode(localStorage.jwtToken);
        try {
            dispatch({ type: JOB_APPLICATION_LOADER_STARTS })
            const res = await axios.get(`${service_url}get-internal-application-employee/${sub}`);
            dispatch({
                type: GET_ALL_JOB_APPLICATIONS,
                payload: res.data.returnData
            });
            dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
        } catch (error) {
            dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
            dispatch({
                type: FETCH_ERROR
            })
            if(error.response.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            }
        }
    }
}


export const approveJobApplication = (id, action) => {
    return async(dispatch) => {
        try {
            dispatch({ type: JOB_APPLICATION_LOADER_STARTS })
            const request = await axios.get(`${service_url}approve-job-application/${id}/${action}`)
            if(request.status === 200){
                dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
                getAllJobApplications()(dispatch)
                return notify(`Job application ${action} successfully`, 'success')
            }
        } catch (error) {
            dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
            dispatch({ type: FETCH_ERROR })
            if(error.response.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            } else if(error.response.status === 404) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            }
        }
    }
}




export const getJobApplication = (id) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`${service_url}get-job-application/${id}`)
            dispatch({
                type: GET_JOB_APPLICATION,
                payload: res.data.returnData
            })
        } catch (error) {
            dispatch({ type: FETCH_ERROR })
            if(error.response.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            } else if(error.response.status === 404) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            }
        }
    }
}

export const getJobApplicationComments = (id) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`${service_url}get-comments-on-job-application/${id}`)
            dispatch({
                type: GET_ALL_JOB_APPLICATIONS_COMMENTS,
                payload: res.data.returnData.job_comments
            })
        } catch (error) {
            dispatch({
                type: FETCH_ERROR
            })
            if(error.response.status === 500) {
                dispatch({
                    type: GET_STATUS_CODE,
                    payload: error.response
                })
            }
        }
    }
}


export const addJobApplicatonComment = (data, id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: COMMENT_LOADING_STARTS })
            const request = await axios.post(`${service_url}save-job-comment`, data)
            dispatch({
                type: COMMENT_LOADING_ENDS,
            });
            getJobApplicationComments(id)(dispatch)
            return notify(request.data.returnMessage, 'success')
        } catch (err) {
            dispatch({
                type: COMMENT_LOADING_ENDS,
            });
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                }else if(err.response.status === 500){
                    dispatch({
                        type: GET_STATUS_CODE,
                        payload: err.response
                    })
                } else {
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
}


export const getJobApplicationsByType = () => {
    return async (dispatch) => {

        dispatch({
            type: IS_LOADING
        });

        const res = await axios.get(`${service_url}get-job-application-by-type/external`);

        dispatch({
            type: GET_JOB_APPLICATIONS_BY_TYPE,
            payload: res.data.returnData
        });

        dispatch({
            type: NOT_LOADING,
        });
    }
}

export const addInternalJobApplication = (data) => {
    return (dispatch) => {
        dispatch({type:JOB_APPLICATION_CRUD_STARTS})
        try {
            const url = "save-new-job-application"
            const request = axios.post(`${service_url}${url}`, data)
            if(request.status === 200) { 
                getAllJobApplications()(dispatch)
                dispatch({type:JOB_APPLICATION_CRUD_ENDS})
                dispatch({
                    type: REDIRECT_TO,
                    payload: CONSTANTS.ROUTES.RECRUITMENT
                });
                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: request
                })
                dispatch(clearNetworkResponse())
            }
        } catch (err) {
                dispatch({type:JOB_APPLICATION_CRUD_ENDS})
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else if(err.response.status === 500) {
                    dispatch({
                        type: GET_STATUS_CODE,
                        payload: err.response
                    })
                } else {
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
}

export const updateInternalJobApplication = (data, id) => {
    return (dispatch) => {
        dispatch({ type: JOB_APPLICATION_LOADER_STARTS })
        try {
            const url = "update-job-application"
            const request = axios.patch(`${service_url}${url}/${id}`, data)
            if(request.status === 200) { 
                getAllJobApplications()(dispatch)
                dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
                dispatch({
                    type: REDIRECT_TO,
                    payload: CONSTANTS.ROUTES.RECRUITMENT
                });
                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: request
                })
                dispatch(clearNetworkResponse())
            }
        } catch (err) {
            if (err.response) {
                dispatch({ type: JOB_APPLICATION_LOADER_ENDS })
                if (err.response.status === 422) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else if(err.response.status === 500) {
                    dispatch({
                        type: GET_STATUS_CODE,
                        payload: err.response
                    })
                } else {
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
}

export const addExternalJobApplication = (data) => {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING
        })

        const sendDataUrl = axios.post(`${service_url}save-new-job-application`, data)
        const getNewdataUrl = axios.get(`${service_url}get-all-job-applications`);

        axios.all([sendDataUrl, getNewdataUrl]).then(axios.spread((...responses) => {
            if (responses[0].status === 200 && responses[1].status === 200) {

                dispatch({
                    type: GET_ALL_JOB_APPLICATIONS,
                    payload: responses[1].data.returnData
                });

                dispatch({
                    type: NOT_LOADING,
                });

                dispatch({
                    type: REDIRECT_TO,
                    payload: CONSTANTS.ROUTES.RECRUITMENT
                });

                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: responses[0]
                })

                dispatch(clearNetworkResponse())
            }
        })).catch((err) => {
            if (err.response) {

                if (err.response.status === 422) {

                    dispatch({
                        type: NOT_LOADING,
                    });

                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });

                    return notify('There were some errors in your input', 'error')

                } else {

                    dispatch({
                        type: NOT_LOADING,
                    });

                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {

                dispatch({
                    type: NOT_LOADING,
                });

                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        });
    }
}

export const updateJobApplication = (data, id) => {
    return (dispatch) => {

        dispatch({
            type: IS_LOADING
        });

        const sendDataUrl = axios.patch(`${service_url}update-job-application/${id}`, data)
        const getNewdataUrl = axios.get(`${service_url}get-all-job-applications`);

        axios.all([sendDataUrl, getNewdataUrl]).then(axios.spread((...responses) => {
            if (responses[0].status === 200 && responses[1].status === 200) {

                dispatch({
                    type: NOT_LOADING,
                });

                dispatch({
                    type: GET_ALL_JOB_APPLICATIONS,
                    payload: responses[1].data.returnData
                });

                dispatch({
                    type: REDIRECT_TO,
                    payload: CONSTANTS.ROUTES.RECRUITMENT
                })
                dispatch(clearNetworkResponse())
                return notify('Job application updated successfully', 'success')
            }
        })).catch((err) => {
            console.log(err)
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                dispatch({
                    type: NOT_LOADING,
                });
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        });
    }
}

export const deleteJobApplication = (id) => {
    return (dispatch) => {
        axios.delete(`${service_url}delete-job-application/${id}`).then(res => {
            dispatch({
                type: DELETE_JOB_APPLICATION,
                payload: id
            })
            return notify('Job application deleted successfully', 'success')
        }).catch((err) => {
            if (err.response) {
                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: err.response.data.returnMessage
                })
                dispatch(clearNetworkResponse())
            } else {
                return notify('Sorry, something went wrong! Most likely your network,', 'error')
            }
        });
    }
}

export const deleteInternalJobApplication = (id) => {
    return (dispatch) => {
        axios.delete(`${service_url}delete-internal-application/${id}`).then(res => {
            dispatch({
                type: DELETE_JOB_APPLICATION,
                payload: id
            })
            dispatch({
                type: SHOW_NETWORK_RESPONSE,
                payload: res
            })
            dispatch(clearNetworkResponse())
        }).catch((err) => {
            if (err.response) {
                dispatch({
                    type: SHOW_NETWORK_RESPONSE,
                    payload: err.response.data.returnMessage
                })
                dispatch(clearNetworkResponse())
            } else {
                return notify('Sorry, something went wrong! Most likely your network,', 'error')
            }
        });
    }
}

// Job Invitation
export const addJobInvitation = (data) => {
    return async(dispatch) => {
        try {
            dispatch({ type: IS_LOADING })
            const request = await axios.post(`${service_url}save-job-invitation`, data)
            dispatch({
                type: NOT_LOADING,
            });
            return notify(request.data.returnMessage, 'success')
        } catch (err) {
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                dispatch({
                    type: NOT_LOADING,
                });
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
}


// Job offer
export const addJobOffer = (data) => {
    return async(dispatch) => {
        try {
            dispatch({ type: IS_LOADING })
            const request = await axios.post(`${service_url}save-job-offer`, data)
            dispatch({
                type: NOT_LOADING,
            });
            return notify(request.data.returnMessage, 'success')
        } catch (err) {
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                dispatch({
                    type: NOT_LOADING,
                });
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
}

// Add Assessment 
export const addAssessment = (data) => {
    return async(dispatch) => {
        try {
            dispatch({ type: IS_LOADING })
            const request = await axios.post(`${service_url}save-job-assessment`, data)
            dispatch({
                type: NOT_LOADING,
            });
            return notify(request.data.returnMessage, 'success')
        } catch (err) {
            if (err.response) {
                if (err.response.status === 422) {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    dispatch({
                        type: GET_ERRORS,
                        payload: err,
                    });
                    return notify('There were some errors in your input', 'error')
                } else {
                    dispatch({
                        type: NOT_LOADING,
                    });
                    return notify('Sorry, something went wrong!', 'error')
                }
            } else {
                dispatch({
                    type: NOT_LOADING,
                });
                return notify('Sorry, something went wrong! Most likely your network', 'error')
            }
        }
    }
}


export const searchJobApplication = (query) => {
    return async (dispatch) => {

        try {
            dispatch({
                type: IS_LOADING
            });
    
            const res = await axios.get(`${service_url} ${query}get-all-job-applications`);
            dispatch({
                type: GET_ALL_JOB_APPLICATIONS,
                payload: res.data.returnData
            });
    
            dispatch({
                type: NOT_LOADING,
            });
        } catch (error) {
            dispatch({
                type: FETCH_ERROR
            })
        }
    }
}