import React, { Component } from "react";
import axios from "axios"
import { connect } from "react-redux";
import Comment from '../../Components/Recruitment/NestedComponents/Comment'
// import ViewJobOfferAsEmployee from '../../Components/Recruitment/NestedComponents/ViewJobOfferAsEmployee'
import { ToastContainer } from "react-toastify";
import InviteForJob from '../../Components/Recruitment/NestedComponents/InviteForJob'
// import Assessment from './NestedComponents/Assessment'
import 'react-toastify/dist/ReactToastify.css'
import isEmpty from "../../utils/isEmpty";
import process from "../../env"
import { getJobApplication } from "../../actions/Module-Recruitment/jobApplicationActions";
import { FormLazyLoad } from "../Reusables/LazyLoads"
import { getErrorStatusCode } from "../../errorHandlers/errorDispatcher"


const service_url = process.env.SERVICE_URL;

class EmployeeJobApplicationComments extends Component {
    constructor() {
        super();
        this.state = {};
    };

    componentDidMount() {
        axios.get(`${service_url}get-job-application/${this.props.match.params.id}`).then(res => {
            this.setState(res.data.returnData)
        }).catch(err => this.props.getErrorStatusCode(err.response))
    }


    render() {
        return isEmpty(this.state) ? (<FormLazyLoad/>) : (
        <div className="inner-page">
            <div className="my-card">
            <ToastContainer />
            <div className="my-card-header flex">
                <div>Application Details for John Doe</div>
                <div className="right">
                <a href="" className="button secondary mr-3">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-eye"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="2" />
                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                    </svg>
                    View CV
                    </a>

                <a href="" className="button secondary mr-3">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-printer"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
                    <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
                    <rect x="7" y="13" width="10" height="8" rx="2" />
                    </svg>
                    Print
                    </a>
                </div>
            </div>
            <div className="my-card-body">
                <div className="applicant-details">
                <div className="detail">
                    <div className="lbl">Type:</div>
                    <div className="vlv">{this.state.application_type.toUpperCase()}</div>
                </div>
                <div className="detail">
                    <div className="lbl">Name:</div>
                    <div className="vlv">{this.state.applicants ? `${this.state?.applicants.first_name} ${this.state?.applicants.surname}`: `${this.state?.first_name} ${this.state?.last_name}`}</div>
                </div>
                <div className="detail">
                    <div className="lbl">Job Title:</div>
                    <div className="vlv">{this.state.job_title.job_title}</div>
                </div>
                <div className="detail">
                    <div className="lbl">Years of Experience:</div>
                    <div className="vlv">{this.state.years_in_role}</div>
                </div>
                <div className="detail">
                    <div className="lbl">Email:</div>
                    <div className="vlv">{this.state.email ?? this.state.applicants.email}</div>
                </div>
                <div className="detail">
                    <div className="lbl">Phone No:</div>
                    <div className="vlv">{this.state.phone_number ?? this.state.applicants.phone}</div>
                </div>
                <div className="detail">
                    <div className="lbl">Location:</div>
                    <div className="vlv">{this.state.state_id.name}, {this.state.country_id.name}</div>
                </div>
                </div>
                <div className="applicant-actions">
                {/* <a
                    type="button"
                    onClick={null}
                    className="button secondary"
                    data-toggle="modal"
                    data-target="#inviteForJob"
                >
                    Job Invitation
                    </a>
                <button
                    type="button"
                    onClick={null}
                    className="button secondary"
                    data-toggle="modal"
                    data-target="#viewOffer"
                >
                    Job Offer
                    </button> */}
                {/* <a
                    type="button"
                    onClick={null}
                    className="button primary"
                    data-toggle="modal"
                    data-target="#assessment"
                >
                    Assessment
                    </a> */}
                </div>

                <Comment 
                jobApplicationID={this.state.id}
                />
                {/* <ViewJobOfferAsEmployee
                id={this.props.match.params.id}
                toggleModal={null} /> */}
                <InviteForJob
                invitee={{
                    name: `${this.state.first_name} ${this.state.last_name}`,
                    job_title: this.state.job_title.job_title,
                    email: this.state.email,
                    location: `${this.state.state_id.name} ${this.state.country_id.name}`,
                    job_application_id: this.state.id
                }}
                toggleModal={null} />


            </div>
            </div>
        </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getJobApplication: id => dispatch(getJobApplication(id)),
        getErrorStatusCode: (err) => dispatch(getErrorStatusCode(err))
    };
};
const mapStateToProps = state => {
    return {
        errors: state.errors,
        isLoading: state.isLoading,
        networkResponse: state.networkResponse,
        jobApplications: state.jobApplications.jobApplication,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeJobApplicationComments);
