import React, { useState, useEffect, useCallback, memo } from 'react';
import { SpinnerGrow } from './loader/Loader';

export const SearchResources = (props) => {
    const { grabQuery, salaryId, loading = false, placeholder = "search..." } = props
    const [query, setQuery] = useState('')

    const handleChange = ((e) => {
        // setQuery(e.target.value)
        setQuery(e.target.value);

    })
    const handleSubmit = ((e) => {
        e.preventDefault()
        // if(!query) return;
        console.log(props, 'pop')
        props.actionToDispatch(query, salaryId)

    }
    )

    return (
        <>
            <div>
                <form onSubmit={handleSubmit} className="form-inline">
                    <div className="form-group">
                        <input
                            name="query"
                            value={query}
                            placeholder={placeholder}
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            disabled={loading.loading} />

                    </div>
                    {/* <button className="button primary mt-2" disabled={loading}>search</button> */}
                    <button disabled={loading.loading} className="button primary mt-2"> { loading.loading ? <SpinnerGrow/> : "Search"}</button>
                </form>
            </div>
        </>
    )
}


export default memo(SearchResources)