import {
  GET_EMPLOYEE_SUMMARY_LIST_REPORT,
  GET_ALL_DESIGNATION_LIST,
  GET_DEPARTMENT_REPORT,
} from "../../actions/types";

const initialState = {
  departmentReport: {},
  employeeSummaryReport: {},
  designationList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_REPORT:
      return {
        ...state,
        departmentReport: action.payload,
      };
    case GET_EMPLOYEE_SUMMARY_LIST_REPORT:
      return {
        ...state,
        employeeSummaryReport: action.payload,
      };
    case GET_ALL_DESIGNATION_LIST:
      return {
        ...state,
        designationList: action.payload,
      };
    default:
      return state;
  }
};
