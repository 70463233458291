import React from "react";
import "../App.css";
import Sidebar from "../Components/Sidebar";
import PrivateRoute from "../Components/PrivateRoute";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import CONSTANTS from "../common/constants.json";
import { connect } from "react-redux";
import { history } from "../utils/history";
import { useDispatch } from "react-redux";
import { getDashboardData } from "../actions/Dashboard/dashboardActions";
import EmployeeJobApplicationView from "../Components/EmployeeView/index";
import NotAuthorized from "../Components/Reusables/NotAuthorized/NotAuthorized";
import EmployeeProfileView from "../Components/EmployeeView/EmployeeProfile";
import usePermittedRoutes from "./hooks/usePermittedRoutes";
import ProtectedComponents from "../Components/ProtectedComponents";


const DashboardPage = (props) => {
  const dispatch = useDispatch();
  const permittedModules = usePermittedRoutes()

  React.useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  return (
    <React.Fragment>
      <Router history={history}>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <Navbar />
            <Sidebar />
            <div className="page-content">
                  <PrivateRoute
                    path={CONSTANTS.ROUTES.EMPLOYEE_JOB_APPLICATIONS}
                    component={EmployeeJobApplicationView}
                  />
                             
                  <PrivateRoute
                    exact 
                    strict
                    component={EmployeeProfileView}
                    path='/my-profile'
                  /> 

                  <ProtectedComponents  routes = {permittedModules}/>            
                
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth,
    isAdmin: state.auth.isAdmin,
    currentUserProfile: state.currentUserProfile,
    currentUserCompany: state.currentUserCompany,
  };
};

export default connect(mapStateToProps)(DashboardPage);
