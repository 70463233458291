import {
  GET_DEPARTMENT_RECRUITMENT_REPORT,
  FETCH_ERROR,
} from "../../actions/types";

const initialState = {
  departmentRecruitment: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_RECRUITMENT_REPORT:
      return {
        ...state,
        departmentRecruitment: action.payload,
      };
    default:
      return state;
  }
};
