import React from 'react'
import { withRouter } from 'react-router';
import "./NotAuthorized.css"
import { useSelector } from 'react-redux';




const Page403 = (props) => {
    const { loading } = useSelector(state => state.auth)
    return (
        <div className="inner-page d-flex">
            { loading ? (
                <div style={{flexDirection:"column", display: "flex"}}>
                    {/* <RectSpinner/> */}
                </div>
            ) : (
            <div className="my-card m-auto" style={{height: "80vh"}}>
                <div className="container mt-5">
                    <h2>OPPSSS!!!! Sorry...</h2>
                    <p className="mt-2">Sorry, your access is refused due to security reasons of our server and also our sensitive data.</p>
                    <p>Please go back to the previous page to continue browsing.</p>
                    <button onClick={props.history.goBack} className="btn btn-danger">Go back</button>
                </div>
            </div>
            )}
        </div>
    );
}

export default withRouter(Page403);