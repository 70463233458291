import { GET_ATTENDANCE_REPORT, ATTENDANCE_REPORT_LOADING_STARTS, ATTENDANCE_REPORT_LOADING_ENDS } from "../../actions/types"

const INITIAL_STATE = {
    attendanceReport: undefined,
    loading: false 
}


export default (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ATTENDANCE_REPORT:
            return {
                ...state,
                attendanceReport: action.payload
            }
        case ATTENDANCE_REPORT_LOADING_STARTS: 
            return {
                ...state,
                loading: true
            }
        case ATTENDANCE_REPORT_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}