import React from "react";

const InputData = ({
  inputTitle,
  inputError,
  storeInputs,
  name,
  pattern,
  inputType,
  title,
  required,
  ...otherProps
}) => {
  const rand = Math.floor(Math.random() * 20);

  return (
    <>
      <div className={"form-group col-md-12"}>
        <label htmlFor={`inputState${rand}`}>{inputTitle}</label>
        <input
          name={name ? name.toLowerCase() : null}
          type={inputType}
          pattern={pattern}
          className={`form-control ${inputError ? "is-invalid" : null}`}
          id={`inputState${rand}`}
          onChange={storeInputs}
          title={title}
          required ={required}
          {...otherProps}
        />
        <div className="invalid-feedback">{inputError}</div>
      </div>
    </>
  );
};

export default InputData;
