import { CLEAR_STATUS_CODE, GET_STATUS_CODE } from "../actions/types"

const initialState = {
    statusCode: undefined,
    errorPayload: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATUS_CODE:
            console.log("ERROR", action.payload)
            return {
                ...state,
                statusCode: action?.payload?.status,
                errorPayload: action?.payload?.data
            }
        case CLEAR_STATUS_CODE:
            return {
                ...state,
                statusCode: undefined,
                errorPayload: {}
            }
        default:
            return state
    }
}