import React from "react";

const Modal = ({
    msg,
    yes ='Yes, Delete',
    no='No, Cancel',
    imgSrc ='../../assets/images/deleteModal.svg',
    showModal,
    position,
    eventTrigger,
    closeModal,
    dismiss,
    modalShow,
    operationInProgress
}) => {
    return (
        <>
            <div
                className={`${modalShow ? "show":""} modal fade`}
                id="deleteConfirmationModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={closeModal}>&times;</span>
                            </button>
                            <img src={imgSrc}
                                className="image"
                                alt=""/>
                            <p>{msg}</p>
                            <div className="modal-buttons">
                                <button onClick={ closeModal ? () => closeModal() : null} type="button" className="button secondary" data-dismiss="modal">
                                    {no} </button>
                                <button onClick={(e) => eventTrigger(e)} type="button" className="button primary" data-dismiss="modal" disabled={operationInProgress}>
                                    {yes} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(Modal);
